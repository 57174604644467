import React from 'react';

import { Button, Grid, InputAdornment, TextField, Typography, withStyles } from '@material-ui/core';
import { EVENT_CODE, EVENT_ID, EVENT_IMG, getEventInfo, LOGIN_TB, SIGNIN_URL } from '../constants';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';

import swal from '@sweetalert/with-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as queryString from 'querystring';
import { Backdrop } from '@mui/material';
import AgendaDialog from '../components/dialogs/AgendaDialog';
import './Login.css';

import { GiSmartphone } from 'react-icons/gi';

Amplify.configure(awsconfig);

const styles = (theme) => ({
  //로그인 입력창
  formInput: {
    width: '100%',
    borderRadius: '5px',
    boxShadow: `rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`,

    '& input': {
      padding: '0 2vw',
      height: '65px',
      backgroundColor: '#fff',
      [theme.breakpoints.down('sm')]: {
        height: '55px',
        fontSize: '19px',
      },
    },
  },
  //버튼 우상
  btn: {
    color: '#ffffff',
    height: '65px',
    fontSize: '1.2em',
    fontWeight: 500,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '5px',
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: '#ffffff',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1em',
      height: '55px',
    },
  },
  //버튼 좌하
  gradientBtn: {
    color: '#ffffff',
    height: '65px',
    fontSize: '1.2em',
    fontWeight: 500,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1em',
      height: '55px',
    },
  },
  // progress bar custom color
  circle: {
    stroke: 'url(#linearColors)',
  },
});

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      username: '',
      password: '',
      email: '',
      tel: '',
      loading: false,
      matches: window.matchMedia('(max-width:992px)').matches,

      programDialog: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
  }

  componentWillUnmount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia('(max-width:992px)').removeListener(handler);
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia('(max-width:992px)').addListener(handler);
    if (window.location.href.indexOf('valid') !== -1) {
      const str = window.location.href.split('?');
      const test = queryString.parse(str[1]);
      console.log(test);

      if (test.valid) {
        this.handleSignIn(null, test.valid.replace('#/', ''));
        this.setState({ isBms: true });
      }
    }
    getEventInfo();
  }

  handleSignIn(event, bmsId) {
    if (event) event.preventDefault();

    let id = EVENT_ID + '_' + this.state.tel.replace(/[- ]/g, '');
    let password = this.state.tel.replace(/[- ]/g, '');

    if (bmsId) {
      id = bmsId.replace('bms_', EVENT_ID + '_');
      password = bmsId.replace('bms_', '');
    } else {
      if (this.state.tel.length === 0) {
        swal('', '등록하신 전화번호를 입력해 주십시오.', 'warning');
        return;
      }
    }

    if (password.length < 6) {
      password = password.padEnd(6, '0');
    }

    this.setState({ loading: true });

    Auth.signIn(id, password)
      .then((user) => {
        const idToken = user.signInUserSession.idToken;
        // localStorage.clear();
        // ※ 행사별로 미사용 항목 주석처리
        sessionStorage.setItem('_in_user_', user.username);
        sessionStorage.setItem('_in_jwt', idToken.getJwtToken());
        sessionStorage.setItem('_in_sub', user.attributes.sub);
        sessionStorage.setItem('_in_name', idToken.payload['custom:in_name']);
        if (bmsId) {
          sessionStorage.setItem('_in_name', idToken.payload['custom:first_name'] + ' ' + idToken.payload['custom:last_name']);
        }
        sessionStorage.setItem('_in_event_id', idToken.payload['event_id']);
        sessionStorage.setItem('_in_role', idToken.payload['custom:role']);
        sessionStorage.setItem('_in_pos', idToken.payload['custom:position']);
        if (bmsId) {
          if (idToken.payload['custom:off_reg'].includes('MEDIGATE')) {
            sessionStorage.setItem('_in_pos', 'MEDIGATE_' + idToken.payload['custom:first_name']);
          }
          if (idToken.payload['custom:off_reg'].includes('BMSON')) {
            sessionStorage.setItem('_in_pos', 'BMSON_' + idToken.payload['custom:first_name']);
          }
        }
        sessionStorage.setItem('_in_tel', idToken.payload['custom:hp']);
        sessionStorage.setItem('_in_user_type', idToken.payload['custom:user_type']);
        sessionStorage.setItem('_in_pos_en', idToken.payload['custom:position_en']);
        sessionStorage.setItem('_in_reg_type', idToken.payload['custom:off_reg']);
        sessionStorage.setItem('_in_email', idToken.payload['custom:in_email']);

        let _url = `${SIGNIN_URL}?username=${encodeURIComponent(idToken.payload['custom:in_name'])}&userid=${encodeURIComponent(
          user.username,
        )}&evt=${EVENT_ID}&agent=${encodeURIComponent(navigator.userAgent)}&tbName=${LOGIN_TB}&code=${encodeURIComponent(EVENT_CODE)}`;

        if (bmsId) {
          _url = `${SIGNIN_URL}?username=${encodeURIComponent(
            'BMSON_' + idToken.payload['custom:first_name'] + ' ' + idToken.payload['custom:last_name'],
          )}&userid=${encodeURIComponent(user.username)}&evt=${EVENT_ID}&agent=${encodeURIComponent(
            navigator.userAgent,
          )}&tbName=${LOGIN_TB}&code=${encodeURIComponent(EVENT_CODE)}`;
        }

        fetch(_url, {
          method: 'GET',
        })
          .then((data) => {
            this.setState({ loading: false });
            this.props.history.push('/program');
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
            this.props.history.push('/program');
          });
      })
      .catch((err) => {
        this.setState({ loading: false });
        swal('', '등록되지 않은 전화번호입니다.', 'error');
      });
  }

  handleChange(type, e) {
    this.setState({ [type]: e.target.value });
  }

  customSpacing(size) {
    return (
      <React.Fragment>
        {size === 's' && <Grid item xs={12} style={{ padding: this.state.matches ? '8px 0' : '16px 0' }}></Grid>}
        {size === 'm' && <Grid item xs={12} style={{ padding: this.state.matches ? '16px 0' : '32px 0' }}></Grid>}
        {size === 'l' && <Grid item xs={12} style={{ padding: this.state.matches ? '24px 0' : '48px 0' }}></Grid>}
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;
    if (!this.state.isBms) {
      return (
        <div
          style={{
            height: '100%',
            position: 'relative',
            overflowY: 'auto',
            backgroundRepeat: 'no-repeat',
            backgroundImage: this.state.matches ? `url(${EVENT_IMG}/bg_mob.png)` : `url(${EVENT_IMG}/bg_web.png)`,
            backgroundSize: this.state.matches ? 'auto 100%' : '100% auto',
            backgroundPosition: this.state.matches ? 'top center' : 'top center',
          }}
        >
          <div
            style={{
              position: 'fixed',
              transform: 'rotate( -90deg )',
              color: '#fff',
              right: this.state.matches ? '-20vw' : '-75px',
              bottom: this.state.matches ? '12vh' : '15%',
              fontSize: this.state.matches ? '10px' : '11px',
              // backgroundColor: 'rgba(255,255,255,0.15)',
            }}
          >
            2084-KR-2500004/Jan.2025/Apr.2025
          </div>

          <Grid container alignItems="center" style={{ height: '100%' }}>
            <Grid container justifyContent="center" style={{ width: '100%' }}>
              {/* 메인이미지 */}
              {this.customSpacing('m')}
              <Grid container justifyContent="center">
                <Grid container item xs={10} sm={8} md={7} lg={5}>
                  <img src={`${EVENT_IMG}/main.png`} alt={''} style={{ width: '100%' }} />
                </Grid>
              </Grid>
              {this.customSpacing('m')}
              <Grid
                id="login_contents_grid"
                container
                justifyContent="center"
                item
                xs={11}
                sm={10}
                md={8}
                lg={6}
                style={{
                  backgroundColor: 'rgba(244,244,244,0.9)',
                  borderRadius: this.state.matches ? '5px' : '8px',
                  boxShadow: this.props.theme.shadow,
                }}
              >
                {this.customSpacing('s')}

                {/* contents left */}
                <Grid
                  id="contents_left_grid"
                  container
                  justifyContent="center"
                  xs={12}
                  md={6}
                  item
                  style={{ borderRight: this.state.matches ? 'none' : '2px solid #efefef', gap: 16 }}
                >
                  <Grid id="text_grid" container justifyContent="center" alignItems="flex-start">
                    <Typography
                      style={{
                        color: this.props.theme.palette.primary.main,
                        fontSize: this.state.matches ? '0.9em' : '1.1em',
                        wordBreak: 'keep-all',
                        textAlign: 'center',
                      }}
                    >
                      등록하신 휴대전화번호로 입장해주세요.
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid id="input_grid" container justifyContent="center" item xs={10} sm={10} md={9} lg={9}>
                      <TextField
                        variant="outlined"
                        id="tel"
                        type="tel"
                        name="tel"
                        onChange={(e) => {
                          this.handleChange('tel', e);
                          this.handleChange('password', e);
                        }}
                        value={this.state.tel || ''}
                        className={classes.formInput}
                        placeholder="- 제외하고 입력하세요"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <GiSmartphone size={25} style={{ color: this.props.theme.palette.primary.main }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid id="login_btn_grid" item xs={10} sm={10} md={9} lg={9}>
                      <Button fullWidth variant="contained" className={classes.gradientBtn} onClick={this.handleSignIn}>
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            fontSize: this.state.matches ? '1em' : '1.1em',
                          }}
                        >
                          로그인
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {this.state.matches && this.customSpacing('s')}
                {/* contents right */}
                <Grid id="contents_right_grid" container justifyContent="center" item xs={12} md={6} style={{ gap: 16 }}>
                  <Grid id="text_grid" container justifyContent="center" alignItems="flex-start">
                    <Typography
                      style={{
                        color: this.props.theme.palette.secondary.main,
                        fontSize: this.state.matches ? '0.9em' : '1.1em',
                        wordBreak: 'keep-all',
                        textAlign: 'center',
                      }}
                    >
                      처음 접속하셨다면, 등록해 주세요.
                    </Typography>
                  </Grid>

                  <Grid container justifyContent="center">
                    <Grid id="reg_btn_grid" item xs={10} sm={10} md={9} lg={9}>
                      <Button
                        fullWidth
                        variant="contained"
                        className={classes.btn}
                        onClick={() => {
                          this.props.history.push('signup');
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            fontSize: this.state.matches ? '1em' : '1.1em',
                          }}
                        >
                          등록하기
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid id="program_btn_grid" item xs={10} sm={10} md={9} lg={9}>
                      <Button
                        fullWidth
                        variant="contained"
                        className={classes.btn}
                        onClick={() => {
                          this.setState({ programDialog: true });
                          // swal('', '준비중입니다.', 'info');
                        }}
                        // onClick={() =>
                        //   window.open(
                        //     `https://www.bmson.co.kr
                        // `,
                        //     '_blank',
                        //   )
                        // }
                        style={{ backgroundColor: '#ad549c' }}
                      >
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            fontSize: this.state.matches ? '1em' : '1.1em',
                            color: '#fff',
                          }}
                        >
                          일정확인
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                {/* 하단로고 */}
                {this.customSpacing('s')}
                <Grid container justifyContent="center" item xs={10} sm={9} md={8} lg={7}>
                  <Grid>
                    <img src={`${EVENT_IMG}/logo_1.png`} alt={''} style={{ width: '100%' }} />
                  </Grid>
                </Grid>
                {this.customSpacing('s')}
              </Grid>
            </Grid>
          </Grid>

          {/* loading progress */}
          {this.state.loading && (
            <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-15%, -50%)', zIndex: 9999 }}>
              <CircularProgress size={100} thickness={3} classes={{ circle: classes.circle }} style={{ opacity: 0.7 }} />
              <svg>
                <linearGradient id="linearColors">
                  <stop offset="20%" stopColor={this.props.theme.palette.primary.main} />
                  <stop offset="80%" stopColor={this.props.theme.palette.secondary.main} />
                </linearGradient>
              </svg>
            </div>
          )}

          {/* agenda(MUI backdrop) */}
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={this.state.programDialog}>
            <AgendaDialog
              onClose={() => {
                this.setState({ programDialog: false });
              }}
            />
          </Backdrop>
        </div>
      );
    } else {
      return (
        // bms login 사용자 :: 로그인 입력창 없이 program page 로 바로 이동
        <div
          style={{
            height: '100%',
            position: 'relative',
            overflowY: 'auto',

            backgroundRepeat: 'no-repeat',
            backgroundImage: this.state.matches ? `url(${EVENT_IMG}/bg_mob.png)` : `url(${EVENT_IMG}/bg_web.png)`,
            backgroundSize: this.state.matches ? 'auto 100%' : '100% 100%',
            backgroundPosition: this.state.matches ? 'auto' : 'center',
          }}
        >
          {/* <div
            style={{
              position: 'fixed',
              transform: 'rotate( -90deg )',
              color: '#AEAEAE',
              right: this.state.matches ? '-19%' : '-75px',
              bottom: this.state.matches ? '20%' : '25%',
              fontSize: this.state.matches ? '0.6em' : '11px',
            }}
          >
            3500-KR-2300071, Sep.2023 / Oct.2023
          </div> */}

          <Grid container style={{ height: '100%', width: '100%' }} alignItems="center">
            <Grid container justifyContent="center" style={{ width: '100%' }}>
              <Grid
                id="login_contents_grid"
                container
                justifyContent="center"
                item
                xs={11}
                md={6}
                style={{
                  backgroundColor: 'rgba(244,244,244,0.85)',
                  borderRadius: '5px',
                  boxShadow: this.props.theme.shadow,
                }}
              >
                {this.customSpacing('l')}
                <Grid container justifyContent="center"></Grid>
                {this.customSpacing('l')}
                {/* input grid */}
                <Grid id="login_contents_positioning_grid" container style={{ padding: this.state.matches ? '48px 0' : '64px 0' }}>
                  {this.state.loading && (
                    <Grid container>
                      <Grid container justifyContent="center">
                        <Grid container justifyContent="center" alignContent="center" alignItems="center">
                          <CircularProgress color="secondary" size={this.state.matches ? 32 : 64} thickness={2} />
                          <Typography style={{ fontSize: this.state.matches ? '1.4em' : '1.8em', fontWeight: 'bold' }}>
                            &nbsp;BMSON/메디게이트 로그인 중입니다.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

export default withStyles(styles, { withTheme: true })(LoginPage);

import React from 'react';
import {
  // CssBaseline,
  Grid,
  withStyles,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { API_URL, EVENT_ID, EVENT_IMG } from '../constants';
import axios from 'axios';
import ReplayVod from './ReplayVod';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import swal from '@sweetalert/with-react';
import { Backdrop } from '@mui/material';
import AgendaDialog from '../components/dialogs/AgendaDialog';

const styles = (theme) => ({});

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    borderRight: '1px solid #ffffff',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 'large',
    padding: 12,
    lineHeight: 'initial',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  body: {
    '&:first-child': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  root: {},
}))(TableCell);

class Replay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      replayVod: false,
      satellitesData: [],
      vod: '',
      lecture: '',
      position: '',
      speaker: '',
      programDialog: false,
      loading: true,
      matches: window.matchMedia('(max-width:960px)').matches,
    };
  }

  getSatelliteList() {
    axios
      .get(
        `${API_URL}/program/detail?eventId=${EVENT_ID}&pgmId=pgm_1715931153703`,
        // todo:
        // 1.관리자 페이지에서 다시보기 목록 생성 시 pgmId 무작위 생성, 행사마다 해당부분 기입필요
        // 2.세부 프로그램 정보에 다시보기 정보, vimeo player Url 적용
        { headers: { Pragma: 'no-cache' } },
      )
      .then((response) => {
        this.setState({ satellitesData: response.data, loading: false });
        // console.log('replay_list', response.data);
      });
  }

  componentDidMount() {
    if (!sessionStorage.getItem('_in_user_')) {
      swal('다시보기 입장하기', '성함과 병원명 입력 후 시청이 가능합니다.', 'warning');
      this.props.history.push('/');
    }

    this.getSatelliteList();
  }

  openReplayVod = (vod, title, presenter, contents) => {
    this.setState({ vod: vod });
    this.setState({ lecture: title });
    this.setState({ speaker: presenter });
    this.setState({ position: contents });
    this.setState({ replayVod: true });
  };
  closeReplayVod = (event) => {
    this.setState({ replayVod: false });
  };

  render() {
    const mapToComponent = (datas) => {
      return datas.map((satellite, i) => {
        return (
          <TableBody key={i}>
            {this.state.matches ? (
              <React.Fragment>
                <TableRow>
                  <CustomTableCell width="100%">
                    <Grid container>
                      <Grid container>
                        <Typography style={{ color: '#055d9a', fontSize: 'large' }}>{satellite.title}</Typography>
                      </Grid>
                      <Grid container justifyContent="flex-end" style={{ marginTop: '10px' }}>
                        {satellite.contents && satellite.presenter && satellite.presenter.length > 2 && (
                          <Typography style={{ color: 'grey', fontStyle: 'italic' }}>
                            {satellite.contents}&nbsp;({satellite.presenter})
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell width="100%" style={{ backgroundColor: '#fff' }}>
                    <Grid container>
                      <Grid container justifyContent="center" alignItems="center" item xs={6} style={{ borderRight: '1px solid #e0e0e0' }}>
                        {satellite.pgVod.length > 1 ? (
                          <Grid
                            container
                            justifyContent="center"
                            onClick={() =>
                              this.openReplayVod(
                                `${satellite.pgVod}`,
                                `${satellite.title}`,
                                `${satellite.presenter}`,
                                `${satellite.contents}`,
                              )
                            }
                          >
                            <PlayCircleFilledIcon style={{ color: '#de3e36' }} />
                          </Grid>
                        ) : (
                          <Typography>준비중</Typography>
                        )}
                      </Grid>
                    </Grid>
                  </CustomTableCell>
                </TableRow>
              </React.Fragment>
            ) : (
              <TableRow>
                <CustomTableCell>
                  <Grid container>
                    <Grid container>
                      <Typography style={{ color: '#000000', fontSize: 'large', fontWeight: 'bold' }}>{satellite.title}</Typography>
                    </Grid>
                    <Grid container justifyContent="flex-end" style={{ marginTop: '10px' }}>
                      {satellite.contents && satellite.presenter && satellite.presenter.length > 2 && (
                        <Typography style={{ color: 'grey', fontStyle: 'italic' }}>
                          {satellite.contents}&nbsp;{satellite.presenter}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </CustomTableCell>
                {satellite.pgVod.length > 1 ? (
                  <CustomTableCell style={{ borderRight: '1px solid #efefef' }}>
                    <div
                      style={{ textAlign: 'center', cursor: 'pointer', marginLeft: '10px' }}
                      onClick={
                        () =>
                          this.openReplayVod(`${satellite.pgVod}`, `${satellite.title}`, `${satellite.presenter}`, `${satellite.contents}`) //자료집 없으면 고치기
                      }
                    >
                      <PlayCircleFilledIcon
                        style={{
                          color: this.props.theme.palette.primary.main,
                          width: this.state.matches ? 24 : 32,
                          height: this.state.matches ? 24 : 32,
                        }}
                      />
                    </div>
                  </CustomTableCell>
                ) : (
                  <CustomTableCell
                    style={{ textAlign: 'center', lineHeight: 'initial', borderRight: '1px solid #efefef', fontSize: 'large' }}
                  >
                    준비중
                  </CustomTableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        );
      });
    };

    return (
      <div
        style={{
          height: '100%',
          position: 'relative',
          overflowY: 'auto',

          backgroundRepeat: 'no-repeat',
          backgroundImage: this.state.matches ? `url(${EVENT_IMG}/bg_mob.png)` : `url(${EVENT_IMG}/bg_web.png)`,
          backgroundSize: this.state.matches ? 'auto 100%' : '100% auto',
          backgroundPosition: this.state.matches ? 'top center' : 'top center',
        }}
      >
        <Grid container style={{ height: '100%' }}>
          <Grid
            id="content_main"
            container
            justifyContent="center"
            alignItems="center"
            style={{
              height: '100%',
            }}
          >
            <Grid
              id="content_positioning_grid"
              container
              justifyContent="center"
              item
              xs={12}
              sm={11}
              md={9}
              lg={7}
              style={{ backgroundColor: 'rgba(255,255,255,0.75)', borderRadius: 5 }}
            >
              <Grid container justifyContent="center">
                <Grid item xs={12} style={{ position: 'relative' }}>
                  {/* float button */}
                  {/* <Grid style={{ position: 'absolute', bottom: this.state.matches ? '65%' : 8, right: this.state.matches ? 4 : 8 }}>
                    <button
                      style={{
                        height: this.state.matches ? 55 : 80,
                        width: this.state.matches ? 55 : 80,
                        borderRadius: '50%',
                        backgroundColor: '#01a6aa',
                        border: '2px solid #00f3e1',
                        cursor: 'pointer',
                        boxShadow: 'rgba(255, 255, 255, 0.16) 0px 3px 6px, rgba(255, 255, 255, 0.23) 0px 3px 6px',
                      }}
                      onClick={() => {
                        this.setState({ programDialog: true });
                      }}
                    >
                      <Grid container justifyContent="center">
                        <Grid container justifyContent="center">
                          <AiFillSchedule size={this.state.matches ? 20 : 24} color={'#fff'} />
                        </Grid>
                        <Grid container justifyContent="center">
                          <Typography style={{ color: '#fff', fontSize: this.state.matches ? '0.8em' : '1.1em' }}>아젠다</Typography>
                        </Grid>
                      </Grid>
                    </button>
                  </Grid> */}
                  {/* ====== */}
                  <Grid container justifyContent="center" style={{ padding: this.state.matches ? '9px 0px' : '18px 0px' }}>
                    <Grid item xs={8} sm={7} md={6} lg={5}>
                      <img
                        src={`${EVENT_IMG}/main_img.png`}
                        alt={''}
                        style={{
                          width: '100%',
                          borderRadius: 5,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container justifyContent="center" style={{ padding: 8, backgroundColor: '#fff' }}>
                  <Grid container justifyContent="center">
                    {this.state.matches ? (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <CustomTableCell style={{ color: '#ffffff' }} width="100%">
                                강연제목
                              </CustomTableCell>
                            </TableRow>
                          </TableHead>
                          {mapToComponent(this.state.satellitesData)}
                        </Table>
                      </TableContainer>
                    ) : (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <CustomTableCell style={{ color: '#ffffff' }} width="70%">
                                강연제목
                              </CustomTableCell>
                              <CustomTableCell width="15%">다시보기</CustomTableCell>
                            </TableRow>
                          </TableHead>
                          {mapToComponent(this.state.satellitesData)}
                        </Table>
                      </TableContainer>
                    )}

                    {this.state.loading && (
                      <Grid container justifyContent="center" alignItems="center" style={{ height: '200px' }}>
                        <CircularProgress size={100} thickness={5} style={{ opacity: 0.75 }} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ReplayVod
          open={this.state.replayVod}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.closeReplayVod();
            }
          }}
          vod={this.state.vod}
          speaker={this.state.speaker}
          lecture={this.state.lecture}
          position={this.state.position}
        />

        {/* agenda(MUI backdrop) */}
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={this.state.programDialog}>
          <AgendaDialog
            onClose={() => {
              this.setState({ programDialog: false });
            }}
          />
        </Backdrop>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Replay);

import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import {
  EVENT_ID,
  MQ_URL,
  QNA_API_URL,
  TOPIC_QNA_APPROVE,
  TOPIC_QNA_REG,
  TOPIC_QNA_SELECT,
  TOPIC_SESSION_CLOSE,
  TOPIC_SESSION_START,
  CHANNEL,
} from '../constants';
import Stomp from 'stompjs';
// import queryString from 'querystring';
import axios from 'axios';
import './qnaFilter.css';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import QnaComponent from './qnaComponent';

class QnaMaster extends QnaComponent {
  constructor(props) {
    super(props);
    //change default 채널명 불러오기
    let param = { ch: CHANNEL };
    // let param = queryString.parse(this.props.location.search.replace('?', ''));

    this.state = {
      channelId: param.ch,
      qnaList: [],
      selectedId: '',
    };

    this.getQnAList = this.getQnAList.bind(this);
    this.callFunc = this.callFunc.bind(this);
  }

  callFunc(value) {
    this.getQnAList('1');
  }

  getQnAList(pgmId) {
    if (!pgmId) {
      return;
    }
    axios
      .get(`${QNA_API_URL}/qna/listApprove?channel=${this.state.channelId}&eventId=${EVENT_ID}&pgmId=${pgmId}`, {
        headers: { Pragma: 'no-cache' },
      })
      .then((response) => {
        response.data.Items.sort(function (a, b) {
          return a.updatedAt < b.updatedAt ? 1 : -1;
        });
        this.setState({
          qnaList: response.data.Items,
        });
      });
  }

  componentWillUnmount() {
    this.customComponentWillUnmount();
  }

  componentDidMount() {
    this.setCallbackFunc(this.callFunc);
    this.customComponentDidMount();
    const headers = {
      login: 'atozsoft',
      passcode: 'atozsoft0901',
      'client-id': 'qna_master_' + new Date().getTime(),
    };
    const CH = this.state.channelId;
    const client = Stomp.client(MQ_URL);
    client.connect(
      headers,
      () => {
        console.log('<< connect', new Date());
        client.subscribe(
          '/topic/' + EVENT_ID + '_' + CH,

          (data) => {
            if (data.command === 'MESSAGE') {
              let cData = JSON.parse(data.body);
              cData = cData.status;
              switch (cData) {
                case TOPIC_QNA_REG:
                  break;
                case TOPIC_QNA_APPROVE:
                  this.getQnAList('1');
                  break;
                case TOPIC_QNA_SELECT:
                  break;
                case TOPIC_SESSION_START:
                  break;
                case TOPIC_SESSION_CLOSE:
                  break;
                default:
                  break;
              }
            }
          },
          headers,
        );
      },
      function () {
        console.log('error', new Date());
      },
    );
    this.getQnAList('1');

    setInterval(() => {
      this.getQnAList('1');
    }, 10000);
  }

  render() {
    // const onResetList = () => {
    //   this.setState({ selectedId: '' });
    //   console.log('RESET LIST');
    //   this.getQnAList('1');
    // };
    const handleAlignment = (event, value) => {
      let param = { qnaId: value, status: 'S', channel: this.state.channelId, eventId: EVENT_ID };
      axios.put(`${QNA_API_URL}/qna/status`, param).then((response) => {
        if (response.status === 200) {
          this.setState({ selectedId: value });
        }
      });
    };
    const mapToComponent = (datas) => {
      return datas.map((qna, i) => {
        return (
          <Grid container item xs={12} key={i}>
            <QnaItem qna={qna} onChange={handleAlignment} selected={qna.id === this.state.selectedId} />
          </Grid>
        );
      });
    };

    return (
      <div style={{ height: '100%', overflowY: 'auto' }}>
        <Grid container item xs={12}>
          <ToggleButtonGroup
            style={{ width: '100%' }}
            value={this.state.selectedId}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <Grid container item xs={12}>
              {mapToComponent(this.state.qnaList)}
            </Grid>
          </ToggleButtonGroup>
        </Grid>
      </div>
    );
  }
}

class QnaItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:991px)').matches /*md=992px*/,
    };
  }

  render() {
    return (
      <Grid container justify="center" item xs={12}>
        <Grid container item xs={11} className="qna_filter_row" style={{ padding: this.state.matches ? '4% 0' : '1% 0' }}>
          <Grid item xs={12} md={8} style={{ fontSize: this.state.matches ? '1em' : '1.5em' }}>
            <span style={{ color: '#377ac6' }}>Q. </span> {this.props.qna.q}
          </Grid>
          <Grid
            item
            xs={6}
            md={2}
            container
            justify="center"
            alignItems="center"
            style={{ fontSize: this.state.matches ? '1em' : '1.2em', paddingTop: this.state.matches ? '10px' : 0 }}
          >
            {/* 작성자: {this.props.qna.user}
            <br />
            소속: {this.props.qna.position} */}
          </Grid>
          <Grid container item xs={6} md={2} justify="center" alignItems="center">
            {this.props.selected ? (
              <Grid
                style={{
                  width: '50%',
                  padding: this.state.matches ? 0 : '5% 0',
                  fontSize: this.state.matches ? '1em' : '1.2em',
                  textAlign: 'center',
                  border: '2px solid #80AAD7',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'skyblue',
                }}
              >
                <span>선택중</span>
              </Grid>
            ) : (
              <ToggleButton
                value={this.props.qna.id}
                style={{
                  width: '50%',
                  padding: this.state.matches ? 0 : '5% 0',
                  fontSize: this.state.matches ? '1em' : '1.2em',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onChange={this.props.onChange}
              >
                선택
              </ToggleButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default QnaMaster;

import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import './App.css';
import Routes from './routes';
import MainProvider from './MainProvider';
import { CookiesProvider } from 'react-cookie';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#e94d30',
    },
    primary: {
      main: '#003a8e',
      gray: '#7a7a7a',
      bg: '#dedee6',
    },
    third: {
      main: '#e6f7fd',
    },
  },
  shadow: `rgba(0, 0, 0, 0.18) 0px 2px 4px`,
  typography: {
    fontFamily: ['NanumSquareNeo-Rg'].join(','),
  },
});

class App extends Component {
  render() {
    return (
      <MainProvider isTimerRunning={true}>
        <div style={{ height: '100%', backgroundColor: '#ffffff' }}>
          <ThemeProvider theme={theme}>
            <CookiesProvider>
              <Routes />
            </CookiesProvider>
          </ThemeProvider>
        </div>
      </MainProvider>
    );
  }
}

export default App;

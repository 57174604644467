import React from 'react';
import { Grid, TableBody, TableCell, TableRow, withStyles } from '@material-ui/core';
import {
  BBS_API_URL,
  EVENT_ID,
  getEventInfo,
  ATTEND_API_URL,
  BOOTH_API_URL,
  CHAT_API_URL,
  EVENTS_API_URL,
  POSTER_API_URL,
  PROGRAM_API_URL,
  QNA_API_URL,
  SURVEY_API_URL,
  USER_API_URL,
} from '../constants';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import axios from 'axios';

const styles = (theme) => ({
  bg: {
    width: '100vw',
    height: '100vh',
    overflow: 'auto',
  },
});

class CheckEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    getEventInfo();
  }

  setEventInfo() {
    const info = JSON.parse(sessionStorage.getItem('Item'));
    const except = ['accLogin', 'loginCount', 'userLabel', 'evtStatus'];
    // eslint-disable-next-line
    return Object.entries(info).map((_info_, idx) => {
      const [key, value] = _info_;
      const isExcept = except.find((a) => {
        return key.indexOf(a) !== -1;
      });
      if (!isExcept && value) {
        let val = '' + value;
        if (typeof value === 'object') {
          val = JSON.stringify(value);
        }
        if (key === 'userItem') {
          let _uiarr = [];
          // eslint-disable-next-line
          Object.entries(value).map((ui) => {
            if (ui[1]) _uiarr.push(ui[0]);
          });
          val = JSON.stringify(_uiarr);
        }
        return (
          <TableRow key={'evt-' + idx}>
            <TableCell>{key}</TableCell>
            <TableCell>{val}</TableCell>
          </TableRow>
        );
      }
    });
  }

  checkAPI() {
    // 여기는 필수 체크 해야하는 LIVE 에 필요한 것들추가
    // eslint-disable-next-line
    const arrAPIs = [
      EVENTS_API_URL,
      BBS_API_URL,
      BOOTH_API_URL,
      ATTEND_API_URL,
      CHAT_API_URL,
      POSTER_API_URL,
      PROGRAM_API_URL,
      QNA_API_URL,
      SURVEY_API_URL,
      USER_API_URL,
    ];

    axios
      .get(`${BBS_API_URL}/bbs/atoz`)
      .then((response) => {
        alert(response.data);
      })
      .catch((err) => {
        console.log('err', err.message);
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.bg}>
        <div>
          <Grid container justify="center" alignItems="center">
            <Grid item md={8}>
              <h1>Check Event Info</h1>
              <Button color={'primary'} variant={'contained'} onClick={this.checkAPI}>
                Check API
              </Button>
            </Grid>
            <Grid item md={8}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>EVENT_ID</TableCell>
                    <TableCell>{EVENT_ID}</TableCell>
                  </TableRow>
                  {sessionStorage.getItem('Item') && this.setEventInfo()}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CheckEvent);

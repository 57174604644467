import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import ScrollToTop from './components/ScrollTop';
import LoginPage from './signup/Login';
import JoinPage from './signup/Join';
import QnaFilter from './admin/qnaFilter';
import QnaMaster from './admin/qnaMaster';
import QnaMonitor from './admin/qnaMonitor';

import ProgramSimple from './program/ProgramSimple';
import LiveSimple from './session/LiveSimple';
import CheckEvent from './components/CheckEvent';
import Admin from './admin/admin';
import ComingSoon from './components/ComingSoon';
// import Panel from './program/Panel';
// import Mypage from './components/MyPage';
// import MyQna from './components/MyQna';
import VotingPage from './admin/votingPage';
import VotingAdmin from './admin/votingAdmin';
// import ReplayLogin from './signup/ReplayLogin';
import Replay from './program/Replay';

export default (props) => {
  return (
    <HashRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={LoginPage} />

          <Route exact path="/comingSoon" component={ComingSoon} />
          <Route exact path="/signup" component={JoinPage} />
          <Route exact path="/signin" component={LoginPage} />
          <Route exact path="/simple" component={LiveSimple} />

          <Route exact path="/program" component={ProgramSimple} />
          <Route exact path="/checkEvent" component={CheckEvent} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/qnaFilter" component={QnaFilter} />
          <Route exact path="/qnaMaster" component={QnaMaster} />
          <Route exact path="/qnaMonitor" component={QnaMonitor} />

          <Route exact path="/replay" component={Replay} />
          {/* <Route exact path="/myQna" component={MyQna} />
          <Route exact path="/panel" component={Panel} />
          <Route exact path="/mypage" component={Mypage} /> */}
          <Route exact path="/voting" component={VotingPage} />
          <Route exact path="/votingAdmin" component={VotingAdmin} />
        </Switch>
      </ScrollToTop>
    </HashRouter>
  );
};

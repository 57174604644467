import React, { Component } from 'react';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import SurveyDialog from '../sessionComp/SurveyDialog';
import AgendaDialog from '../../components/dialogs/AgendaDialog';
import swal from 'sweetalert';
import axios from 'axios';
import { FcSurvey } from 'react-icons/fc';
import { FcCalendar } from 'react-icons/fc';

import { EVENT_ID, QNA_API_URL, getUserID, API_URL } from '../../constants';
import { Backdrop } from '@mui/material';
import { RiQuestionnaireLine } from 'react-icons/ri';

const styles = (theme) => ({
  chatContent: { width: '100%', height: 'calc(100% - 48px)', overflowY: 'scroll' },
  chatInput: { width: '100%' },
  sendBtn: {
    backgroundColor: '#bfbfbf',
    color: '#ffffff',
    fontWeight: 'bold',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  qnaInput: {
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    fontWeight: '500',
  },
  liveLogo: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      transform: 'translateY(-50px)',
    },
  },
  surveyBtn: {
    color: '#ffffff',
    backgroundColor: theme.palette.secondary.main,
    padding: '1.5vh 1.5vw',
    width: '100%',
    fontSize: '1.3em',
    fontWeight: 'bold',
    borderRadius: '1vh',
    marginTop: '2vh',

    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
      border: '1.5px solid #61876e',
      borderColor: theme.palette.secondary.main,
    },
  },

  btn: {
    width: '90%',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: 'large',
    padding: '2vh 0',
    color: '#fff',

    // [theme.breakpoints.down('sm')]: { padding: '2vh 0' },
  },
});

class QNA extends Component {
  state = {
    msg: '',
    qnaItem: [],
    afterSendModal: false,
    surveyDialog: false,
    agendaDialog: false,
    matches: window.matchMedia('(max-width:600px)').matches,
  };

  constructor(props) {
    super(props);
    this.handleInput = this.handleInput.bind(this);
    this.sendQNA = this.sendQNA.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    console.log('접속한 채널: ' + this.props.channel);
  }

  handleInput(event) {
    this.setState({ msg: event.target.value });
  }

  sendQNA() {
    if (!this.state.msg || this.state.msg.length === 0) {
      swal('', '질문내용을 입력해주세요.', 'warning');
      return;
    }

    const userId = sessionStorage.getItem('_in_name');
    const userPosition = sessionStorage.getItem('_in_pos');
    // console.log(
    //   '12345',
    //   `question=${this.state.msg}&answer=&eventId=${EVENT_ID}&channel=${this.props.channel}&userId=${userId}&pgmId=${sessionStorage.getItem(
    //     '_in_session',
    //   )}&status=R`,
    // );

    fetch(`${QNA_API_URL}/qna/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      // change_default 세션 정보 없이도 질문 가능하게,소속 반영
      // body: `question=${this.state.msg}&answer=&eventId=${EVENT_ID}&channel=${
      //   this.props.channel
      // }&userId=${userId}&pgmId=${sessionStorage.getItem('_in_session')}&status=A`,
      body: `question=${this.state.msg}&answer=&eventId=${EVENT_ID}&channel=${this.props.channel}&userId=${userId}&position=${userPosition}&status=A`,
    }).then((res) => {
      console.log(res);
      this.setState({ msg: '' });
      swal('', '질문이 전송되었습니다.', 'success');
    });
  }

  handleOpen = () => {
    this.setState({ afterSendModal: true });
  };

  handleClose = () => {
    this.setState({ afterSendModal: false });
  };

  openSurveyDialog = (event) => {
    this.setState({ surveyDialog: true });

    // this.isReport().then((result) => {
    //   if (!result.is) {
    //     this.setState({ surveyDialog: true });
    //   } else {
    //     swal('', '이미 설문에 참여하셨습니다.', 'warning');
    //     this.setState({ surveyDialog: false });
    //   }
    // });
  };

  openAgendaDialog = (event) => {
    this.setState({ agendaDialog: true });
  };

  isReport = () => {
    return new Promise((resolve) => {
      axios.get(`${API_URL}/survey/info?evt=${EVENT_ID}&userid=${getUserID()}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
        // console.log('isReport확인');
        // console.log(response.data.Item);
        if (response.data.Item) {
          resolve({ is: true });
        } else {
          resolve({ is: false });
        }
      });
    });
  };

  render() {
    const { classes } = this.props;
    const { msg } = this.state;
    return (
      <React.Fragment>
        <Grid container style={{ height: 'fit-content' }} alignItems="flex-start">
          {!this.state.matches && (
            <div
              style={{
                position: 'fixed',
                transform: 'rotate( -90deg )',
                color: '#555',
                right: this.state.matches ? '-25vw' : '-75px',
                bottom: this.state.matches ? '12vh' : '15%',
                fontSize: this.state.matches ? '10px' : '11px',
                backgroundColor: 'rgba(255,255,255,0.15)',
              }}
            >
              2084-KR-2500004/Jan.2025/Apr.2025
            </div>
          )}
          <Grid container>
            <div
              style={{
                padding: this.state.matches ? '8px 24px' : '8px 16px',
                backgroundColor: '#00479d',
                color: '#fff',
                borderTopRightRadius: '4px',
                borderTopLeftRadius: '4px',
                fontSize: this.state.matches ? '0.9em' : '1.1em',
                fontWeight: 800,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <RiQuestionnaireLine size={this.state.matches ? 16 : 24} />
              &nbsp;QNA
            </div>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <OutlinedInput
                className={classes.qnaInput}
                fullWidth
                multiline
                rows={5}
                placeholder="질문을 보내주시면, 해당 세션의 좌장 선생님께 전달됩니다."
                value={msg}
                onChange={this.handleInput}
              />
              <Button
                className={classes.sendBtn}
                style={msg.length > 0 ? { backgroundColor: this.props.theme.palette.primary.main } : { backgroundColor: '#bfbfbf' }}
                onClick={this.sendQNA}
              >
                전송 *
              </Button>
            </FormControl>
            <Grid container justifyContent="center" style={{ padding: this.state.matches ? '4px 0 8px' : '8px 0 16px' }}>
              <Typography style={{ color: '#f00', fontSize: this.state.matches ? '0.9em' : '1em' }}>
                * Q&A 창은 심포지엄 관련 내용만 전송 부탁드립니다.
              </Typography>
            </Grid>
          </Grid>
          <Grid id="button_positioning_grid" container justifyContent="center">
            <Grid item xs={12}>
              <Typography style={{ fontSize: this.state.matches ? '1em' : '1.3em', wordBreak: 'keep-all' }}>
                * 행사 종료 후 강의실 퇴장 시, 하단의 설문참여 버튼을 눌러 주시기 바랍니다.
              </Typography>
            </Grid>

            <Grid container justifyContent="center" style={{ paddingTop: '2vh' }}>
              <Grid container justifyContent="center" alignContent="center" item xs={9} sm={9} md={8} lg={7}>
                <Button
                  style={{
                    width: '90%',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    fontSize: this.state.matches ? '1em' : '1.2em',
                    height: this.state.matches ? 55 : 75,
                    color: '#fff',
                  }}
                  // style={{ borderColor: this.props.theme.palette.primary.main }}
                  startIcon={<FcSurvey size={30} />}
                  variant="contained"
                  color={'primary'}
                  onClick={this.openSurveyDialog}
                >
                  설문참여
                </Button>
              </Grid>

              <Grid container justifyContent="center" alignContent="center" item xs={9} sm={9} md={8} lg={7} style={{ paddingTop: 16 }}>
                <Button
                  style={{
                    width: '90%',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    fontSize: this.state.matches ? '1em' : '1.2em',
                    height: this.state.matches ? 55 : 75,
                    color: '#fff',
                  }}
                  // style={{ borderColor: this.props.theme.palette.secondary.main }}
                  startIcon={<FcCalendar size={30} />}
                  variant="contained"
                  color={'secondary'}
                  // style={{ color: this.props.theme.palette.secondary.main }}
                  onClick={
                    // () => swal('', '준비중입니다.', 'info')
                    this.openAgendaDialog
                  }
                >
                  일정확인
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {this.state.matches && (
            <Grid container justifyContent="flex-end" style={{ paddingTop: '24px' }}>
              <div
                style={{
                  color: '#555',
                  fontSize: this.state.matches ? '10px' : '11px',
                  backgroundColor: 'rgba(255,255,255,0.15)',
                }}
              >
                2084-KR-2500004/Jan.2025/Apr.2025
              </div>
            </Grid>
          )}
        </Grid>
        <SurveyDialog
          open={this.state.surveyDialog}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.setState({ surveyDialog: false });
            }
          }}
          isFeedback={this.state.isFeedback}
        />
        {/* agenda(MUI backdrop) */}
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={this.state.agendaDialog}>
          <AgendaDialog
            onClose={() => {
              this.setState({ agendaDialog: false });
            }}
          />
        </Backdrop>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(QNA);

import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Grid, makeStyles, Tabs, Tab } from '@material-ui/core';

// import UserList from './userList';
import QnaFilter from './qnaFilter';
import QnaMaster from './qnaMaster';
import QnaMonitor from './qnaMonitor';
// import Button from '@material-ui/core/Button';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    overflowY: 'auto',
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item xs={11}>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} indicatorColor="primary">
              <Tab label="qnaMaster" {...a11yProps(0)} />
              <Tab label="qnaFilter" {...a11yProps(1)} />
              <Tab label="qnaMonitor" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
        </Grid>

        <Grid item xs={12}>
          <TabPanel value={value} index={0}>
            <QnaMaster />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <QnaFilter />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <QnaMonitor />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
}

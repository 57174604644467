import React from 'react';
import {
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  withStyles,
  // FormControl,
  // RadioGroup,
  // Radio,
} from '@material-ui/core';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';

import { EVENT_ID, EVENT_IMG, EVENT_NAME, USER_API_URL } from '../constants';
import axios from 'axios';

import swal from 'sweetalert';
import { HiOutlineArrowCircleLeft } from 'react-icons/hi';

Amplify.configure(awsconfig);

const styles = (theme) => ({
  // 등록하기 버튼
  submitRoot: {
    width: '50%',
    color: '#ffffff',
    backgroundColor: theme.palette.secondary.main,
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    fontSize: '1.3em',
    // fontWeight: 'bold',
    fontWeight: 600,
    borderRadius: '5px',
    padding: '16px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1em',
      width: '80%',
      height: '50px',
    },
  },
  submitDisabled: {
    color: '#9d0b4c !important',
    borderColor: theme.palette.secondary.main,
    border: '2px solid',
    backgroundColor: '#ffffff !important',
  },

  // 나가기 버튼
  outBtnWeb: {
    height: '55px',
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  outBtnMob: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  input: {
    width: '90%',
    backgroundColor: '#ffffff',
  },

  inputGrid: {
    margin: '1vh 0',
    [theme.breakpoints.down('sm')]: {
      margin: '1.5vh 0',
    },
  },
  // 등록 동의 문구
  ctrlLabel: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'start',
      '& .MuiFormControlLabel-label': {
        fontSize: '0.9em',
        wordBreak: 'keep-all',
      },
    },
  },
});

class JoinPage extends React.Component {
  constructor(props) {
    super(props);
    this.inputGridRef = React.createRef(); // Ref 생성
    this.state = {
      name: '', //이름
      email: '', //이메일
      tel: '', //전화번호
      position: '', //소속
      attendType: '', // 참석유형
      isInfoAgree1: 'N', //개인정보 동의
      isInfoAgree2: 'N', //개인정보 동의2
      matches: window.matchMedia('(max-width:991px)').matches, //md: 992px
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
  }

  componentWillUnmount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia('(max-width:992px)').removeListener(handler);
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia('(max-width:992px)').addListener(handler);
  }
  handleSignUp() {
    if (
      this.state.tel === '' ||
      this.state.name === '' ||
      this.state.position === '' ||
      this.state.positionDetail === '' ||
      this.state.email === ''
    ) {
      swal('등록자 정보 미입력', '미입력된 항목이 있습니다. 다시 한번 확인 부탁드립니다.', 'error');
      if (this.inputGridRef.current) {
        this.inputGridRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      return;
    }
    if (this.state.isInfoAgree1 !== 'Y' || this.state.isInfoAgree2 !== 'Y') {
      swal('필수 항목 미동의', '필수 항목에 동의 체크 후 등록가능합니다.', 'error');
      return;
    }

    const telNo = this.state.tel.replace(/[- ]/g, '');
    this.setState({ loading: true });
    //check Exists
    try {
      axios.get(`${USER_API_URL}/user/info?eventId=${EVENT_ID}&userId=${EVENT_ID + '_' + this.state.tel}`).then((res) => {
        if (res.data.Item) {
          swal(`${EVENT_NAME}`, '이미 등록된 전화번호입니다.', 'error');
          this.setState({ loading: false });
          return;
        } else {
          Auth.signUp({
            username: EVENT_ID + '_' + telNo,
            password: telNo,
            attributes: {
              email: 'test@test.com',
              'custom:in_name': this.state.name,
              'custom:hp': telNo,
              'custom:in_email': this.state.email,
              'custom:position': this.state.position,
              // 'custom:isPay': this.state.attendType,
              'custom:role': 'user',
              'custom:evt': EVENT_ID,
            },
          })
            .then((user) => {
              console.log(user);
              swal(`${EVENT_NAME}`, '등록되었습니다.', 'success');
              this.setState(
                {
                  name: '',
                  email: '',
                  tel: '',
                  position: '',
                  isInfoAgree1: 'N',
                  isInfoAgree2: 'N',
                  attendType: '', // 참석유형
                  loading: false,
                },
                () => {
                  this.props.history.push('/signin');
                },
              );
            })
            .catch((err) => {
              if (err) {
                if (err.code === 'UsernameExistsException') {
                  this.setState({ loading: false });
                  // swal('', '이미 등록된 전화번호 입니다.', 'error');
                  return;
                }
              }
              console.log(err);
            });
        }
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  }

  handleChange(type, e) {
    this.setState({ [type]: e.target.value }, () => {});
  }

  //전화번호 입력란 숫자만 입력가능
  handleChangeNum(type, e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ [type]: e.target.value });
    }
  }

  customSpacing(size) {
    return (
      <React.Fragment>
        {size === 's' && <Grid item xs={12} style={{ padding: this.state.matches ? '8px 0' : '16px 0' }}></Grid>}
        {size === 'm' && <Grid item xs={12} style={{ padding: this.state.matches ? '16px 0' : '32px 0' }}></Grid>}
        {size === 'l' && <Grid item xs={12} style={{ padding: this.state.matches ? '24px 0' : '48px 0' }}></Grid>}
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div
        style={{
          height: '100%',
          position: 'relative',
          overflowY: 'auto',

          backgroundRepeat: 'no-repeat',
          backgroundImage: this.state.matches ? `url(${EVENT_IMG}/bg_mob.png)` : `url(${EVENT_IMG}/bg_web.png)`,
          backgroundSize: this.state.matches ? 'auto 100%' : '100% auto',
          backgroundPosition: this.state.matches ? 'top center' : 'top center',
        }}
      >
        <div
          style={{
            position: 'fixed',
            transform: 'rotate( -90deg )',
            color: '#fff',
            right: this.state.matches ? '-20vw' : '-65px',
            bottom: this.state.matches ? '12vh' : '15%',
            fontSize: this.state.matches ? '10px' : '11px',
            // backgroundColor: 'rgba(255,255,255,0.15)',
          }}
        >
          2084-KR-2500004/Jan.2025/Apr.2025
        </div>

        <Grid container>
          <Grid container justifyContent="center">
            {/* 메인이미지 */}
            {this.customSpacing('m')}
            <Grid container justifyContent="center">
              <Grid container item xs={10} sm={8} md={7} lg={5}>
                <img src={`${EVENT_IMG}/main.png`} alt={''} style={{ width: '100%' }} />
              </Grid>
            </Grid>
            {this.customSpacing('m')}
            <Grid id="content_positioning_grid" container justifyContent="center">
              <Grid
                container
                justifyContent="center"
                item
                xs={11}
                sm={10}
                md={8}
                lg={6}
                style={{
                  backgroundColor: 'rgba(244,244,244,0.85)',
                  padding: this.state.matches ? 16 : 32,
                  borderRadius: 10,
                  boxShadow: this.props.theme.shadow,
                }}
              >
                <Grid id="back_btn_grid" container justifyContent="flex-start" item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      this.props.history.push('signin');
                    }}
                    style={{ height: '55px', backgroundColor: 'rgba(255,255,255,0.85)', display: this.state.matches ? 'none' : '' }}
                  >
                    <HiOutlineArrowCircleLeft
                      style={{ width: this.state.matches ? 16 : 32, height: this.state.matches ? 16 : 32, marginRight: '5px' }}
                    />
                    <b>메인페이지</b>
                  </Button>
                  <HiOutlineArrowCircleLeft
                    style={{
                      width: '40px',
                      height: '40px',
                      color: this.props.theme.palette.primary.main,
                      display: this.state.matches ? '' : 'none',
                    }}
                    alt={'move to mainpage'}
                    onClick={() => {
                      this.props.history.push('signin');
                    }}
                  />
                </Grid>

                <Grid id="reg_grid_main" container justifyContent="center" item xs={12} ref={this.inputGridRef}>
                  <form noValidate style={{ width: '100%' }}>
                    <Grid container justifyContent="center">
                      <Grid id="title_grid" container justifyContent="center" alignContent="center" item xs={12} md={6}>
                        <Typography
                          style={{
                            color: this.props.theme.palette.primary.main,
                            fontSize: this.state.matches ? '1.6em' : '2.2em',
                            fontFamily: 'NanumSquareNeo-Hv',
                            borderLeft: '20px double #003a8e',
                            borderRight: '20px double #003a8e',
                          }}
                        >
                          &nbsp;&nbsp;사전등록&nbsp;&nbsp;
                        </Typography>
                      </Grid>
                    </Grid>
                    {this.customSpacing('m')}

                    <Grid
                      container
                      justifyContent={this.state.matches ? 'center' : 'flex-start'}
                      style={{
                        borderBottom: '3px solid',
                        borderColor: this.props.theme.palette.primary.main,
                        fontWeight: 'bold',
                        paddingBottom: this.state.matches ? 4 : 8,
                        marginBottom: this.state.matches ? 4 : 8,
                      }}
                    >
                      <Typography
                        style={{
                          color: this.props.theme.palette.primary.main,
                          fontSize: this.state.matches ? '1.2em' : '1.4em',
                          fontWeight: 'bold',
                        }}
                      >
                        1. 등록자 정보 입력
                      </Typography>
                    </Grid>
                    <Grid container style={{ color: this.props.theme.palette.primary.main }}>
                      <Typography style={{ color: this.props.theme.palette.primary.main }}>
                        * 아래의 항목은 모두 <b style={{ color: '#f00' }}>필수적</b>으로 기입해야 하는 항목입니다.
                      </Typography>
                    </Grid>

                    <Grid container id="input_grid_main" style={{ margin: this.state.matches ? '8px 0' : '16px 0' }}>
                      <Grid
                        container
                        justifyContent={this.state.matches ? 'center' : 'flex-start'}
                        item
                        xs={12}
                        md={6}
                        className={classes.inputGrid}
                      >
                        <TextField
                          autoComplete="name"
                          name="name"
                          variant="outlined"
                          required
                          //   fullWidth
                          id="name"
                          label="이름"
                          onChange={(e) => this.handleChange('name', e)}
                          value={this.state.name}
                          className={classes.input}
                        />
                      </Grid>
                      <Grid
                        container
                        justifyContent={this.state.matches ? 'center' : 'flex-end'}
                        item
                        xs={12}
                        md={6}
                        className={classes.inputGrid}
                      >
                        <TextField
                          variant="outlined"
                          required
                          //   fullWidth
                          id="tel"
                          label={
                            <>
                              휴대전화번호(<b style={{ color: '#f00' }}>로그인 ID</b>)
                            </>
                          }
                          type="tel"
                          name="tel"
                          autoComplete="tel"
                          onChange={(e) => this.handleChangeNum('tel', e)}
                          value={this.state.tel}
                          style={{ backgroundColor: '#fff', borderRadius: '4px' }}
                          className={classes.input}
                        />
                      </Grid>
                      <Grid
                        container
                        justifyContent={this.state.matches ? 'center' : 'flex-start'}
                        item
                        xs={12}
                        md={6}
                        className={classes.inputGrid}
                      >
                        <TextField
                          variant="outlined"
                          required
                          id="email"
                          label="이메일주소"
                          type="email"
                          name="email"
                          autoComplete="email"
                          onChange={(e) => this.handleChange('email', e)}
                          value={this.state.email}
                          className={classes.input}
                        />
                      </Grid>

                      <Grid
                        container
                        justifyContent={this.state.matches ? 'center' : 'flex-end'}
                        item
                        xs={12}
                        md={6}
                        className={classes.inputGrid}
                      >
                        <TextField
                          variant="outlined"
                          required
                          //   fullWidth
                          id="position"
                          label="병원명"
                          name="position"
                          onChange={(e) => this.handleChange('position', e)}
                          value={this.state.position}
                          className={classes.input}
                        />
                      </Grid>
                    </Grid>
                    {this.customSpacing('s')}
                    {/* <Grid container>
                      <Grid container justifyContent={this.state.matches ? 'center' : 'flex-start'} item xs={12} md={6}>
                        <Typography style={{ color: '#1d415a', fontWeight: 'bold' }}>* 참석유형을 선택해 주세요.</Typography>
                        <Grid container justifyContent={this.state.matches ? 'center' : 'flex-start'}>
                          <FormControl>
                            <RadioGroup
                              row
                              value={this.state.attendType}
                              onChange={(e) => {
                                this.handleChange('attendType', e);
                              }}
                            >
                              <FormControlLabel control={<Radio value="현장" />} label="현장 참석" />
                              <FormControlLabel control={<Radio value="온라인" />} label="온라인 참석" />
                            </RadioGroup>
                          </FormControl>
                          <Typography style={{ color: 'transparent' }}>공백높이 조절용 텍스트</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {this.customSpacing('m')} */}
                    <Grid
                      container
                      justifyContent={this.state.matches ? 'center' : 'flex-start'}
                      item
                      xs={12}
                      style={{
                        borderBottom: '3px solid',
                        borderColor: this.props.theme.palette.primary.main,
                        paddingBottom: this.state.matches ? 4 : 8,
                      }}
                    >
                      <Typography
                        style={{
                          color: this.props.theme.palette.primary.main,
                          fontSize: this.state.matches ? '1.2em' : '1.4em',
                          fontWeight: 'bold',
                        }}
                      >
                        2. 개인정보 수집 및 이용에 대한 동의
                      </Typography>
                    </Grid>
                    {this.customSpacing('s')}
                    <Grid
                      id="terms"
                      style={{ border: '0.25px solid #555555', borderRadius: '5px', backgroundColor: 'rgba(255,255,255,0.5)' }}
                    >
                      <Typography
                        style={{
                          padding: this.state.matches ? '16px 8px' : '24px 16px',
                          wordBreak: 'keep-all',
                          lineHeight: this.state.matches ? 1.4 : 1.6,
                          fontSize: this.state.matches ? '1em' : '1.1em',
                        }}
                      >
                        (유)한국비엠에스제약("회사")은 선생님의 <b>[성명,휴대폰번호,병원명,이메일주소 등]("개인정보")</b>를 수집하며,
                        <br />
                        <br />위 정보는 회사가 주최하는{this.state.matches ? <br /> : <>&nbsp;</>}[ {EVENT_NAME} ] ("행사")의 진행 및 관련
                        활동의 수행을 위하여 수집&#183;이용됩니다.
                        <br /> <br />
                        회사는 이와 같은 수집 및 이용 목적이 달성될 때 까지 해당 개인정보를 보유 및 이용합니다. 또한 회사는 행사의 원활한
                        진행을 위하여 [(주)이노앤]에 해당 개인정보를 제공할 수 있으며, [(주)이노앤]은{' '}
                        <b>행사진행을 위한 개인정보 이용 목적을 달성할 때까지 해당 정보를 보유 및 이용합니다.</b>
                        <br /> <br />
                        선생님께서는 위와 같은 개인 정보의 수집,이용,보관 및 제3자 제공을 거부하실 수 있으나, 이 경우 행사 참석에 어려움이
                        발생할 수 있음을 알려 드립니다.
                      </Typography>
                    </Grid>
                    {this.customSpacing('s')}

                    <Grid
                      container
                      alignItems="center"
                      style={{ backgroundColor: '#e6f7fd', marginBottom: 8, borderRadius: 5, padding: 8 }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={this.state.isInfoAgree1}
                            color="secondary"
                            onChange={(e) => {
                              if (e.target.checked) {
                                this.setState({ isInfoAgree1: 'Y' });
                              } else {
                                this.setState({ isInfoAgree1: 'N' });
                              }
                            }}
                          />
                        }
                        labelPlacement="end"
                        label="본인은 개인정보의 수집 및 이용에 관한 설명을 모두 이해하고, 이에 동의 합니다."
                        className={classes.ctrlLabel}
                      />
                    </Grid>

                    <Grid container alignItems="center" style={{ backgroundColor: '#e6f7fd', borderRadius: 5, padding: 8 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={this.state.isInfoAgree2}
                            color="secondary"
                            onChange={(e) => {
                              if (e.target.checked) {
                                this.setState({ isInfoAgree2: 'Y' });
                              } else {
                                this.setState({ isInfoAgree2: 'N' });
                              }
                            }}
                          />
                        }
                        labelPlacement="end"
                        label="본인은 개인정보의 제3자 제공에 관한 설명을 모두 이해하고, 이에 동의 합니다."
                        className={classes.ctrlLabel}
                      />
                    </Grid>
                    {this.customSpacing('s')}
                    {/* 등록버튼 Grid */}
                    <Grid container justifyContent="center">
                      {(this.state.tel === '' || this.state.name === '' || this.state.position === '' || this.state.email === '') && (
                        <Grid container item xs={12} alignContent="center">
                          <Typography>
                            <b style={{ color: '#f00' }}>*&nbsp;</b>
                            <b>등록자 정보 입력</b>&nbsp;에 빈항목이 있습니다.
                          </Typography>
                        </Grid>
                      )}

                      {(this.state.isInfoAgree1 !== 'Y' || this.state.isInfoAgree2 !== 'Y') && (
                        <Grid container item xs={12} alignContent="center">
                          <Typography style={{ wordBreak: 'keep-all' }}>
                            <b style={{ color: '#f00' }}>*&nbsp;</b>
                            모든&nbsp;<b>필수 동의 항목</b>에 <b style={{ color: this.props.theme.palette.primary.main }}>동의</b> 체크 후
                            등록 가능합니다.
                          </Typography>
                        </Grid>
                      )}
                      {this.customSpacing('s')}
                      <Grid container justifyContent="center">
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            height: this.state.matches ? '55px' : '75px',
                            aspectRatio: this.state.matches ? '5/1' : '5.5/1',
                            borderRadius: 100,
                            position: 'relative',
                            backgroundColor: this.props.theme.palette.primary.main,
                            boxShadow: 'inset 0 0 0 2px #fff, inset 0 0 0 4px transparent, inset 0 0 0 6px #fff',
                          }}
                          onClick={this.handleSignUp}
                        >
                          <Typography style={{ fontSize: this.state.matches ? '1.15rem' : '1.35rem', color: '#fff' }}>등록하기</Typography>
                        </Button>
                      </Grid>
                      {this.customSpacing('m')}
                    </Grid>
                  </form>
                </Grid>

                {this.customSpacing('s')}
                <Grid container justifyContent="center" item xs={10} sm={9} md={8} lg={7}>
                  <Grid>
                    <img src={`${EVENT_IMG}/logo_1.png`} alt={''} style={{ width: '100%' }} />
                  </Grid>
                </Grid>
                {this.customSpacing('s')}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* loading progress */}
        {this.state.loading && (
          <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-15%, -50%)', zIndex: 9999 }}>
            <CircularProgress size={100} thickness={3} classes={{ circle: classes.circle }} style={{ opacity: 0.7 }} />
            <svg>
              <linearGradient id="linearColors">
                <stop offset="20%" stopColor={this.props.theme.palette.primary.main} />
                <stop offset="80%" stopColor={this.props.theme.palette.primary.main} />
              </linearGradient>
            </svg>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(JoinPage);

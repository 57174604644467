import dotenv from 'dotenv';
import CryptoJS from 'crypto-js';
import cloneDeep from 'lodash/cloneDeep';
import swal from 'sweetalert';
import { Auth } from 'aws-amplify';
import axios from 'axios';
dotenv.config();

export const EVENT_ID = 'evt235';
export const EVENT_CODE = 'BMS#250208';
export const EVENT_NAME = `Z-EXPERT SYMPOSIUM`; // 설문지, IE 의 강의실 타이틀
export const CHANNEL = ['ChannelA'];
export const CHANNEL_DP = ['Z-EXPERT SYMPOSIUM']; // 사이트 표시용 채널명
export const LOGIN_TB = 'innon_login_evt235';
export const ATTEND_TB = 'innon_attend_evt235';
export const ENTRY_TB = 'innon_entry_evt235';

export const TEST_DAY = 0;
export const TEST_HOUR = 0;
export const TEST_MINUTE = 0;
export const TEMP_MINUTE = 0;
export const IS_RELEASE = true;

export const EVENT_IMG = '/images/main';
export const COMMON_IMG = '/images/common';

export const API_URL = 'https://y0v99h4rz8.execute-api.ap-northeast-2.amazonaws.com/latest';
export const EVENTS_API_URL = 'https://ocpxoadfl8.execute-api.ap-northeast-2.amazonaws.com/latest';
export const BBS_API_URL = 'https://bikahhcyl6.execute-api.ap-northeast-2.amazonaws.com/latest';
export const BOOTH_API_URL = 'https://p8qthqkie0.execute-api.ap-northeast-2.amazonaws.com/latest';
export const ATTEND_API_URL = 'https://pbob5ldej5.execute-api.ap-northeast-2.amazonaws.com/latest';
export const CHAT_API_URL = 'https://6cwxeo3mwd.execute-api.ap-northeast-2.amazonaws.com/la';
export const POSTER_API_URL = 'https://wgutlysk6b.execute-api.ap-northeast-2.amazonaws.com/latest';
export const PROGRAM_API_URL = 'https://2ecuzxhg64.execute-api.ap-northeast-2.amazonaws.com/latest';
export const QNA_API_URL = 'https://35tz448i1c.execute-api.ap-northeast-2.amazonaws.com/latest';
export const SURVEY_API_URL = 'https://v8nkwgol87.execute-api.ap-northeast-2.amazonaws.com/latest';
export const USER_API_URL = 'https://n68lvmggvf.execute-api.ap-northeast-2.amazonaws.com/latest';

export const WEBSOCKET_URL = 'wss://64au02hcs1.execute-api.ap-northeast-2.amazonaws.com/Prod';
export const INNON_WEBSOCKET_URL = 'wss://f36a4qz1th.execute-api.ap-northeast-2.amazonaws.com/Prod';
export const SIGNIN_URL = 'https://g6a1kkh5ti.execute-api.ap-northeast-2.amazonaws.com/Prod/signin';
export const SIGNOUT_URL = 'https://g6a1kkh5ti.execute-api.ap-northeast-2.amazonaws.com/Prod/signout';
export const ATTEND_URL = 'https://mumm66g0k3.execute-api.ap-northeast-2.amazonaws.com/Prod';
export const MQ_URL = 'wss://b-8b895e6a-50b0-474a-8dcc-f7cc095bb701-1.mq.ap-northeast-2.amazonaws.com:61619';

export const NOTICE_EMERGENCY = 'NOTICE_EMERGENCY';
export const FORCE_REFRESH = 'FORCE_REFRESH';
export const FORCE_LOGOUT = 'FORCE_LOGOUT';
export const CHECK_STATUS = 'CHECK_STATUS';
export const RESET_EVENT = 'RESET_EVENT';

export const TOPIC_VOTING_START = 'VOTING_START';

export const TOPIC_SESSION_CLOSE = 'SESSION_CLOSE';
export const TOPIC_SESSION_START = 'SESSION_START';
export const TOPIC_QNA_REG = 'QNA_R';
export const TOPIC_QNA_APPROVE = 'QNA_A';
export const TOPIC_QNA_SELECT = 'QNA_S';
export const VOTING_NAME = 'ChannelA';

export const JWPLAYER_ID = 'grRptRQ9';

export const encrypt = (str) => {
  const hash = CryptoJS.MD5(str);
  const key = CryptoJS.enc.Utf8.parse(hash); // hex로 변환
  return CryptoJS.enc.Base64.stringify(key);
};

export const decrypt = (str) => {
  const decrypt = CryptoJS.enc.Base64.parse(str);
  return decrypt.toString(CryptoJS.enc.Utf8);
};

export const encryptAES = (str) => {
  return CryptoJS.AES.encrypt(str, process.env.REACT_APP_ENC_KEY);
};

export const decryptAES = (str) => {
  const dc = CryptoJS.AES.decrypt(str, process.env.REACT_APP_ENC_KEY);
  return dc.toString(CryptoJS.enc.Utf8);
};

export const getUserID = (isPost) => {
  if (isPost) {
    return `${sessionStorage.getItem('_in_user_')}`;
  } else {
    return encodeURIComponent(`${sessionStorage.getItem('_in_user_')}`);
  }
};

export const getEventInfo = (callback) => {
  axios
    .get(`${EVENTS_API_URL}/event/info?eventId=${EVENT_ID}&code=${encodeURIComponent(EVENT_CODE)}`, {
      headers: { Pragma: 'no-cache' },
    })
    .then((_result) => {
      if (_result.data.Item) _result.data.Item.code = null;
      sessionStorage.setItem('Item', JSON.stringify(_result.data.Item));
      if (callback) callback();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getArrDate = () => {
  if (!sessionStorage.getItem('Item')) {
    getEventInfo(getArrDate);
  }
  let item = JSON.parse(sessionStorage.getItem('Item'));
  if (!item) return null;
  let startDate = item.startDate;
  let endDate = item.endDate;
  startDate = new Date(startDate.substring(0, 4), startDate.substring(4, 6) - 1, startDate.substring(6, 8));
  endDate = new Date(endDate.substring(0, 4), endDate.substring(4, 6) - 1, endDate.substring(6, 8));
  let buffs = [];
  let continueDate = startDate;
  while (continueDate.getTime() <= endDate.getTime()) {
    buffs.push(cloneDeep(continueDate));
    continueDate.setDate(continueDate.getDate() + 1);
  }
  return buffs;
};

export const minuteToFormat = (_m) => {
  const acTime = new Date(0, 0, 0, 0, _m, 0, 0);
  const diffHour = acTime.getHours(); // 시간
  const diffMin = acTime.getMinutes(); // 분
  const diffSec = acTime.getSeconds(); // 초
  return displayTime(`${diffHour}:${diffMin}:${diffSec}`);
};

export const msToPoint = (_t) => {
  const min = Math.floor((_t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) * 60 + Math.floor((_t % (1000 * 60 * 60)) / (1000 * 60));
  return min / 60;
};

export const displayTime = (st) => {
  if (st.length === 0) return '';
  let arr = st.split(':');
  const h = Number(arr[0]) < 10 ? '0' + arr[0] : arr[0];
  const m = Number(arr[1]) < 10 ? '0' + arr[1] : arr[1];
  // const s = Number(arr[2]) < 10 ? '0' + arr[2] : arr[2];
  // return `${h}:${m}:${s}`;
  return `${h}:${m}`;
};
export const getEventDays = () => {
  let item = JSON.parse(sessionStorage.getItem('Item'));
  let startDate = item.startDate;
  let endDate = item.endDate;

  startDate = new Date(startDate.substring(0, 4), startDate.substring(4, 6) - 1, startDate.substring(6, 8));
  endDate = new Date(endDate.substring(0, 4), endDate.substring(4, 6) - 1, endDate.substring(6, 8));

  let buffs = [];
  let buffsValue = [];
  let continueDate = startDate;
  while (continueDate.getTime() <= endDate.getTime()) {
    let value =
      continueDate.getFullYear() +
      (continueDate.getMonth() + 1 > 9 ? '' : '0') +
      (continueDate.getMonth() + 1) +
      (continueDate.getDate() > 9 ? '' : '0') +
      continueDate.getDate();

    buffsValue.push(value);
    buffs.push(cloneDeep(continueDate));

    continueDate.setDate(continueDate.getDate() + 1);
  }

  return { _buffs: buffs, _buffsValue: buffsValue };
};

export const pad02 = (input) => {
  let inputData = Number(input);
  let outputData = '';
  if (inputData < 10) {
    outputData = '0' + inputData;
  } else {
    outputData = '' + inputData;
  }
  return outputData;
};

export const groupBy = (array, key) => {
  return array.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
export const KR_MOBILE_REG = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})(\d{4})$/;
export const krMobileValidation = (mobile) => {
  if (KR_MOBILE_REG.test(mobile)) {
    return true;
  } else {
    return false;
  }
};
export const focusElementRef = (msg, ref) => {
  swal(msg).then(() => {
    ref.current.focus();
  });
};

export const isIE = () => {
  const agent = navigator.userAgent.toLowerCase();
  if ((navigator.appName === 'Netscape' && navigator.userAgent.search('Trident') !== -1) || agent.indexOf('msie') !== -1) {
    return true;
  } else {
    return false;
  }
};

export const tokenRefresh = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      if (session) {
        const { idToken } = session;
        sessionStorage.setItem('_in_jwt', idToken.jwtToken);
        return Promise.resolve(idToken.jwtToken);
      } else {
        console.log(err.message);
      }
    });
  } catch (e) {
    console.log('Unable to refresh Token', e);
  }
};

import { EVENT_ID, EVENTS_API_URL, IS_RELEASE, TEST_DAY, TEST_HOUR } from '../constants';
import axios from 'axios';
import { Component } from 'react';

class QnaComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      now: new Date(),
      tickSecond: null,
      tickMinute: null,
      session: null,
      sessionStart: '',
      sessionEnd: '',
      callback: null,
    };
    console.log('CREATE QNAComponent');
  }

  setCallbackFunc = (func) => {
    this.setState({ callback: func });
  };

  customComponentWillUnmount = () => {
    console.log('componentWillUnmount');
    window.removeEventListener('focus', this.syncLocalTime.bind(this), false);
  };

  customComponentDidMount = () => {
    console.log('componentDidMount');
    window.addEventListener('focus', this.syncLocalTime.bind(this), false);

    const now = new Date();
    const today = `${now.getFullYear()}${now.getMonth() + 1}${now.getDate() + TEST_DAY}`;

    //console.log('getList');
    //console.log(this.state.channelId);

    axios
      .get(`${EVENTS_API_URL}/event/pgmByDateChannel?eventId=${EVENT_ID}&date=${today}&channel=${this.state.channelId}`, {
        headers: { Pragma: 'no-cache' },
      })
      .then((response) => {
        response.data.Items.sort(function (a, b) {
          return a.startTime > b.startTime ? 1 : -1;
        });
        this.setState({ program: response.data.Items });
        this.getCurrentSession();
      });

    this.init();
  };

  init() {
    this.syncLocalTime();
  }

  // 이름이 이래서 그렇지 lacalTime은 60초 마다 한번씩 서버타임과 한번씩 싱크를 맞춘다.
  syncLocalTime() {
    if (this.state.tickMinute !== null) {
      clearInterval(this.state.tickMinute);
      this.setState({ tickMinute: null });
    }
    this.syncServerTIme();
    let buff = setInterval(this.syncServerTIme.bind(this), 60000);
    this.setState({ tickMinute: buff });
  }

  startSecondInterval(date) {
    if (this.state.tickSecond !== null) {
      clearInterval(this.state.tickSecond);
      this.setState({ tickSecond: null });
    }
    const func = () => {
      //console.log('call function');
      date = new Date(date.getTime() + 1000);
      this.setState({ now: date });

      //console.log(date);
      let finalEnd = new Date(this.state.sessionEnd);
      finalEnd.setMinutes(finalEnd.getMinutes() + 5);

      if (this.state.sessionEnd && this.state.now.getTime() >= finalEnd.getTime()) {
        this.getCurrentSession();
        this.setState({ isEntry: false });
      }
    };
    func();
    let buff = setInterval(func, 1000);

    this.setState({ tickSecond: buff });
  }

  syncServerTIme() {
    let timeServer = '';
    if (IS_RELEASE) {
      timeServer = `${EVENTS_API_URL}/event/serverTime`;
    } else {
      timeServer = `http://61.254.62.161:3005/`;
    }

    console.log('syncServerTIme');
    axios.get(timeServer, { headers: { Pragma: 'no-cache' } }).then((response) => {
      console.log(response.data.SYSDATE);

      let yy = response.data.SYSDATE.substring(0, 4);
      let mm = response.data.SYSDATE.substring(5, 7);
      let dd = response.data.SYSDATE.substring(8, 10);
      let h = response.data.SYSDATE.substring(11, 13);
      let m = response.data.SYSDATE.substring(14, 16);
      let s = response.data.SYSDATE.substring(17, 19);

      let date = new Date(Number(yy), Number(mm), Number(dd), Number(h), Number(m), Number(s));

      //TODO for TEST
      const now = new Date();
      //date = new Date(now.getFullYear(), now.getMonth(), now.getDate() + TEST_DAY, Number(h), Number(m), Number(s));
      if (TEST_DAY !== 0) {
        date = new Date(now.getFullYear(), now.getMonth(), now.getDate() + TEST_DAY, Number(h) + TEST_HOUR, Number(m), Number(s));
      } else {
        date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), Number(h), Number(m), Number(s));
      }
      this.setState({ now: date });
      this.getCurrentSession();

      this.startSecondInterval(date);
    });
  }

  getCurrentSession() {
    if (this.state.program) {
      this.state.program.map((data) => {
        //const dt = data.pgDate.substring(0, 4) + '-' + data.pgDate.substring(4, 6) + '-' + data.pgDate.substring(6, 8);
        // const startTime = new Date(dt + ' ' + data.startTime);
        // const endTime = new Date(dt + ' ' + data.endTime);
        const arrST = data.startTime.split(':');
        const arrET = data.endTime.split(':');
        const startTime = new Date(
          Number(data.pgDate.substring(0, 4)),
          Number(data.pgDate.substring(4, 6)) - 1,
          Number(data.pgDate.substring(6, 8)),
          Number(arrST[0]),
          Number(arrST[1]),
          0,
        );
        const endTime = new Date(
          Number(data.pgDate.substring(0, 4)),
          Number(data.pgDate.substring(4, 6)) - 1,
          Number(data.pgDate.substring(6, 8)),
          Number(arrET[0]),
          Number(arrET[1]),
          0,
        );
        let finalStart = startTime;
        finalStart.setMinutes(finalStart.getMinutes() - 5);

        if (this.state.now && this.state.now.getTime() >= finalStart.getTime() && this.state.now.getTime() < endTime.getTime()) {
          console.log('session', data.id + ':' + data.session + ':' + data.startTime + '~' + data.endTime);

          this.setState({ session: data, sessionStart: startTime, sessionEnd: endTime });

          if (this.state.callback) {
            this.state.callback('');
          }
        } else {
          //TODO 중간 쉬는 시간 표시 방법 확인
        }

        return '';
      });
    }
  }

  displayTime = (date) => {
    let rtn = '';
    rtn += '' + date.getFullYear() + '-';
    rtn += '' + (date.getMonth() + 1) + '-';
    rtn += '' + date.getDate() + ' ';
    rtn += '' + this.numberPad(date.getHours(), 2) + ':';
    rtn += '' + this.numberPad(date.getMinutes(), 2) + ':';
    rtn += '' + this.numberPad(date.getSeconds(), 2);
    return rtn;
  };

  numberPad = (n, width) => {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  };
}

export default QnaComponent;

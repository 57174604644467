import React, { Component } from 'react';
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TextField,
  withStyles,
  FormControlLabel,
} from '@material-ui/core';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Rating from '@mui/material/Rating';

import CloseIcon from '@material-ui/icons/Close';
import {
  getUserID,
  SURVEY_API_URL,
  CHANNEL,
  EVENT_ID,
  EVENT_NAME,
  // EVENT_NAME, EVENT_ID
} from '../../constants';
import axios from 'axios';
import swal from 'sweetalert';
import './SurveyDialog.css';
import cloneDeep from 'lodash/cloneDeep';

const styles = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} style={{ width: 36, height: 36 }}>
          <CloseIcon style={{ color: '#000' }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CustomSubTitleCell = withStyles((theme) => ({
  root: {
    backgroundColor: '#013d6f',
    color: '#fff',
    textAlign: 'left',
    padding: 8,
    fontSize: 'large',

    [theme.breakpoints.down('md')]: {},
  },
}))(TableCell);

class SurveyDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:600px)').matches,
      activeStep: 0,
      qCount: 0,
      open: this.props.open,
      quizIdx: 0,
      quizData: [],
      confirmOpen: false,
      confirmSubmit: false,
      surveyList: [],
      opportunityOther: '',
      selectCheck: [],
      star: [],
    };

    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.quizSubmit = this.quizSubmit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.data);
    // console.log('quizIdx', this.props.quizIdx);
    // const pgmId = sessionStorage.getItem('_in_session');

    // this.setState({ quizData: data, qCount: data.length });
    // console.log('설문가져오기', `${SURVEY_API_URL}/polls?eventId=${EVENT_ID}&channel=${CHANNEL}`);
    axios
      .get(`${SURVEY_API_URL}/polls?eventId=${EVENT_ID}&channel=${CHANNEL}`, {
        headers: { Pragma: 'no-cache' },
        // .get(`${API_URL}/event/polls?eventId=${EVENT_ID}&channel=${CHANNEL}`, {
        //   headers: { Pragma: 'no-cache' },
      })
      .then((res) => {
        this.setState({ surveyList: res.data.Items });

        const sessionData = this.state.surveyList;
        this.setState({
          quizIdx: 0,
          quizData: [],
        });
        // eslint-disable-next-line
        this.state.surveyList.map((d) => {
          this.setState({ [d.id]: '' });
        });

        sessionData.sort((a, b) => {
          return a.orderNo - b.orderNo;
        });

        // console.log('Poll >>>>>>>');
        // console.log(sessionData);
        // console.log(sessionData.length);
        // console.log('<<<<<<<<<<<<');

        this.setState({ quizData: sessionData, qCount: sessionData.length });
        console.log(sessionData);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onOpen() {
    this.setState({ open: true });
  }

  onClose(_isSubmit) {
    this.setState({ confirmOpen: true, confirmSubmit: false });

    // if (this.state.quizAnswer && Object.keys(this.state.quizAnswer).length === this.state.qCount) {
    //   console.log(this.state.quizAnswer);
    //
    //   // this.props.onClose();
    // } else {
    //   // swal('문제 다안풀었어');
    //   this.setState({ confirmOpen: true });
    // }
  }

  onSubmit() {
    this.setState({ confirmSubmit: true, confirmOpen: true });
  }

  quizSubmit() {
    const keys = Object.keys(this.state.quizAnswer);
    let arrAnswer = [];
    for (let k of keys) {
      arrAnswer.push({ pollId: k, val: this.state.quizAnswer[k] });
    }

    fetch(`${SURVEY_API_URL}/survey`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: `userid=${getUserID()}&evt=evt503&surveyAnswer=${JSON.stringify(arrAnswer)}`,
    })
      .then((res) => {
        console.log(res);
        swal('', '설문조사에 응해주셔서 감사합니다.', 'success');
        this.props.onClose(this.props.quizIdx, arrAnswer);
      })
      .catch((err) => {
        console.log(err);
        swal('', '정상적으로 등록되지 않았습니다. 다시 시도해주세요.', 'error');
      });
  }

  handleNext() {
    if (this.state.activeStep < this.state.qCount - 1) {
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
  }
  handlePrev() {
    if (this.state.activeStep > 0) {
      this.setState({ activeStep: this.state.activeStep - 1 });
    }
  }
  handleClose(_val) {
    this.setState({ confirmOpen: false });
    //this.props.onClose();

    if (_val === 'Y') {
      this.setState({ confirmOpen: false, open: false });
      if (this.state.confirmSubmit) {
        this.quizSubmit();
      } else {
        //미작성 시에도 강의실 입장 동안은 팝업 안뜨게
        sessionStorage.setItem('isPopup', 'Y');
        this.props.onClose();
      }
    } else if (_val === 'N') {
      this.setState({ confirmOpen: false });
    }
  }

  change(e, idx) {
    // console.log('idx', idx, 'e', e.target.value);
    let arr = {};
    if (this.state.quizAnswer) {
      arr = this.state.quizAnswer;
    }

    var quiz = this.state.quizData[idx];

    if (quiz.type === 'radio') {
      arr[quiz.id] = quiz.a.indexOf(e.target.value);
    } else if (quiz.type === 'text') {
      arr[quiz.id] = e.target.value;
    } else if (quiz.type === 'star') {
      arr[quiz.id] = e.target.value;
    } else if (quiz.type === 'checkbox') {
      arr[quiz.id] = this.state.selectCheck;
    }

    this.setState({ quizAnswer: arr, [quiz.id]: e.target.value });
  }

  handleCheckbox(e) {
    if (e.target.checked) {
      this.selectSession(e.target.value);
    } else {
      this.deSelectSession(e.target.value);
    }
  }

  selectSession(id) {
    for (let i = 0; i < this.state.selectCheck.length; i++) {
      if (this.state.selectCheck[i] === id) {
        return;
      }
    }
    let buff = cloneDeep(this.state.selectCheck);
    buff.push(id);

    this.setState({ selectCheck: buff });
  }

  deSelectSession(id) {
    let buff = cloneDeep(this.state.selectCheck);
    for (let i = 0; i < this.state.selectCheck.length; i++) {
      if (this.state.selectCheck[i] === id) {
        buff.splice(i, 1);
        break;
      }
    }
    this.setState({ selectCheck: buff });
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.onClose();
            }
          }}
          fullWidth={true}
          maxWidth={'md'}
        >
          <DialogTitle style={this.state.matches ? { textAlign: 'center', wordBreak: 'keep-all' } : { textAlign: 'center' }}>
            <Grid container item xs={12}>
              <Grid container item xs={2} />
              <Grid container item xs={8} alignContent="center" justifyContent="center">
                <Typography
                  style={{
                    lineHeight: this.state.matches ? 1 : 1.2,
                    wordBreak: 'keep-all',
                    color: this.props.theme.palette.primary.main,
                    fontFamily: 'NanumSquareNeo-Hv',
                    fontSize: this.state.matches ? '1.1em' : '1.35em',
                  }}
                >
                  {EVENT_NAME}
                </Typography>
              </Grid>
              <Grid container item xs={2} alignContent="center" justifyContent="flex-end">
                <IconButton onClick={this.onClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers style={{ minHeight: '48vh' }}>
            <div className={classes.root}>
              {this.state.quizData && this.state.quizData.length > 0 ? (
                <Paper square elevation={0} className={classes.header}>
                  {
                    <React.Fragment>
                      <Grid container>
                        {this.state.quizData.map((aitem, idx) => {
                          return (
                            <Grid
                              container
                              justifyContent="center"
                              key={`aitem_${idx}`}
                              style={{ paddingBottom: this.state.matches ? 0 : 0 }}
                            >
                              {/* 객관식 단수선택 */}
                              {this.state.quizData[idx].type === 'radio' && (
                                <Grid container item xs={12} lg={11}>
                                  <Grid>
                                    <Typography
                                      style={{
                                        color: this.props.theme.palette.primary.main,
                                        fontWeight: 'bold',
                                        fontSize: 'large',
                                      }}
                                      dangerouslySetInnerHTML={{ __html: aitem.q }}
                                    />
                                  </Grid>
                                  <Grid container direction="column">
                                    <Grid style={{ padding: '8px 0px' }}>
                                      <Typography style={{ color: 'grey' }}>전혀 추천하고 싶지 않다.</Typography>
                                    </Grid>
                                    <RadioSurvey
                                      quizData={this.state.quizData}
                                      activeStep={idx}
                                      onChange={(e, activeStep) => {
                                        this.change(e, activeStep);
                                      }}
                                    />
                                    <Grid style={{ padding: '8px 0px' }}>
                                      <Typography style={{ color: '#000' }}>적극 추천한다.</Typography>
                                    </Grid>
                                  </Grid>

                                  {/* 
                                  {aitem.pgTitle && aitem.pgTitle.length > 3 && (
                                    <Grid container style={{ padding: this.state.matches ? '16px 0 8px' : '32px 0 16px' }}>
                                      <Typography
                                        style={{
                                          color: this.props.theme.palette.primary.main,
                                          fontWeight: 800,
                                          fontSize: this.state.matches ? '1.2em' : '1.4em',
                                        }}
                                      >
                                        {aitem.pgTitle}
                                      </Typography>
                                    </Grid>
                                  )}

                                  <Table>
                                    <TableHead>
                                      {this.state.quizData[idx].sessionNo === '100' ? (
                                        <>
                                          <TableRow>
                                            <CustomSubTitleCell colSpan={5}>&nbsp;&nbsp;{aitem.q}</CustomSubTitleCell>
                                          </TableRow>
                                          <TableRow>
                                            <CustomSubTitleCell colSpan={5} style={{ textAlign: 'right', wordBreak: 'keep-all' }}>
                                              {aitem.pgmId}&nbsp;&nbsp;
                                            </CustomSubTitleCell>
                                          </TableRow>
                                        </>
                                      ) : (
                                        <TableRow>
                                          <CustomSubTitleCell colSpan={5}>&nbsp;&nbsp;{aitem.q}</CustomSubTitleCell>
                                        </TableRow>
                                      )}
                                    </TableHead>

                                    <TableBody>

                                    </TableBody>
                                  </Table> */}
                                </Grid>
                              )}
                              {/* 객관식 복수선택 */}
                              {this.state.quizData[idx].type === 'checkbox' && (
                                <Grid container item xs={12} lg={11}>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <CustomSubTitleCell>
                                          <Typography
                                            style={{ color: '#fff', fontWeight: 'bold' }}
                                            dangerouslySetInnerHTML={{ __html: aitem.q }}
                                          />
                                        </CustomSubTitleCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      <Grid
                                        onChange={(e) => {
                                          this.change(e, idx);
                                        }}
                                        style={{ width: '100%' }}
                                      >
                                        {this.state.quizData[idx].a.map((c_item, c_idx) => {
                                          return (
                                            <Grid
                                              container
                                              justifyContent="flex-start"
                                              item
                                              xs={12}
                                              sm={12}
                                              md={6}
                                              lg={6}
                                              xl={6}
                                              key={`${c_item}_${c_idx}`}
                                              style={{ padding: this.state.matches ? 8 : '8px 16px' }}
                                            >
                                              <FormControlLabel
                                                control={
                                                  <input
                                                    type="checkbox"
                                                    onChange={(e) => this.handleCheckbox(e, this.state.quizData[idx])}
                                                  />
                                                }
                                                value={c_item}
                                                label={c_item}
                                              />
                                            </Grid>
                                          );
                                        })}
                                      </Grid>
                                    </TableBody>
                                  </Table>
                                </Grid>
                              )}
                              {this.state.quizData[idx].type === 'star' && (
                                <Grid container style={{ padding: this.state.matches ? '16px 0' : '16px 0 24px' }} item xs={12} lg={11}>
                                  <Grid container>
                                    <Typography
                                      style={{ color: this.props.theme.palette.primary.main, fontWeight: 'bold' }}
                                      dangerouslySetInnerHTML={{ __html: aitem.q }}
                                    />
                                  </Grid>
                                  <Grid container>
                                    <Typography dangerouslySetInnerHTML={{ __html: aitem.pgTitle }} style={{ fontWeight: 'bold' }} />
                                  </Grid>
                                  <Grid
                                    container
                                    justifyContent={this.state.matches ? 'center' : 'flex-start'}
                                    style={{ padding: this.state.matches ? 8 : 16 }}
                                  >
                                    <Rating
                                      name="star"
                                      defaultValue={0}
                                      precision={1}
                                      size="large"
                                      max={Number(aitem.pgmId) || 5}
                                      style={{
                                        backgroundColor: 'rgb(45,59,117,0.25)',
                                        color: '#fff',
                                        padding: this.state.matches ? 8 : 16,
                                        borderRadius: this.state.matches ? 24 : 48,
                                      }}
                                      onChange={(e) => {
                                        this.change(e, idx);
                                      }}
                                      key={idx}
                                    />
                                  </Grid>
                                </Grid>
                              )}

                              {/* 주관식 */}
                              {this.state.quizData[idx].type === 'text' && (
                                <Grid container style={{ paddingTop: this.state.matches ? 16 : 24 }} item xs={12} lg={11}>
                                  <Grid container style={{ padding: '8px 0px' }}>
                                    <Typography
                                      style={{
                                        color: this.props.theme.palette.primary.main,
                                        fontWeight: 'bold',
                                        fontSize: 'large',
                                      }}
                                      dangerouslySetInnerHTML={{ __html: aitem.q }}
                                    />
                                  </Grid>
                                  <Grid container>
                                    <Typography dangerouslySetInnerHTML={{ __html: aitem.pgTitle }} style={{ fontWeight: 'bold' }} />
                                  </Grid>

                                  <TextSurvey
                                    quizData={this.state.quizData}
                                    activeStep={idx}
                                    onChange={(e, activeStep) => {
                                      this.change(e, activeStep);
                                    }}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          );
                        })}
                      </Grid>
                    </React.Fragment>
                  }
                </Paper>
              ) : (
                <div></div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Grid container alignItems="center">
              <Grid container justifyContent="center" alignItems="center">
                <Button
                  autoFocus
                  onClick={this.onSubmit}
                  color="primary"
                  variant={'contained'}
                  size={'large'}
                  fullWidth={false}
                  style={{ margin: 10, height: 65 }}
                  disabled={!this.state.quizAnswer || Object.keys(this.state.quizAnswer).length !== this.state.qCount}
                >
                  <Typography>설문지 제출하기</Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.confirmOpen}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.handleClose();
            }
          }}
          // onClose={this.handleClose}
          // disableBackdropClick={true}
          // disableEscapeKeyDown={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Grid container justifyContent="center" style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
              {'Confirm'}
            </Grid>
          </DialogTitle>
          <DialogContent>
            {this.state.confirmSubmit ? (
              <div>
                설문지를 제출하시겠습니까? <br /> 제출하기를 누른 이후에는 내용을 수정할 수 없습니다.
              </div>
            ) : (
              <div>
                설문지를 제출하지 않았습니다. <br /> 창을 닫으시겠습니까?
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="center">
              <Button style={{ fontWeight: 'bold' }} onClick={() => this.handleClose('Y')} color="primary">
                확인
              </Button>
              <Button style={{ fontWeight: 'bold' }} onClick={() => this.handleClose('N')} color="primary" autoFocus>
                취소
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
class RadioSurvey extends React.Component {
  handleChange(event) {
    this.props.onChange(event, this.props.activeStep);
  }
  render() {
    const qname = 'option' + this.props.activeStep;
    const qoptions = this.props.quizData[this.props.activeStep].a.map((radioItem, idx) => {
      return (
        <Grid container key={idx} alignItems="center">
          <Grid
            container
            alignItems="center"
            style={{
              backgroundColor: `rgba(233, 77, 48, ${Number(0.2 + 0.1 * Number(idx))}`,
              borderRadius: 100,
              height: '40px',
              width: '90px',
              marginBottom: 8,
            }}
          >
            <input
              type="radio"
              key={this.props.activeStep}
              name={qname}
              value={this.props.quizData[this.props.activeStep].a[idx]}
              onChange={(e) => {
                this.handleChange(e, this.props.activeStep);
              }}
              style={{
                width: '24px', // 원의 너비
                height: '24px', // 원의 높이
                cursor: 'pointer', // 마우스 커서
                outline: 'none', // 포커스 outline 제거
                accentColor: '#e94d30',
              }}
            />

            <Typography style={{ color: '#fff', fontFamily: 'NanumSquareNeo-Hv' }}>
              &nbsp;&nbsp;{this.props.quizData[this.props.activeStep].a[idx]}
            </Typography>
          </Grid>
        </Grid>
      );
    }, this);
    return <Grid>{qoptions}</Grid>;
    //onchange name
  }
}
// class CheckSurvey extends React.Component {}

class TextSurvey extends React.Component {
  handleChange(event) {
    this.props.onChange(event, this.props.activeStep);
  }
  render() {
    return (
      <Grid container>
        <TextField
          fullWidth
          multiline
          minRows={3}
          variant="outlined"
          key={this.props.activeStep}
          onChange={(e) => {
            this.handleChange(e, this.props.activeStep);
          }}
          style={{}}
        />
      </Grid>
    );
  }
}
export default withStyles(styles, { withTheme: true })(SurveyDialog);

import React, { Component } from 'react';
import { Button, Grid, Select, MenuItem } from '@material-ui/core';

import {
  EVENT_ID,
  CHANNEL,
  MQ_URL,
  QNA_API_URL,
  TOPIC_QNA_APPROVE,
  TOPIC_QNA_REG,
  TOPIC_QNA_SELECT,
  TOPIC_SESSION_CLOSE,
  TOPIC_SESSION_START,
} from '../constants';
import axios from 'axios';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
import Stomp from 'stompjs';
import cloneDeep from 'lodash/cloneDeep';
// import queryString from 'querystring';

import QnaComponent from './qnaComponent';
import swal from 'sweetalert';

import './qnaFilter.css';

Amplify.configure(awsconfig);

class QnaFilter extends QnaComponent {
  constructor(props) {
    super(props);
    //change default 채널명 불러오기
    let param = { ch: CHANNEL };
    // let param = queryString.parse(this.props.location.search.replace('?', ''));

    //console.log(param);

    this.state = {
      channelId: param.ch,
      qnaList: [],
    };

    this.getQnAList = this.getQnAList.bind(this);
    this.callFunc = this.callFunc.bind(this);
  }

  callFunc(value) {
    /*
    if (this.state.session && this.state.session.id) {
      this.getQnAList(this.state.session.id);
    }
    */
    this.getQnAList('1');
  }

  getQnAList(pgmId) {
    if (!pgmId) {
      return;
    }
    //console.log(`${QNA_API_URL}/qna/list?channel=${this.state.channelId}&eventId=${EVENT_ID}&pgmId=${pgmId}`);
    axios
      .get(`${QNA_API_URL}/qna/list?channel=${this.state.channelId}&eventId=${EVENT_ID}&pgmId=${pgmId}`, {
        headers: { Pragma: 'no-cache' },
      })
      .then((response) => {
        response.data.Items.sort(function (a, b) {
          return a.id < b.id ? 1 : -1;
        });
        let buffs = [];
        for (let i = 0; i < response.data.Items.length; i++) {
          let data = response.data.Items[i];
          if (data.qna_status === 'S') {
            data.qna_status = 'A';
          }
          buffs.push(data);
        }

        this.setState({
          qnaList: buffs,
        });
      });
  }

  componentWillUnmount() {
    this.customComponentWillUnmount();
  }

  componentDidMount() {
    this.setCallbackFunc(this.callFunc);
    this.customComponentDidMount();

    const clientId = 'qna-admin-' + Math.floor(Math.random() * 100000 + 1);

    const headers = {
      login: 'atozsoft',
      passcode: 'atozsoft0901',
      'client-id': clientId,
    };
    const CH = this.state.channelId;
    const client = Stomp.client(MQ_URL);
    client.connect(
      headers,
      () => {
        console.log('<< connect', new Date());

        client.subscribe(
          '/topic/' + EVENT_ID + '_' + CH,
          (data) => {
            console.log(data);
            if (data.command === 'MESSAGE') {
              let cData = JSON.parse(data.body);
              cData = cData.status;
              switch (cData) {
                case TOPIC_QNA_REG:
                  this.getQnAList('1');
                  break;
                case TOPIC_QNA_APPROVE:
                  break;
                case TOPIC_QNA_SELECT:
                  break;
                case TOPIC_SESSION_START:
                  break;
                case TOPIC_SESSION_CLOSE:
                  break;
                default:
                  break;
              }
            }
          },
          headers,
        );
      },
      function () {
        console.log('error', new Date());
      },
    );

    this.getQnAList('1');
    setInterval(() => {
      this.getQnAList('1');
    }, 10000);
  }
  render() {
    const onChangeQnaState = (data) => {
      data.channel = this.state.channelId;
      axios.put(`${QNA_API_URL}/qna/status`, data).then((response) => {
        if (response.status === 200) {
          let buff = cloneDeep(this.state.qnaList);
          for (let i = 0; i < buff.length; i++) {
            if (buff[i].id === data.qnaId) {
              buff[i].qna_status = response.data.Attributes.qna_status;
              break;
            }
          }

          this.setState({
            qnaList: buff,
          });
        }
      });
    };

    const mapToComponent = (datas) => {
      return datas.map((qna, i) => {
        return (
          <Grid container item xs={12} key={i}>
            <QnaItem qna={qna} onChangeQnaState={onChangeQnaState} />
          </Grid>
        );
      });
    };

    const onResetList = () => {
      console.log('RESET LIST');
      let buff = '';
      for (let i = 0; i < this.state.qnaList.length; i++) {
        // warnings
        // if (i != 0) {
        if (i !== 0) {
          buff += ',';
        }
        buff += this.state.qnaList[i].id;
      }

      let param = { resetList: buff, eventId: EVENT_ID };

      console.log(param);
      swal({
        title: '리스트를 정말 초기화 하시겠습니까?',
        text: '( 초기화 후에도 행사 데이터에는 내용이 유지됩니다. )',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willReset) => {
        if (willReset) {
          axios.put(`${QNA_API_URL}/qna/reset`, param).then((response) => {
            console.log(response);
            this.getQnAList('1');
          });
          swal('', '초기화 되었습니다.', 'success');
        } else {
        }
      });
      // axios.put(`${QNA_API_URL}/qna/reset`, param).then((response) => {
      //   console.log(response);
      //   this.getQnAList('1');
      // });
    };

    return (
      <div style={{ height: '100%', overflowY: 'auto' }}>
        <Grid container item xs={12}>
          <Grid container justify={this.state.matches ? 'center' : 'flex-end'} item xs={11}>
            <Button
              variant="contained"
              color="primary"
              onClick={onResetList}
              style={{
                width: this.state.matches ? '50%' : '10%',
                padding: this.state.matches ? '2% 0' : '0.5% 0',
                fontSize: this.state.matches ? '1em' : '1.4em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              초기화
            </Button>
          </Grid>

          <Grid container item xs={12}>
            {mapToComponent(this.state.qnaList)}
          </Grid>
        </Grid>
      </div>
    );
  }
}

class QnaItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:991px)').matches /*md=992px*/,
    };
  }

  render() {
    const handleChange = (event) => {
      // console.log(event.target.value);
      const value = event.target.value;
      let param = { qnaId: this.props.qna.id, eventId: EVENT_ID, status: value };
      //console.log(param);
      this.props.onChangeQnaState(param);
    };

    return (
      // <div style={{ height: '100%', overflowY: 'auto' }}>
      <Grid container justifyContent="center">
        <Grid container item xs={11} className="qna_filter_row" style={{ padding: this.state.matches ? '4% 0' : '1% 0' }}>
          <Grid item xs={12} md={8} style={{ fontSize: this.state.matches ? '1em' : '1.5em' }}>
            <span style={{ color: '#377ac6' }}>Q. </span> {this.props.qna.q}
          </Grid>
          <Grid
            item
            xs={6}
            md={2}
            container
            justifyContent="center"
            alignItems="center"
            style={{ fontSize: this.state.matches ? '1em' : '1.2em', paddingTop: this.state.matches ? '10px' : 0 }}
          >
            {/* 작성자: {this.props.qna.user}
            <br />
            소속: {this.props.qna.position} */}
          </Grid>
          <Grid container item xs={6} md={2} justifyContent="center" alignItems="center">
            {this.props.qna.qna_status === 'S' ? (
              '선택중'
            ) : (
              <Select value={this.props.qna.qna_status} onChange={handleChange}>
                {/* <MenuItem value={'R'}>완료</MenuItem> */}
                <MenuItem value={'R'}>대기</MenuItem>
                <MenuItem value={'A'}>승인</MenuItem>
                {/* <MenuItem value={'P'}>사전질문</MenuItem> */}
              </Select>
            )}
          </Grid>
        </Grid>
      </Grid>
      // </div>
    );
  }
}

export default QnaFilter;

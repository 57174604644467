import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
import {
  BBS_API_URL,
  CHECK_STATUS,
  EVENT_CODE,
  EVENT_ID,
  EVENTS_API_URL,
  FORCE_LOGOUT,
  FORCE_REFRESH,
  getUserID,
  LOGIN_TB,
  MQ_URL,
  NOTICE_EMERGENCY,
  RESET_EVENT,
  SIGNOUT_URL,
  TOPIC_QNA_APPROVE,
  TOPIC_QNA_REG,
  TOPIC_QNA_SELECT,
} from '../constants';
import MainContext from '../MainContext';
import IoTClient from '../lib/iot-client';
import * as Cognito from '../lib/aws-cognito';
import { getAwsCredentials } from '../lib/aws-cognito';
import Emergency from './dialogs/Emergency';
import axios from 'axios';
import Stomp from 'stompjs';

// const moment = require('moment');
Amplify.configure(awsconfig);

const styles = (theme) => ({});

class EMAlert extends Component {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    this.state = {
      openEmergencyPopup: false,
      noticeContent: '',
      noticeTitle: '',
      isFirst: true,
      isIE: null,
      eventInfo: sessionStorage.getItem('Item') ? JSON.parse(sessionStorage.getItem('Item')) : null,
    };

    this.mqConnect = this.mqConnect.bind(this);
    this.connectIoT = this.connectIoT.bind(this);
  }

  connectIoT() {
    console.log('*********** try connecting (IoT) *************');
    getAwsCredentials(sessionStorage.getItem('_in_jwt'), 'user_pool').then((credentialSubset) => {
      sessionStorage.setItem('awsCredentials', JSON.stringify(credentialSubset));
      // initNewClient
      const options = {
        debug: false,
        accessKeyId: credentialSubset.accessKeyId,
        secretKey: credentialSubset.secretAccessKey,
        sessionToken: credentialSubset.sessionToken,
      };
      this.setState({ isFirst: false });
      if (this.state.isFirst) return;
      try {
        const client = new IoTClient(true, options);
        client.attachConnectHandler(() => {
          console.log('*********** CONNECT *************');
          const topic = `room/public/ping/${Cognito.getIdentityId()}`;
          client.publish(topic, JSON.stringify({ message: 'connected', targetId: getUserID(true) }));

          // 행사 전체 공지 토픽
          const allTopic = `room/public/${EVENT_ID}/+`;
          client.subscribe(allTopic);
          console.log('subscribe', allTopic);

          if (this.props.topic) {
            console.log('subscribe', this.props.topic);
            client.subscribe(this.props.topic);
          }

          client.attachCloseHandler(() => {
            console.log('*********** CLOSE 2 *************');
            if (sessionStorage.getItem('_in_user_')) {
              const topic = `room/public/ping/${Cognito.getIdentityId()}`;
              client.publish(topic, JSON.stringify({ message: 'disconnected', targetId: getUserID(true) }));
              this.connectIoT();
            }
          });

          client.attachMessageHandler((topic, message) => {
            console.log('topic', topic);
            console.log('message', JSON.parse(message.toString()));
            const msg = JSON.parse(message.toString());
            this.onMessage(msg.cate, msg, topic, client);
          });
        });
      } catch (e) {
        console.log('EMAlertError', e.message);
      }
    });
  }

  mqConnect() {
    const random = Math.random().toFixed(3).split('.')[1];
    const headers = {
      login: 'atozsoft',
      passcode: 'atozsoft0901',
      'client-id': sessionStorage.getItem('_in_sub') + random,
    };
    const CH = this.props.channel;
    let that = this;
    let client = null;
    let mqNo = 1;

    client = Stomp.client(MQ_URL);
    this.setState({ MQ: client });

    client.connect(
      headers,
      function () {
        console.log(mqNo + '<< connect', new Date());
        let subTopic = '/topic/' + EVENT_ID;
        if (CH) {
          subTopic = '/topic/' + EVENT_ID + '_' + CH;
        }
        console.log('subscribe :: ', subTopic);

        client.subscribe(
          subTopic,
          function (data) {
            console.log(data);
            console.log(JSON.parse(data.body));
            // let buff = JSON.parse(data.body);
            if (data.command === 'MESSAGE') {
              let cData = JSON.parse(data.body);
              that.onMessage(cData.status, cData, null, client);
            }
          },
          headers,
        );
      },
      function (err) {
        console.log('error', err);
        that.mqConnect();
      },
    );

    client.heartbeat.outgoing = 20000; // client will send heartbeats every 20000ms
    client.heartbeat.incoming = 0;
    client.reconnect_delay = 5000;
    client.debug = function (str) {};
  }

  onMessage(cate, msg, topic, client) {
    console.log('onMessage::', cate, msg, topic, client);
    switch (cate) {
      case NOTICE_EMERGENCY:
        try {
          const targetId = msg.targetId ? msg.targetId : msg.data;
          if (targetId) {
            axios
              .get(`${BBS_API_URL}/notice/info?evt=${EVENT_ID}&noticeId=${targetId}`, { headers: { Pragma: 'no-cache' } })
              .then((response) => {
                console.log(response);
                if (response.data.Item) {
                  this.setState({
                    openEmergencyPopup: true,
                    noticeTitle: response.data.Item.noticeTitle,
                    noticeContent: response.data.Item.noticeContents,
                  });
                }
              });
          }
        } catch (err) {}
        break;
      case FORCE_REFRESH:
        window.location.reload();
        break;
      case FORCE_LOGOUT:
        if (sessionStorage.getItem('_in_name')) {
          fetch(
            `${SIGNOUT_URL}?username=${encodeURIComponent(
              sessionStorage.getItem('_in_name'),
            )}&userid=${getUserID()}&evt=${EVENT_ID}&tbName=${LOGIN_TB}&agent=${encodeURIComponent(navigator.userAgent)}`,
            {
              method: 'GET',
            },
          )
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        }

        localStorage.clear();
        sessionStorage.clear();
        Auth.signOut().then(() => {
          this.props.history.push('/');
        });
        break;
      case TOPIC_QNA_REG:
        this.props.onMessage(cate, topic, msg);
        break;
      case TOPIC_QNA_APPROVE:
        this.props.onMessage(cate, topic, msg);
        break;
      case TOPIC_QNA_SELECT:
        this.props.onMessage(cate, topic, msg);
        break;
      case CHECK_STATUS:
        client.publish(
          `room/public/ping/${Cognito.getIdentityId()}`,
          JSON.stringify({ message: 'connected', targetId: getUserID(true), channel: this.props.channel }),
        );
        break;
      case RESET_EVENT:
        axios
          .get(`${EVENTS_API_URL}/event/info?eventId=${EVENT_ID}&code=${encodeURIComponent(EVENT_CODE)}`, {
            headers: { Pragma: 'no-cache' },
          })
          .then((body) => {
            let _result = body.data;
            _result.Item.code = null;
            sessionStorage.setItem('Item', JSON.stringify(_result.Item));
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      default:
        break;
    }
  }

  componentDidMount() {
    console.log('<<< EMAlert >>>');
    const agent = navigator.userAgent.toLowerCase();
    let _isIE = 'N';
    if ((navigator.appName === 'Netscape' && navigator.userAgent.search('Trident') !== -1) || agent.indexOf('msie') !== -1) {
      _isIE = 'Y';
      this.setState({ isIE: 'Y' });
    } else {
      this.setState({ isIE: 'N' });
    }

    if (this.state.eventInfo && this.state.eventInfo.useIot && _isIE === 'N') {
      console.log('EMAlert::connectIoT');
      this.connectIoT();
    } else {
      console.log('EMAlert::mqConnect');
      this.mqConnect();
    }
  }

  componentWillUnmount() {
    const client = new IoTClient();
    client.disconnect();
    if (this.state.MQ) this.state.MQ.disconnect();
  }

  closeEmergencyDialog = (event) => {
    this.setState({ openEmergencyPopup: false });
  };

  render() {
    return (
      <Emergency
        open={this.state.openEmergencyPopup}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            this.closeEmergencyDialog();
          }
        }}
        // onClose={this.closeEmergencyDialog}
        // disableBackdrop={true}
        noticeContent={this.state.noticeContent}
        noticeTitle={this.state.noticeTitle}
      />
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(EMAlert));

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_identity_pool_id": "ap-northeast-2:4a4e96cc-cca4-40e8-811d-f3956494e211",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_uMAcr7MIJ",
    "aws_user_pools_web_client_id": "5u3hdn1a9ujr8b09g780c23ps3",
    "oauth": {}
};


export default awsmobile;

import React from 'react';
import {
  EVENT_ID,
  MQ_URL,
  QNA_API_URL,
  TOPIC_QNA_APPROVE,
  TOPIC_QNA_REG,
  TOPIC_QNA_SELECT,
  TOPIC_SESSION_CLOSE,
  TOPIC_SESSION_START,
  CHANNEL,
} from '../constants';

import Stomp from 'stompjs';
// import queryString from 'querystring';
import './qnaMonitor.css';
import QnaDialog from './qnaDialog';
import axios from 'axios';
import QnaComponent from './qnaComponent';

class QnaMonitor extends QnaComponent {
  constructor(props) {
    super(props);
    //change default 채널명 불러오기
    let param = { ch: CHANNEL };
    // let param = queryString.parse(this.props.location.search.replace('?', ''));
    this.state = {
      channelId: param.ch,
      qnaDialog: false,
      qna: {},
    };
    this.getInfo = this.getInfo.bind(this);
  }

  closeQNADialog = (event) => {
    this.setState({ qnaDialog: false });
  };

  componentWillUnmount() {
    this.customComponentWillUnmount();
  }

  componentDidMount() {
    this.customComponentDidMount();
    const headers = {
      login: 'atozsoft',
      passcode: 'atozsoft0901',
      'client-id': 'qna_monitor_' + new Date().getTime(),
    };
    const CH = this.state.channelId;
    const client = Stomp.client(MQ_URL);
    client.connect(
      headers,
      () => {
        console.log('<< connect', new Date());

        client.subscribe(
          '/topic/' + EVENT_ID + '_' + CH,
          (data) => {
            console.log(data);
            if (data.command === 'MESSAGE') {
              let cData = JSON.parse(data.body);
              let cate = cData.status;
              switch (cate) {
                case TOPIC_QNA_REG:
                  break;
                case TOPIC_QNA_APPROVE:
                  break;
                case TOPIC_QNA_SELECT:
                  this.getInfo(cData.data);
                  break;
                case TOPIC_SESSION_START:
                  break;
                case TOPIC_SESSION_CLOSE:
                  break;
                default:
                  break;
              }
            }
          },
          headers,
        );
      },
      function () {
        console.log('error', new Date());
      },
    );
    setInterval(this.getInfo, 2000);
  }

  afterSelect(_id) {
    let param = { qnaId: _id, eventId: EVENT_ID };
    console.log('after Select');
    console.log(param);
    axios.put(`${QNA_API_URL}/qna/selectAfter`, param).then((response) => {
      console.log(response);
    });
  }

  getInfo(id) {
    if (id) {
      axios.get(`${QNA_API_URL}/qna/info?qnaId=${id}&eventId=${EVENT_ID}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
        this.afterSelect(response.data.Item.id);
        this.setState({ qnaDialog: true, qna: response.data.Item });
      });
    } else {
      //if (this.state.session && this.state.session.id) {
      axios.get(`${QNA_API_URL}/qna/listSelect?channel=${this.state.channelId}&eventId=${EVENT_ID}&pgmId=1`).then((response) => {
        response.data.Items.sort(function (a, b) {
          return a.updatedAt < b.updatedAt ? 1 : -1;
        });
        if (response.data.Items.length > 0) {
          this.afterSelect(response.data.Items[0].id);
          this.setState({ qnaDialog: true, qna: response.data.Items[0] });
        }
      });
      //}
    }
  }

  render() {
    return (
      <div className="qna_monitor_area" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div>{/*<img src="/images/kcr2020_login_bg.png" alt={'logo'} style={{ filter: 'grayscale(100%)', opacity: '0.2' }} />*/}</div>

        <QnaDialog open={this.state.qnaDialog} onClose={this.closeQNADialog} qna={this.state.qna} disableBackdrop={false} />
      </div>
    );
  }
}

export default QnaMonitor;

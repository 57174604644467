import axios from 'axios';
import { CHANNEL, EVENT_ID, EVENTS_API_URL, TEMP_MINUTE } from './constants';

const moment = require('moment-timezone');
moment.tz.setDefault('Asia/Seoul');

// 해당 세션 컨트롤러는 우선 룸이 2개에 한함
export const getPrograms = (today) => {
  return new Promise((resolve) => {
    const buff = moment(today).tz('Asia/Seoul').format('YYYYMMDD');
    axios
      .get(`${EVENTS_API_URL}/event/pgmByDate?eventId=${EVENT_ID}&date=${buff}`, {
        headers: { Pragma: 'no-cache' },
      })
      .then((response) => {
        console.log('SessionCtrl::getPrograms');
        // console.log(response);
        let _result = response.data.Items;
        let buffs = [];
        // eslint-disable-next-line
        CHANNEL.map((r) => {
          buffs.push([]);
        });

        _result.sort(function (a, b) {
          return a.startTime > b.startTime ? 1 : -1;
        });

        for (let i = 0; i < _result.length; i++) {
          if (_result[i].isBreak) {
            continue;
          }

          const channelIdx = CHANNEL.indexOf(_result[i].channel);
          buffs[channelIdx].push(_result[i]);
        }
        resolve({ result: true, detail: buffs });
      })
      .catch((err) => {
        resolve({ result: false });
      });
  });
};

const getSession = (programs, now) => {
  let findData = null;
  // eslint-disable-next-line
  programs.map((data, idx) => {
    const arrST = data.startTime.split(':');
    const arrET = data.endTime.split(':');
    const strST = `${data.pgDate.substring(0, 4)}-${data.pgDate.substring(4, 6)}-${data.pgDate.substring(6, 8)} ${arrST[0]}:${arrST[1]}:00`;
    const strET = `${data.pgDate.substring(0, 4)}-${data.pgDate.substring(4, 6)}-${data.pgDate.substring(6, 8)} ${arrET[0]}:${arrET[1]}:00`;

    let finalST = moment(strST).tz('Asia/Seoul');
    finalST.add(TEMP_MINUTE, 'minute');

    let finalET = moment(strET).tz('Asia/Seoul');
    finalST.add(TEMP_MINUTE, 'minute');

    if (now.valueOf() >= finalST.valueOf() && now.valueOf() < finalET.valueOf()) {
      findData = data;
      return data; // 겹치는 부분 없다는 가정아래 개발함. 앞이 우선인지 뒤가 우선인지에 따라 return 여부를 결정해야 할 것임.
    }
  });
  return findData;
};

/* 이미가져온 프로그램 내에서 세션을 한번에 가져오는 내용 */
export const getCurrentSessionOnly = (programs, now) => {
  return new Promise((resolve) => {
    if (programs.result) {
      let buffs = [];
      for (let i = 0; i < programs.detail.length; i++) {
        let buff = getSession(programs.detail[i], now);
        if (buff) {
          buffs.push(buff);
        }
      }

      if (buffs.length === 0) {
        // eslint-disable-next-line
        CHANNEL.map((r) => {
          buffs.push({ channel: r });
        });
      }
      resolve({ result: true, sessions: buffs });
    } else {
      console.log('ERROR');
      resolve({ result: false, sessions: [{ channel: 'ChannelA' }, { channel: 'ChannelB' }] });
    }
  });
};

/* 아래는 통신과 현재 세션을 한번에 가져오는 내용 */
export const getCurrentSession = (now) => {
  return new Promise((resolve) => {
    //let buff = moment(now).format('YYYYMMDD');
    //console.log(buff);
    getPrograms(now).then((response) => {
      if (response.result) {
        let buffs = [];
        for (let i = 0; i < response.detail.length; i++) {
          buffs.push(getSession(response.detail[i], now));
        }
        resolve({ result: true, sessions: buffs });
      } else {
        console.log('ERROR');
        resolve({ result: false, sessions: [null, null] });
      }
    });
  });
};

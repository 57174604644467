import React, { Component } from 'react';
import { AppBar, Grid, withStyles, Toolbar } from '@material-ui/core';

// import withStyles from '@material-ui/styles/withStyles';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
// import Toolbar from '@material-ui/core/Toolbar';
// import AppBar from '@material-ui/core/AppBar';
// import Button from '@material-ui/core/Button';
// import SectionHeader from './typo/SectionHeader';
import {
  ATTEND_API_URL,
  ATTEND_TB,
  ATTEND_URL,
  // CHANNEL,
  // CHANNEL_DP,
  // COMMON_IMG,
  ENTRY_TB,
  EVENT_ID,
  EVENT_IMG,
  EVENTS_API_URL,
  getUserID,
  PROGRAM_API_URL,
  TEMP_MINUTE,
} from '../constants';
import axios from 'axios';
import './LiveTopbar.css';
// import Popover from '@material-ui/core/Popover';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
import cloneDeep from 'lodash/cloneDeep';
import MainContext from '../MainContext';
// import ChannelDialog from '../components/dialogs/ChannelDialog';
import swal from '@sweetalert/with-react';

// import Schedule from '@material-ui/icons/Schedule';

const moment = require('moment-timezone');
moment.tz.setDefault('Asia/Seoul');
const styles = (theme) => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey['100']}`,
    backgroundColor: '#ffffff',
  },
  inline: {
    display: 'inline',
    paddingTop: '0.4em',
  },
  username: {
    display: 'inline',
    color: '#000000',
    paddingRight: '2em',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoInline: {
    display: 'inline',
  },
  flex: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  logo: {
    height: '80px',
    paddingTop: '10px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  productLogo: {
    display: 'inline-block',
    marginLeft: 32,
    paddingLeft: 24,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      paddingLeft: 0,
      borderLeft: '0px',
      fontSize: '0.5rem',
    },
  },
  pcContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    marginBottom: '20px',
  },
  popover: {
    padding: theme.spacing(2),
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(1),
  },

  chText: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em',
    },
  },
  titleText: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    color: '#555555',
    margin: '0.5% 0',
  },
  timeText: {
    fontSize: '1.1em',
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
});

class LiveTopbar extends Component {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    // console.log(this.props.ch);
    const callFunction = Number(JSON.parse(sessionStorage.getItem('Item')).channel.findIndex((i) => i.name === this.props.ch)) + 1;

    this.state = {
      username: '',
      now: moment(),
      isEntry: false,
      entryOpen: false,
      exitOpen: false,
      session: null,
      tickSecond: null,
      tickMinute: null,
      isFirstCheck: false,
      isAlert: false,
      isFirstEntry: true,
      isFirstExit: false,
      isLoadingProgram: false,
      callFunction: callFunction,
      openAttendPopup: false,
      matches: window.matchMedia('(max-width:991px)').matches /*md=992px*/,
      eventInfo: sessionStorage.getItem('Item') ? JSON.parse(sessionStorage.getItem('Item')) : null,
      regType: '',
    };

    this.init = this.init.bind(this);
    this.getPgmData = this.getPgmData.bind(this);
    this.handleEntry = this.handleEntry.bind(this);
    this.handleExit = this.handleExit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClose2 = this.handleClose2.bind(this);
    this.goExit = this.goExit.bind(this);
    this.getCurrentSession = this.getCurrentSession.bind(this);

    console.log('constructor::callFunction', callFunction);
  }

  getSession() {
    return this.state.session;
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.syncLocalTime.bind(this), false);
  }

  initGetServerTIme() {
    let timeServer = `${EVENTS_API_URL}/event/serverTime`;

    axios
      .get(timeServer, { headers: { Pragma: 'no-cache' } })
      .then((response) => {
        //console.log(response.data.SYSDATE);
        const today = moment(response.data.SYSDATE).tz('Asia/Seoul'); //.format('YYYY MM DD HH:mm:ss');
        this.setState({ today: today.format('YYYYMMDD') });
        this.initAfterGetTime(today.format('YYYYMMDD'));
      })
      .catch((err) => {
        const todayM = moment().tz('Asia/Seoul');
        this.initAfterGetTime(todayM.format('YYYYMMDD'));
      });
  }

  getPgmData(today, ch) {
    console.log(`getPgmData() :: ${today}_${ch} 에 해당하는 프로그램 정보 서버 요청 `);

    return new Promise((resolve) => {
      axios
        .get(`${EVENTS_API_URL}/event/pgmByDateChannel?eventId=${EVENT_ID}&date=${today}&channel=${ch}`, {
          headers: { Pragma: 'no-cache' },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log('program', response.data.Items);
            let newArr = [];
            // eslint-disable-next-line
            response.data.Items.map((item) => {
              if (!item.isBreak) {
                newArr.push(item);
              }
            });
            let buffs = cloneDeep(newArr);
            buffs.sort(function (a, b) {
              return a.startTime > b.startTime ? 1 : -1;
            });

            let preData = null;
            for (let i = buffs.length - 1; i >= 0; i--) {
              if (preData) {
                buffs[i]['NstartTime'] = preData.startTime;
              } else {
                // 마지막 세션인 경우 + 20분 이수 가능하도록 변경
                const arrEnd = buffs[i].endTime.split(':');
                if (Number(arrEnd[1]) + 10 >= 60) {
                  const lastMin = (Number(arrEnd[1]) + 10) % 60 === 0 ? '00' : (Number(arrEnd[1]) + 10) % 60;
                  buffs[i]['NstartTime'] = Number(arrEnd[0]) + 1 + ':' + lastMin;
                } else {
                  buffs[i]['NstartTime'] = arrEnd[0] + ':' + (Number(arrEnd[1]) + 10);
                }
                // buffs[i]['NstartTime'] = buffs[i].endTime;
              }
              preData = buffs[i];
            }
            this.setState({ program: buffs });
            this.getCurrentSession();
            this.setState({ isLoadingProgram: true });
            resolve(true);
          }
          resolve(false);
        });
    });
  }

  initAfterGetTime(today) {
    this.getPgmData(today, this.props.ch).then((resolve) => {
      this.init();
    });
  }

  componentDidMount() {
    this.setState({ isFirstEntry: true, isLoadingProgram: false });
    window.scrollTo(0, 0);
    window.addEventListener('focus', this.syncLocalTime.bind(this), false);

    if (!sessionStorage.getItem('Item')) {
      alert('잘못된 접근경로입니다. 다시 접속해주세요.');
      window.opener = 'Self';
      window.open('', '_parent', '');
      window.close(); // 일반적인 현재 창 닫기
      window.open('about:blank', '_self').self.close(); // IE에서 묻지 않고 창 닫기

      return;
    }

    // initGetServerTIme > initAfterGetTime > getPgmData > init > syncLocalTime >
    this.initGetServerTIme();
  }

  init() {
    let username = sessionStorage.getItem('_in_name');
    if (username === undefined || username === 'undefined') username = '';
    this.setState({ username: username });
    this.syncLocalTime();

    const regType = sessionStorage.getItem('_in_reg_type');
    this.setState({ regType: regType });
  }

  // 이름이 이래서 그렇지 lacalTime은 60초 마다 한번씩 서버타임과 한번씩 싱크를 맞춘다.
  syncLocalTime() {
    if (this.state.tickMinute !== null) {
      clearInterval(this.state.tickMinute);
      this.setState({ tickMinute: null });
    }
    this.syncServerTIme();
    let buff = setInterval(this.syncServerTIme.bind(this), 60000);
    this.setState({ tickMinute: buff });
  }

  startSecondInterval(date) {
    if (this.state.tickSecond !== null) {
      clearInterval(this.state.tickSecond);
      this.setState({ tickSecond: null });
    }

    const func = () => {
      // console.log('func() :: 1초마다 시간 업데이트');
      date.add(1, 'seconds');
      this.setState({ now: date });

      // TODO 세션 종료시간 체크하여 종료 + (TEMP_NMINUTE) 에 입장버튼으로 변경
      // console.log(this.state.sessionRealEnd);
      let finalEnd;
      if (this.state.sessionRealEnd) {
        finalEnd = this.state.sessionRealEnd;
        finalEnd.add(TEMP_MINUTE, 'minutes');

        if (this.state.sessionEnd && this.state.now.valueOf() >= finalEnd.valueOf()) {
          this.getCurrentSession();
          this.setState({ isEntry: false });
        }
      } else {
        //최초 sessionEnd 가 없으면 확인
        this.getCurrentSession();
      }
    };
    func();
    let buff = setInterval(func, 1000);

    this.setState({ tickSecond: buff });
  }
  syncServerTIme() {
    console.log('syncServerTIme() :: 1분마다 서버타임 체크');
    let timeServer = `${EVENTS_API_URL}/event/serverTime`;
    axios
      .get(timeServer, { headers: { Pragma: 'no-cache' } })
      .then((response) => {
        const setTimeZone = moment(response.data.SYSDATE).tz('Asia/Seoul'); //.format('YYYY MM DD HH:mm:ss');
        this.setState({ now: setTimeZone });
        this.getCurrentSession();

        // check already entry
        setTimeout(() => {
          this.checkAlready();
        }, 500);

        this.startSecondInterval(setTimeZone);
      })
      .catch((err) => {
        this.getCurrentSession();
        setTimeout(() => {
          this.checkAlready();
        }, 500);

        this.startSecondInterval(this.state.now);
      });
  }

  checkAlready() {
    console.log('checkAlready() ::', this.state.callFunction);
    if (this.state.session) {
      this.setState({ isFirstCheck: true });
      const sessionStartM = this.state.sessionStart;
      const sd = this.state.sessionStart.format('YYYYMMDD');

      // console.log(
      //   'checkStatus',
      //   `${ATTEND_API_URL}/check/status${this.state.callFunction}?userid=${getUserID()}&evt=${EVENT_ID}&program=${
      //     this.state.session.id
      //   }&etDate=${sd}&session=${this.state.session.session}&tbName=${ATTEND_TB}`,
      // );

      axios
        .get(
          `${ATTEND_API_URL}/check/status${this.state.callFunction}?userid=${getUserID()}&evt=${EVENT_ID}&program=${
            this.state.session.id
          }&etDate=${sd}&session=${this.state.session.session}&tbName=${ATTEND_TB}`,
          { headers: { Pragma: 'no-cache' } },
        )
        .then((response) => {
          let _result = response.data;
          // console.log('checkAlready', _result);
          if (_result.check) {
            this.setState({ isEntry: true });
            if (this.state.isFirstEntry) {
              console.log('입장기록이 존재합니다.');
              this.setState({ isFirstEntry: false });
            }
          } else {
            this.setState({ isEntry: false });
            console.log('입장기록이 없습니다.', this.state.isFirstEntry);
            // 진입시 바로 입장 처리시 주석 풀것 (입장버튼 사용안하는 경우)
            // if (this.state.isFirstEntry) {
            const sessionEnd = new Date(this.state.sessionEnd);
            if (this.state.now.valueOf() < sessionEnd.valueOf()) {
              this.setState({ isFirstEntry: false });
              this.handleEntry();
            } else {
              // alert('세션이 종료되어 입장가능한시간이 아닙니다.');
              this.setState({ isFirstExit: true });
              // window.opener = 'Self';
              // window.open('', '_parent', '');
              // window.close(); // 일반적인 현재 창 닫기
              // window.open('about:blank', '_self').self.close(); // IE에서 묻지 않고 창 닫기
            }
            // }
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isEntry: false });
        });

      // for log (entry table)
      fetch(
        `${ATTEND_URL}/ch${this.state.callFunction}?username=${encodeURIComponent(
          sessionStorage.getItem('_in_name'),
        )}&userid=${getUserID()}&evt=${EVENT_ID}&channel=${this.props.ch}&type=C&program=${this.state.session.id}&etDate=${sd}&session=${
          this.state.session.session
        }&sst=${sessionStartM.valueOf()}&entryTbName=${ENTRY_TB}&tbName=${ATTEND_TB}`,
        {
          method: 'POST',
        },
      )
        .then((res) => res.text())
        .then((body) => {})
        .catch((err) => {
          console.log(err);
        });
    } else {
      //this.setState({ isEntry: false });
    }
  }

  getCurrentSession() {
    // console.log('getCurrentSession() :: 현재 세션 체크!!!');
    if (this.state.program) {
      this.setState({ session: null });
      // eslint-disable-next-line
      let bFound = false;
      let selectId = -1;

      this.state.program.map((data, idx) => {
        const arrST = data.startTime.split(':');
        const arrET = data.endTime.split(':');
        const strST = `${data.pgDate.substring(0, 4)}-${data.pgDate.substring(4, 6)}-${data.pgDate.substring(6, 8)} ${arrST[0]}:${
          arrST[1]
        }:00`;
        const strET = `${data.pgDate.substring(0, 4)}-${data.pgDate.substring(4, 6)}-${data.pgDate.substring(6, 8)} ${arrET[0]}:${
          arrET[1]
        }:00`;
        const arrNST = data.NstartTime.split(':');
        const strNextStartTime = `${data.pgDate.substring(0, 4)}-${data.pgDate.substring(4, 6)}-${data.pgDate.substring(6, 8)} ${
          arrNST[0]
        }:${arrNST[1]}:00`;

        let finalST = moment(strST).tz('Asia/Seoul');
        finalST.add(TEMP_MINUTE, 'minute');

        let finalET = moment(strET).tz('Asia/Seoul');
        finalET.add(TEMP_MINUTE, 'minute');

        let finalRealET = moment(strNextStartTime).tz('Asia/Seoul');
        finalRealET.add(TEMP_MINUTE, 'minute');

        if (this.state.now.valueOf() >= finalST.valueOf() && this.state.now.valueOf() < finalRealET.valueOf()) {
          selectId = data.id;
          console.log('<<< SESSION >>>', data);
          // [Start] 접속 카운트 기록
          // 세션 진입시 1번 누적 기록
          let isFirst = false;
          if (!sessionStorage.getItem('firstEnterSession_' + data.id)) {
            isFirst = true;
            sessionStorage.setItem('firstEnterSession_' + data.id, true);
          }

          let param = {
            eventId: EVENT_ID,
            pgmId: data.id,
            userid: getUserID(true),
            pgmInfo: data,
            isFirst: isFirst,
            username: sessionStorage.getItem('_in_name'),
            position: sessionStorage.getItem('_in_position'),
          };
          axios.post(`${PROGRAM_API_URL}/program/view`, param).then((response) => {});
          // [End]

          bFound = true;
          sessionStorage.setItem('_in_session', data.id);
          sessionStorage.setItem('_in_sessionInfo', data);
          // sessionStorage.setItem('_in_session_quiz', data.useQuiz);

          //if (!this.state.isFirstCheck) {
          this.checkAlready();
          //}
          this.setState({
            session: data,
            sessionStart: moment(strST),
            sessionEnd: moment(strET),
            isRequired: data.isRequired,
            sessionRealEnd: finalRealET,
          });
        } else {
          //TODO 중간 쉬는 시간 표시 방법 확인
          if (idx === this.state.program.length - 1 && !this.state.isFirstExit && !this.state.session && this.state.isLoadingProgram) {
            console.log('>>>> session 종료 ');
            /*
            alert("세션이 종료되어 입장가능한시간이 아닙니다.");

            this.setState({ isFirstExit: true });
            window.opener = "Self";
            window.open("", "_parent", "");
            window.close(); // 일반적인 현재 창 닫기
            window.open("about:blank", "_self").self.close(); // IE에서 묻지 않고 창 닫기
            */
          }
        }

        return '';
      });
      if (selectId !== -1) {
        this.props.setSession(selectId);
        console.log('check session ID');
        console.log(selectId);
      } else {
        this.props.setSession('');
      }
    } else {
      this.props.setSession('');
    }
  }
  closeAttendDialog = (event) => {
    this.setState({ openAttendPopup: false });
  };

  handleEntry(e) {
    this.setState({ openAttendPopup: false });
    console.log('handleEntry() :: 세션입장 ');
    const target = document.getElementById('username');
    if (!this.state.sessionEnd) return;

    if (this.state.now.valueOf() > this.state.sessionEnd.valueOf()) {
      alert('세션시작 후에 출석하기 버튼 클릭이 가능합니다.');
      return;
    }

    if (this.state.session) {
      const sd = this.state.sessionStart.format('YYYYMMDD');
      // for Session In
      let param = {
        username: sessionStorage.getItem('_in_name'),
        userid: getUserID(true),
        evt: EVENT_ID,
        channel: this.props.ch,
        date: sd,
        session: this.state.session.session,
        sst: this.state.sessionStart.valueOf(),
        ssdt: this.state.sessionEnd.valueOf(),
        entryTbName: ENTRY_TB,
        tbName: ATTEND_TB,
        pgmId: this.state.session.id,
      };
      axios.post(`${ATTEND_URL}/ch${this.state.callFunction}?type=E`, param).then((res) => {
        let _result = res.data;
        localStorage.setItem('_in_attendId_' + this.state.session.id, _result.attendId);
        localStorage.setItem('_in_attend_user', sessionStorage.getItem('_in_name'));
        this.setState({ isEntry: true, anchorEl: target, entryOpen: true });
      });
    } else {
      // swal('세션시간이 아닙니다.');
      swal('세션시작 후에 출석하기 버튼 클릭이 가능합니다.');
    }
  }

  handleExit() {
    //TODO check sessionEndTime
    if (!this.state.session) {
      return;
    }
    if (!this.state.session.id) {
      this.checkAlready();
    }

    if (this.state.sessionEnd && this.state.now.valueOf() < this.state.sessionEnd.valueOf()) {
      const target = document.getElementById('username');
      this.setState({ anchorEl: target, entryOpen: false, exitOpen: true });
    } else {
      // new session start
      this.setState({ entryOpen: true });
    }
  }

  goExit(e, _unload) {
    if (!this.state.sessionStart) {
      console.log('session is null');
      return;
    }

    try {
      if (this.state.sessionStart) {
        const sd = this.state.sessionStart.format('YYYYMMDD');
        let param = {
          username: sessionStorage.getItem('_in_name'),
          userid: getUserID(true),
          evt: EVENT_ID,
          channel: this.props.ch,
          date: sd,
          session: this.state.session.session,
          sst: this.state.sessionStart.valueOf(),
          ssdt: this.state.sessionEnd.valueOf(),
          entryTbName: ENTRY_TB,
          tbName: ATTEND_TB,
          pgmId: this.state.session.id,
        };
        axios
          .post(`${ATTEND_URL}/ch${this.state.callFunction}?type=X`, param)
          .then((res) => {
            const target = document.getElementById('username');
            this.setState({ entryOpen: false, anchorEl: target, exitOpen: true });

            // 퇴장시 창 닫기
            if (!_unload) {
              window.close();
              // window.open('about:blank', '_self').self.close(); // IE에서 묻지 않고 창 닫기
            }
          })
          .catch((err) => {
            // alert(`퇴장처리에 실패하였습니다.페이지 새로고침후 다시 퇴장하기를 눌러주세요.`);
            console.log(err);
          });
      }
    } catch (e) {
      console.log('ERror');
      //
      // window.opener = 'Self';
      // window.open('', '_parent', '');
      // window.close(); // 일반적인 현재 창 닫기
      // window.open('about:blank', '_self').self.close(); // IE에서 묻지 않고 창 닫기
    }
  }

  handleClose() {
    this.setState({ entryOpen: false, exitOpen: false });
  }

  handleClose2() {
    this.setState({ entryOpen: false, exitOpen: false });
  }

  render() {
    const { classes } = this.props;

    let displayTime = (date) => {
      return date.format('YYYY-MM-DD HH:mm:ss');
    };

    return (
      <AppBar position="static" color="default" className={classes.appBar}>
        <Toolbar
          style={{
            padding: 0,
            // background: `linear-gradient(45deg,rgba(132,204,231,0.20) 25%, rgba(52,69,124,0.20) 50%, rgba(117,75,155,0.20) 75%)`,
          }}
        >
          <Grid container item xs={12}>
            {/* 로고 Grid */}
            <Grid container alignContent="center" item xs={5} md={2} id="logo_grid">
              {!this.state.matches && (
                <div
                  style={{
                    height: 60,
                    width: 'fit-content',
                    marginLeft: this.state.matches ? '8px' : '20px ' /*비디오 플레이어 맞춤*/,
                  }}
                >
                  <img src={EVENT_IMG + '/live_logo.png'} alt="LOGO" style={{ maxHeight: '100%', padding: '5px 0px' }} />
                </div>
              )}
            </Grid>
            {this.state.matches ? (
              ''
            ) : (
              <Grid id="text_grid" item xs={7} container justifyContent="flex-start" alignItems="center">
                <Grid container style={{ marginLeft: this.state.matches ? '2%' : '2%' }}>
                  {/* <Grid container item xs={12}>
                    <Chip label={CHANNEL_DP} color="primary" style={{ fontWeight: 'bold' }} />
                  </Grid> */}
                  {/* 
                  <React.Fragment>
                    <Grid container item xs={12} className={classes.titleText}>
                      {this.state.session ? <Grid> {this.state.session.pgTitle}</Grid> : <Grid></Grid>}
                    </Grid>
                    <Grid container item xs={12} className={classes.timeText}>
                      <Grid container alignItems="center">
                        {this.state.session ? (
                          <Grid container>
                            <Schedule style={{ marginRight: '0.25%' }} />
                            {this.state.session.startTime}~{this.state.session.endTime}
                          </Grid>
                        ) : (
                          <Grid></Grid>
                        )}
                      </Grid>
                    </Grid>
                  </React.Fragment> */}
                </Grid>
              </Grid>
            )}
            <Grid
              id="name_grid"
              item
              xs={6}
              md={3}
              container
              justifyContent="flex-end"
              alignItems="center"
              style={{ fontSize: this.state.matches ? '1.1em' : '1.5em' }}
            >
              {this.state.matches ? (
                <Grid container style={{ paddingRight: '5%' }}>
                  <Grid container justifyContent="flex-end" style={{ paddingRight: '3%', paddingTop: '2%' }}>
                    {this.state.regType && this.state.regType.includes('MEDIGATE_') ? (
                      <b>GUEST_LOGIN</b>
                    ) : (
                      <div>
                        <b>{this.state.username}</b> 님
                      </div>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Grid container alignItems="center" style={{ paddingRight: '5%', fontSize: '0.85em' }}>
                  <Grid container justifyContent="flex-end">
                    {this.state.regType && this.state.regType.includes('MEDIGATE_') ? (
                      <b>GUEST_LOGIN</b>
                    ) : (
                      <div>
                        <b>{this.state.username}</b>&nbsp;&nbsp; 님
                      </div>
                    )}
                  </Grid>
                  <Grid container justifyContent="flex-end" style={{ fontSize: this.state.matches ? '0.85em' : '1em' }}>
                    {displayTime(this.state.now)}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Toolbar>
        {/* <Popover
          id={'popover-entry'}
          open={this.state.entryOpen}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose2}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleClose2}>
            <CloseIcon />
          </IconButton>
          <Typography className={classes.popover}>
            해당 세션 <span style={{ color: '#377ac6' }}>퇴장하기 버튼</span>을 <br />
            클릭 하셔야 출석 인정됩니다.
          </Typography>
        </Popover>
        <Popover
          id={'popover-exit'}
          open={this.state.exitOpen}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography className={classes.popover}>퇴장시간이 확인되었습니다.</Typography>
          <div style={{ textAlign: 'center', borderBottom: '1px solid #d8d8d8', margin: '16px', marginTop: '-10px' }}>&nbsp;</div>
          <div style={{ textAlign: 'center', marginBottom: '5px' }}>
            <Button color={'secondary'} disableElevation onClick={this.handleClose}>
              확인
            </Button>
          </div>
        </Popover> */}
        {/* <ChannelDialog
          open={this.state.openAttendPopup}
          onClose={this.closeAttendDialog}
          // disableBackdrop={true}
          handleEntry={this.handleEntry}
        /> */}
      </AppBar>
    );
  }
}
export default withStyles(styles, { withTheme: true })(LiveTopbar);

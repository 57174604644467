import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CHANNEL, EVENTS_API_URL, getArrDate } from './constants';
import MainContext from './MainContext';

import { getCurrentSessionOnly, getPrograms } from './SessionCtrl.js';
import axios from 'axios';

const moment = require('moment-timezone');
moment.tz.setDefault('Asia/Seoul');

export class MainProvider extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    now: PropTypes.any,
    timerInterval: PropTypes.number,
    isTimerRunning: PropTypes.bool,
    value: PropTypes.any,
    arrDate: PropTypes.array,
    dayIndex: PropTypes.number,
    arrCurrentSesseion: PropTypes.array,
    win: PropTypes.any,
  };

  static defaultProps = {
    children: null,
    now: new Date(),
    timerInterval: 1000,
    isTimerRunning: false,
    value: null,
    arrDate: [],
    dayIndex: 0,
    arrCurrentSesseion: [],
  };

  constructor(props, context) {
    super(props, context);
    const { now, timerInterval, isTimerRunning, value, arrDate, dayIndex, arrCurrentSesseion } = props;
    this.state = {
      now: now,
      timerInterval: timerInterval,
      isTimerRunning: isTimerRunning,
      arrDate: arrDate,
      dayIndex: dayIndex,
      tickMinute: null,
      tickSecond: null,
      details: null,
      arrCurrentSesseion: arrCurrentSesseion,

      ...value,
    };

    console.log('MainProvicer constructor');

    setTimeout(() => {
      this.syncLocalTime();
    }, 100);
  }

  getSyncPgm = () => {
    this.syncServerTIme().then((response) => {
      setTimeout(() => {
        const arr = getArrDate();
        if (arr) {
          // eslint-disable-next-line
          arr.map((_d, idx) => {
            if (moment(this.state.now).isSame(_d, 'day')) {
              this.setState({ dayIndex: idx });
            }
          });
        }
        this.setState({ arrDate: arr });
        getPrograms(this.state.now).then((response) => {
          this.setState({ details: response });
          this.startSecondInterval(this.state.timerInterval);
        });
      }, 100);
    });
  };

  syncLocalTime() {
    if (this.state.tickMinute !== null) {
      clearInterval(this.state.tickMinute);
      this.setState({ tickMinute: null });
    }

    // console.log('syncLocalTime');
    this.getSyncPgm();
    let buff = setInterval(this.getSyncPgm.bind(this), 60000);
    this.setState({ tickMinute: buff });
  }

  // 해당 함수는 최초 서버의 시간을 가져오는 함수로써, 이때 프로그램의 정보를 가져온다.
  syncServerTIme() {
    return new Promise((resolve) => {
      // console.log('syncServerTime');
      axios
        .get(`${EVENTS_API_URL}/event/serverTime`, { headers: { Pragma: 'no-cache' } })
        .then((response) => {
          // console.log(response.data.SYSDATE);
          const setTimeZone = moment(response.data.SYSDATE).tz('Asia/Seoul'); //.format('YYYY MM DD HH:mm:ss');
          this.setState({ now: setTimeZone });

          resolve(setTimeZone);
        })
        .catch((err) => {
          console.log('ERROR >>>> ');
          const now = new Date();

          this.setState({ now: now });
          resolve(now);
        });
    });
  }

  componentDidUpdate(prevProps) {
    // const { isTimerRunning: prevIsTimerRunning } = prevProps;
    // const { isTimerRunning: currIsTimerRunning, timerInterval } = this.props;
  }

  componentWillUnmount() {
    if (this.timer) {
      this.stopTimer();
    }
  }

  componentDidMount() {}

  startSecondInterval(timerInterval) {
    if (this.state.tickSecond !== null) {
      clearInterval(this.state.tickSecond);
      this.setState({ tickSecond: null });
    }

    const func = () => {
      const date = this.state.now;
      //date.add(1, 'seconds');
      this.setState({ now: date });
      if (this.state.details) {
        getCurrentSessionOnly(this.state.details, this.state.now).then((response) => {
          // console.log('getCurrentSession');
          // console.log(response);
          if (response.result) {
            this.setState({ arrCurrentSesseion: response.sessions });
          } else {
            console.log('NOT FOUND SESSION');
            let buffs = [];
            // eslint-disable-next-line
            CHANNEL.map((r) => {
              buffs.push({ channel: r.name });
            });

            console.log(buffs);
            this.setState({ arrCurrentSesseion: buffs });
          }
          setTimeout(() => {
            //console.log(this.state);
          }, 100);
        });
      }
    };
    func();
    let buff = setInterval(func, 1000);
    this.setState({ tickSecond: buff });
  }

  render() {
    const { children } = this.props;
    const { isTimerRunning, timerInterval, ...state } = this.state;

    return (
      <MainContext.Provider value={state} isTimerRunning={isTimerRunning} timerInterval={timerInterval}>
        {children}
      </MainContext.Provider>
    );
  }
}

// export const TimeConsumer = Consumer;
export default MainProvider;

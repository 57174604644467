import React, { Component } from 'react';
import { Button, Container, Dialog, DialogContent, DialogTitle, Grid, Typography, withStyles } from '@material-ui/core';
import './Emergency.css';
import { COMMON_IMG } from '../../constants';

const styles = (theme) => ({
  dialogRoot: {
    '& .MuiDialog-paper': {
      [theme.breakpoints.up('sm')]: {
        right: '0px',
        top: '120px',
        position: 'absolute',
      },
    },
  },
  title: {
    paddingBottom: '0px',
  },
  titleText02: {
    fontSize: '15.5px',
    textAlign: 'center',
    margin: '3px 0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13.5px',
    },
  },
  agreeBtn: {
    // maxWidth: '686px',
    // maxHeigt: '541px',
    // backgroundColor: '#377ac6',
    marginBottom: '10px',
    minWidth: '50%',
    // marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      padding: '16px',
      minWidth: '100%',
    },
  },
});

class Emergency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: true,
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes, open, onClose } = this.props;
    return (
      <Dialog
        className={classes.dialogRoot}
        onClose={onClose}
        aria-labelledby="channel-enter-dialog"
        open={open}
        // disableBackdropClick={disableBackdrop}
        // disableEscapeKeyDown={disableBackdrop}
      >
        <DialogTitle id="channel-enter-dialog" onClose={onClose} className={classes.title}>
          <div>
            <p className="titleText01">
              <img src={`${COMMON_IMG}/ic-caution.png`} style={{ verticalAlign: 'bottom' }} alt={''} />
              {this.props.noticeTitle}
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Container maxWidth="md" style={{ paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#fafafa' }}>
            <div dangerouslySetInnerHTML={{ __html: this.props.noticeContent }} />
          </Container>
        </DialogContent>

        <Grid container justifyContent="center" alignItems="center">
          {/*<Grid container justifyContent="center">*/}
          {/*  <FormControlLabel*/}
          {/*    value={this.state.value}*/}
          {/*    control={<Checkbox color="secondary" />}*/}
          {/*    label="모든 내용을 확인했고 동의합니다."*/}
          {/*    onChange={this.handleChange}*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid container justifyContent="center">
            <Button
              onClick={onClose}
              color="primary"
              variant="contained"
              size="large"
              className={classes.agreeBtn}
              disabled={!this.state.value}
            >
              <Typography variant="button">닫기 (Close)</Typography>
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}
export default withStyles(styles)(Emergency);

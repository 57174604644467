import React from 'react';
import { CssBaseline, Grid, withStyles } from '@material-ui/core';
import { indigo } from '@material-ui/core/colors';

import VideoPlayer from '../components/VideoPlayer';
import { EVENT_ID, EVENT_IMG, SIGNOUT_URL } from '../constants';
import queryString from 'querystring';
import QNA from '../session/sessionComp/QNA';

import { Auth } from 'aws-amplify';
import axios from 'axios';

import LiveTopbar from '../components/LiveTopbar';
import './LiveSimple.css';
import Emergency from '../components/dialogs/Emergency';
import EMAlert from '../components/EMAlert';

import SocketManager from '../components/SocketManager';
import { Tab, Tabs } from '@mui/material';
import { MdOutlineLiveTv } from 'react-icons/md';

// import { Tab, Tabs } from '@mui/material';
// import { MdHearing } from 'react-icons/md';

const styles = (theme) => ({
  container: {
    display: 'flex',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  vodGrid: {
    height: '100%',
    paddingLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
    },
  },
  main: {
    height: '100%',
    overflow: 'scroll',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  footer: {
    backgroundColor: indigo[700],
    position: 'relative',
    bottom: '67px',
    width: '100%',
  },
  rightWrap: {
    borderLeft: '1px solid #CCCCCD',
    [theme.breakpoints.down('sm')]: {
      borderLeft: 'none',
      height: '100%',
      overflowY: 'auto',
    },
  },
  tabContainer: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  tabPanel: {
    height: 'calc(100vh - 180px)',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  bannerContainer: {
    bottom: 0,
    width: '100%',
    backgroundColor: '#8DB3FF',
    height: '80px',
    padding: '20px',
  },
  selectBtn: {
    backgroundColor: '#0d4d96',
    color: 'white',
  },
});

class LiveSimple extends React.Component {
  constructor(props) {
    super(props);
    let param = queryString.parse(this.props.location.search.replace('?', ''));

    this.refChat = React.createRef();
    this.refLiveTopBar = React.createRef();

    this.state = {
      value: 'QNA',
      msg: '',
      channelId: param.ch,
      username: sessionStorage.getItem('_in_name'),
      openConfirmPopup: false,
      openEmergencyPopup: false,
      session: null,
      isIE: null,
      eventInfo: sessionStorage.getItem('Item') ? JSON.parse(sessionStorage.getItem('Item')) : null,
      tabsValue: 'kor', // 스트리밍 선택 탭
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.init = this.init.bind(this);
    this.liveLogout = this.liveLogout.bind(this);
    this.doClose = this.doClose.bind(this);
    this.doClosePopup = this.doClosePopup.bind(this);

    this.handleTabs = this.handleTabs.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {}

  init() {
    const eventItem = sessionStorage.getItem('Item');
    let jsonItem = null;
    if (!eventItem) this.props.history.push('/main');
    else {
      jsonItem = JSON.parse(eventItem);
      this.setState({ eventInfo: jsonItem });
      // console.log(this.state.channelId);
      const pos = jsonItem.channel
        .map(function (e) {
          return e.name;
        })
        .indexOf(this.state.channelId);
      this.setState({ channel: jsonItem.channel }, () => {
        console.log('CHANNEL: ', this.state.channel);
      });
      this.setState(
        {
          callFunction: pos,
          /*+ 1*/
        },
        () => {
          console.log('callFunc', this.state.callFunction);
        },
      );
    }
  }

  liveLogout() {
    this.setState({ openConfirmPopup: true, isShowConfirm: true });
  }

  doClose(event, _val) {
    if (_val === 'Y') {
      if (sessionStorage.getItem('_in_name')) {
        axios
          .get(
            `${SIGNOUT_URL}?username=${encodeURIComponent(sessionStorage.getItem('_in_name'))}&userid=${encodeURIComponent(
              sessionStorage.getItem('_in_sub'),
            )}&evt=${EVENT_ID}`,
          )
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      }

      localStorage.clear();
      sessionStorage.clear();

      Auth.signOut().then(() => {
        window.opener = 'Self';
        window.open('', '_parent', '');
        window.close(); // 일반적인 현재 창 닫기
        window.open('about:blank', '_self').self.close(); // IE에서 묻지 않고 창 닫기
      });
    } else {
      this.setState({ openConfirmPopup: false });
    }
  }

  handleUnload() {
    return 'exit';
  }

  handleChange(event, newVal) {
    this.setState({ value: newVal });
  }

  handleHover(e) {
    const strId = e.target.id + 'Hovered';
    this.setState({
      [strId]: true,
    });
  }
  handleTabs(event, newVal) {
    this.setState({ tabsValue: newVal });
  }
  // closeEmergencyDialog = (event) => {
  //   this.setState({ openEmergencyPopup: false });
  // };

  doClosePopup = (event) => {
    this.setState({ openPopup: false });
  };

  onSetSession = (session) => {
    if (this.state.session !== session) {
      if (this.refChat.current) this.refChat.current.clearChatList();
    }

    this.setState({ session: session });
  };

  render() {
    const { classes } = this.props;
    const { tabsValue } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <LiveTopbar ref={this.refLiveTopBar} ch={this.state.channelId} callFunc={this.state.callFunction} setSession={this.onSetSession} />
        <main className={classes.main}>
          <div className={classes.container}>
            {/* End hero unit */}
            <Grid container spacing={0}>
              <Grid item xs={12} md={9}>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Tabs
                      value={tabsValue}
                      variant="fullWidth"
                      onChange={this.handleTabs}
                      sx={{
                        '& .MuiTabs-indicator': { backgroundColor: 'transparent !important' },
                        '& .MuiTab-root': { backgroundColor: 'rgba(230,92,22,0.1)', color: 'rgba(230,92,22)' },
                        '& .Mui-selected': { backgroundColor: 'rgba(230,92,22,0.9)', color: '#fff !important' },
                      }}
                    >
                      <Tab
                        icon={tabsValue === 'kor' && <MdOutlineLiveTv size={24} />}
                        iconPosition="start"
                        label="KOR"
                        value="kor"
                        style={{
                          fontFamily: 'NanumSquareNeo-Hv',
                          fontSize: this.state.matches ? '1.05em' : '1.45em',
                          textTransform: 'none',
                        }}
                      />
                      <Tab
                        icon={tabsValue === 'eng' && <MdOutlineLiveTv size={24} />}
                        iconPosition="start"
                        label="ENG"
                        value="eng"
                        style={{
                          fontFamily: 'NanumSquareNeo-Hv',
                          fontSize: this.state.matches ? '1.05em' : '1.45em',
                          textTransform: 'none',
                        }}
                      />
                    </Tabs>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={0}
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'flex-start'}
                  className={classes.vodGrid}
                >
                  {tabsValue === 'kor' && (
                    <VideoPlayer
                      // src={this.state.channel[this.state.callFunction].url}
                      // src={this.state.channel[0].url}
                      src={`https://innon-live.xst.kinxcdn.com/innon_live/_definst_/innon1/playlist.m3u8`}
                      // src={`https://innon.s3.ap-northeast-2.amazonaws.com/test06.mp4`}
                      type={this.state.eventInfo.playerType ? this.state.eventInfo.playerType : 'jw'}
                      pos={'live'}
                    />
                  )}

                  {tabsValue === 'eng' && (
                    <VideoPlayer
                      // src={this.state.channel[this.state.callFunction].url}
                      // src={this.state.channel[0].url}
                      src={`https://innon-live.xst.kinxcdn.com/innon_live/_definst_/innon2/playlist.m3u8`}
                      type={this.state.eventInfo.playerType ? this.state.eventInfo.playerType : 'jw'}
                      pos={'live'}
                    />
                  )}
                </Grid>

                {/* 기존 VideoPlayer >>>>>>*/}
                {/* <Grid
                  container
                  spacing={0}
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'flex-start'}
                  className={classes.vodGrid}
                >
                  {this.state.channel && this.state.channel.length > 0 ? (
                    <VideoPlayer
                      src={this.state.channel[this.state.callFunction].url}
                      // src={this.state.channel[0].url}
                      type={this.state.eventInfo.playerType ? this.state.eventInfo.playerType : 'jw'}
                      pos={'live'}
                    />
                  ) : (
                    ''
                  )}
                </Grid> */}
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                style={{
                  height: '100%',
                  overflowY: 'auto',
                  padding: this.state.mathces ? 16 : 24,
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: this.state.matches ? `` : `url(${EVENT_IMG}/bg_qna.png)`,
                  backgroundSize: this.state.matches ? '' : '100% auto',
                  backgroundPosition: this.state.matches ? '' : 'bottom',
                }}
              >
                <QNA channel={this.state.channelId} />
              </Grid>
            </Grid>
          </div>
        </main>
        {/*</div>*/}
        <Emergency
          open={this.state.openEmergencyPopup}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.setState({ openEmergencyPopup: false });
            }
          }}
          noticeContent={this.state.noticeContent}
          noticeTitle={this.state.noticeTitle}
        />
        <EMAlert topic={`room/public/${EVENT_ID}/${this.state.channelId}/+`} channel={this.state.channelId} />
        {this.state.eventInfo && this.state.eventInfo.useLoginCheck && <SocketManager winClose={true} />}
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LiveSimple);

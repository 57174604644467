import React, { Component } from 'react';
import axios from 'axios';
import Stomp from 'stompjs';
import {
  API_URL,
  EVENT_ID,
  MQ_URL,
  TOPIC_QNA_APPROVE,
  TOPIC_QNA_REG,
  TOPIC_QNA_SELECT,
  TOPIC_SESSION_CLOSE,
  TOPIC_SESSION_START,
  TOPIC_VOTING_START,
  VOTING_NAME,
} from '../constants';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import './votingAdmin.css';
import Grid from '@material-ui/core/Grid';
import swal from 'sweetalert';
// import { Button } from '@material-ui/core';

class VotingAdmin extends Component {
  constructor(props) {
    super(props);

    //let param = queryString.parse(this.props.location.search.replace('?', ''));
    //console.log(param);

    this.state = {
      channel: VOTING_NAME,
      // channel: 'REPOM_VOTE',
      //eventId: param.evt,
      session: 'evt158_pg001', //보팅수정
      votingList: [],
    };

    this.getVotingList = this.getVotingList.bind(this);

    setTimeout(() => {
      console.log(this.state);
    }, 1000);
  }

  getVotingList() {
    console.log('getVotingList');
    //console.log(`${API_URL}/qna/list?channel=${this.state.channelId}&eventId=${EVENT_ID}&pgmId=${pgmId}`);
    axios
      .get(`${API_URL}/event/polls?channel=${this.state.channel}&eventId=${EVENT_ID}&session=${this.state.session}`, {
        headers: { Pragma: 'no-cache' },
      })
      .then((response) => {
        console.log(response);
        this.setState({ votingList: response.data.Items });
      });
  }

  componentWillUnmount() {}

  componentDidMount() {
    const clientId = 'voting-admin-' + Math.floor(Math.random() * 100000 + 1);

    const headers = {
      login: 'atozsoft',
      passcode: 'atozsoft0901',
      'client-id': clientId,
    };
    const CH = this.state.channelId;
    const client = Stomp.client(MQ_URL);
    client.connect(
      headers,
      () => {
        console.log('<< connect', new Date());

        client.subscribe(
          '/topic/' + CH,
          (data) => {
            console.log(data);
            if (data.command === 'MESSAGE') {
              let cData = JSON.parse(data.body);
              cData = cData.status;
              switch (cData) {
                case TOPIC_QNA_REG:
                  //this.getQnAList("1");
                  /*
                  if (this.state.session && this.state.session.id) {
                    this.getQnAList(this.state.session.id);
                  }
                  */
                  break;
                case TOPIC_QNA_APPROVE:
                  break;
                case TOPIC_QNA_SELECT:
                  break;
                case TOPIC_SESSION_START:
                  break;
                case TOPIC_SESSION_CLOSE:
                  break;
                case TOPIC_VOTING_START:
                  break;
                default:
                  break;
              }
            }
          },
          headers,
        );
      },
      function () {
        console.log('error', new Date());
      },
    );

    this.getVotingList();
  }

  render() {
    const handleAlignment = (event, value, data) => {
      console.log('handleA....');
      console.log(data);
      //console.log(value);
      //let param = { qnaId: value, status: "S", channel: this.state.channelId, eventId: EVENT_ID };
      //let param = { channel: this.state.channel, pollId: value, evt: EVENT_ID };
      //console.log(param);

      this.setState({ selectedId: value });

      //axios.get(`${API_URL}/voting/start?channel=${this.state.channel}&pollId=${value}&evt=${EVENT_ID}`).then((response) => {
      /*
      console.log(
        `http://192.168.1.21:3000/voting/start?channel=${this.state.channel}&pollId=${value}&evt=${EVENT_ID}&choices=${
          data.choices ? data.choices : 5
        }`
      );
      */
      axios
        .get(
          `${API_URL}/voting/start?channel=${this.state.channel}&pollId=${value}&evt=${EVENT_ID}&choices=${
            data.choices ? data.choices : 5
          }`,
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.setState({ selectedId: value });
          }
        });
    };

    const mapToComponent = (datas) => {
      return datas.map((data, i) => {
        return (
          <li key={i} style={{ marginBottom: 30 }}>
            <VotingItem
              data={data}
              onChange={(event, value) => handleAlignment(event, value, data)}
              selected={data.id === this.state.selectedId}
              handleReset={handleReset}
            />
          </li>
        );
      });
    };

    const handleReset = (datas) => {
      console.log(datas);

      axios.get(`${API_URL}/voting/reset?pollId=${datas.id}&evt=${EVENT_ID}`).then((response) => {
        // alert('리셋되었습니다.');
        swal('', '리셋되었습니다.', 'success');
      });
    };

    // const unSelected = (datas) => {
    //   console.log('unSelected');
    //   this.setState({ selectedId: -1 });

    //   axios.get(`${API_URL}/voting/start?channel=${this.state.channel}&pollId=${-1}&evt=${EVENT_ID}&choices=5`).then((response) => {
    //     console.log(response);
    //     if (response.status === 200) {
    //       this.setState({ selectedId: -1 });
    //     }
    //   });
    // };

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Grid container justifyContent="center" style={{ paddingTop: 50 }}>
          <Grid item xs={12} sm={11} md={10} lg={9}>
            <h2>Voting 문항 선택 페이지</h2>

            <div style={{ padding: '10px 40px', display: 'flex' }}>
              <div style={{ flex: '1' }}>
                {/* <div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '110px' }}>
                    <span> 현재시간 : {!this.state.now || this.displayTime(this.state.now)}</span>
                    <span> Session : {!this.state.session || this.state.session.pgTitle}</span>
                    <span>
                      {' '}
                      Time : {!this.state.session || this.state.session.startTime} ~ {!this.state.session || this.state.session.endTime}
                    </span>
                  </div>
                </div> */}
              </div>
            </div>

            {/* <Button onClick={unSelected}>선택리셋</Button> */}

            <ToggleButtonGroup
              style={{ width: '100%' }}
              value={this.state.selectedId}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ul style={{ overflow: 'auto', height: '75vh', width: '100%' }}>{mapToComponent(this.state.votingList)}</ul>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </div>
    );
  }
}

class VotingItem extends Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  render() {
    return (
      <div className="voting_filter_row" style={{ fontSize: '24px' }}>
        <div style={{ flex: '1' }}>
          <div>
            <span style={{ color: '#377ac6' }}>Q. </span> {this.props.data.q}
          </div>
          {/*<div style={{ marginLeft: '20px' }}>{this.props.qna.a ? this.props.qna.a : '답변 미입력'}</div>*/}
        </div>
        {/*<div className="limit_item">{this.props.qna.user}</div>*/}
        {/*<div className="limit_item">{this.props.qna.qna_status}</div>*/}
        <div className="limit_item">
          {this.props.selected ? (
            <div
              style={{
                width: '100px',
                height: '37px',
                textAlign: 'center',
                border: '2px solid #80AAD7',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '24px',
                backgroundColor: 'skyblue',
              }}
            >
              <span>선택중</span>
            </div>
          ) : (
            <ToggleButton
              value={this.props.data.id}
              style={{
                height: '37px',
                width: '100px',
                fontSize: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0px',
              }}
              onChange={(event, value) => {
                this.props.onChange(event, value, this.props.data);
              }}
            >
              선택
            </ToggleButton>
          )}
        </div>
        {/*
        <div className="limit_item">
          
          <Button
            onClick={() => {
              this.props.handleReset(this.props.data);
            }}
          >
            초기화
          </Button>
        </div>
          */}
      </div>
    );
  }
}

export default VotingAdmin;

import React, { Component } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles, IconButton, Button } from '@material-ui/core';

import './qnaMonitor.css';

const styles = (theme) => ({
  dialogRoot: {
    minHeight: '94vh',
    // maxHeight: '95vh',
  },
  dialogTitle: {
    margin: '0',
    padding: '8px 8px 0',
  },
  dialogTitleContent: {
    textAlign: 'center',
    fontSize: '1em',
    fontWeight: 'bold',
    color: '#377ac6',
    margin: '0 15px',
    paddingTop: '1em',
    paddingBottom: '1.5em',
    borderBottom: '2px solid #377ac6',
  },
  closeBtn: {
    right: '12px',
    top: '4px',
    color: '#5b5b5b',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  dialogActions: {
    display: 'none',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
});

class QnaDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, open, onClose, disableBackdrop } = this.props;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        onClose={onClose}
        aria-labelledby="faq-dialog-title"
        open={open}
        disableBackdropClick={disableBackdrop}
        disableEscapeKeyDown={disableBackdrop}
        className={classes.dialogRoot}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div className={classes.dialogTitleContent}>
            <IconButton onClick={onClose} className={classes.closeBtn} style={{ position: 'absolute' }}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="qna_area">
            {/*<div className="q_area">Q.</div>*/}
            <div className="question_area">{this.props.qna.q}</div>
            <div className="userinfo_area">{/*<span>홍길동</span>*/}</div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <div>
            <Button onClick={onClose} style={{ color: '#000000', fontSize: '18px', fontWeight: '600' }}>
              닫기
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(QnaDialog);

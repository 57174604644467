import React from 'react';
import { Button, Grid, withStyles, Typography } from '@material-ui/core';
import { API_URL, EVENT_CODE, EVENT_ID, EVENT_IMG, CHANNEL, EVENT_NAME } from '../constants';
import axios from 'axios';
import AgendaDialog from '../components/dialogs/AgendaDialog';
import { Backdrop } from '@mui/material';
import swal from 'sweetalert';

const styles = (theme) => ({
  //button
  commonBtn: {
    opacity: 1,
    aspectRatio: 5.5 / 1,
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
    '&:hover': {
      opacity: 0.85,
    },

    [theme.breakpoints.down('sm')]: {
      aspectRatio: 4.5 / 1,
    },
  },
});

class ProgramSimple extends React.Component {
  constructor(props) {
    super(props);
    this.goToPage = this.goToPage.bind(this);
    this.callWindows = this.callWindows.bind(this);
    this.state = {
      matches: window.matchMedia('(max-width:991px)').matches, //md:992px
      programDialog: false,
    };
  }

  componentDidMount() {
    this.setState({ height: document.body.scrollHeight + 'px' });
    const eventItem = sessionStorage.getItem('Item');

    if (!eventItem) {
      axios
        .get(`${API_URL}/event/info?eventId=${EVENT_ID}&code=${encodeURIComponent(EVENT_CODE)}`)
        .then((res) => {
          let _result = res.data;
          console.log(_result);
          _result.Item.code = null;
          console.log(_result.Item);
          sessionStorage.setItem('Item', JSON.stringify(_result.Item));
          const jsonItem = JSON.parse(JSON.stringify(_result.Item));
          this.setState({ channel: jsonItem.channel });
          // todayProgram();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const jsonItem = JSON.parse(eventItem);
      this.setState({ channel: jsonItem.channel });
      // console.log('행사채널', jsonItem.channel);
      // todayProgram();
    }

    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia('(max-width:992px)').addListener(handler);
  }

  componentWillUnmount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia('(max-width:992px)').removeListener(handler);
  }

  goToPage(_move) {
    this.props.history.push(_move);
  }

  goToLive(ch) {
    this.setState({ opened: true });
    if (this.state.win) {
      this.state.win.close();
    }

    this.callWindows(ch);
    // sessionStorage.setItem('_agree_precautions', 'Y');
  }

  callWindows = (ch) => {
    let win = window.open(`/#/simple?ch=${ch}`, 'liveWin');
    win.document.title = EVENT_NAME;
    this.setState({ win: win }, () => console.log(this.state.win));
  };
  customSpacing(size) {
    return (
      <React.Fragment>
        {size === 's' && <Grid item xs={12} style={{ padding: this.state.matches ? '8px 0' : '16px 0' }}></Grid>}
        {size === 'm' && <Grid item xs={12} style={{ padding: this.state.matches ? '16px 0' : '32px 0' }}></Grid>}
        {size === 'l' && <Grid item xs={12} style={{ padding: this.state.matches ? '24px 0' : '48px 0' }}></Grid>}
      </React.Fragment>
    );
  }
  render() {
    return (
      <div
        style={{
          height: '100%',
          position: 'relative',
          overflowY: 'auto',

          backgroundRepeat: 'no-repeat',
          backgroundImage: this.state.matches ? `url(${EVENT_IMG}/bg_mob.png)` : `url(${EVENT_IMG}/bg_web.png)`,
          backgroundSize: this.state.matches ? 'auto 100%' : '100% auto',
          backgroundPosition: this.state.matches ? 'top center' : 'top center',
        }}
      >
        <div
          style={{
            position: 'fixed',
            transform: 'rotate( -90deg )',
            color: '#fff',
            right: this.state.matches ? '-20vw' : '-75px',
            bottom: this.state.matches ? '12vh' : '15%',
            fontSize: this.state.matches ? '10px' : '11px',
            // backgroundColor: 'rgba(255,255,255,0.15)',
          }}
        >
          2084-KR-2500004/Jan.2025/Apr.2025
        </div>

        <Grid container style={{ height: '100%' }} alignItems="center">
          {/* 버튼 영역 */}
          <Grid id="login-contents-positioning-grid" container justifyContent="center">
            {/* 메인이미지 */}
            {this.customSpacing('m')}
            <Grid container justifyContent="center">
              <Grid container item xs={10} sm={8} md={7} lg={5}>
                <img src={`${EVENT_IMG}/main.png`} alt={''} style={{ width: '100%' }} />
              </Grid>
            </Grid>
            {this.customSpacing('m')}
            <Grid
              container
              justifyContent="center"
              item
              xs={11}
              sm={10}
              md={8}
              lg={6}
              style={{
                border: '1px solid #efefef',
                backgroundColor: 'rgba(244,244,244,0.9)',
                borderRadius: this.state.matches ? '4px' : '8px',
                boxShadow: this.props.theme.shadow,
              }}
            >
              {/* {this.customSpacing('s')}
              <Grid container justifyContent="center">
                <Grid container item xs={10} sm={9} md={8} lg={7}>
                  <img src={`${EVENT_IMG}/main.png`} alt={''} style={{ width: '100%' }} />
                </Grid>
              </Grid>
              {this.customSpacing('s')} */}
              {this.customSpacing('s')}
              <Grid container justifyContent="center" item xs={10} sm={9} md={8} lg={7} style={{ gap: this.state.mathces ? 8 : 16 }}>
                <Grid container justifyContent="center" item xs={11} sm={10} md={10} lg={10}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.goToLive(CHANNEL);
                    }}
                    className={this.props.classes.commonBtn}
                    fullWidth
                  >
                    <Typography style={{ fontSize: this.state.matches ? '1rem' : '1.3rem', fontWeight: 700 }}>입장하기</Typography>
                  </Button>
                </Grid>

                <Grid container justifyContent="center" item xs={11} sm={10} md={10} lg={10}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      // swal('', '준비중입니다.', 'info');
                      this.setState({ programDialog: true });
                    }}
                    className={this.props.classes.commonBtn}
                    fullWidth
                  >
                    <Typography style={{ fontSize: this.state.matches ? '1rem' : '1.3rem', fontWeight: 700 }}>일정확인</Typography>
                  </Button>
                </Grid>

                {this.customSpacing('s')}
              </Grid>

              <Grid container justifyContent="center" item xs={11} sm={10} md={9} lg={8}>
                <Grid>
                  <img src={`${EVENT_IMG}/logo_set.png`} alt={''} style={{ width: '100%' }} />
                </Grid>
              </Grid>
              {this.customSpacing('s')}
            </Grid>
          </Grid>
        </Grid>

        {/* agenda(MUI backdrop) */}
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={this.state.programDialog}>
          <AgendaDialog
            onClose={() => {
              this.setState({ programDialog: false });
            }}
          />
        </Backdrop>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ProgramSimple);

import React, { Component } from 'react';
import { Grid, withStyles, IconButton, Button, Typography } from '@material-ui/core';
import { EVENT_IMG } from '../../constants';
import CloseIcon from '@material-ui/icons/Close';

const styles = () => ({});

class AgendaDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:992px)').matches,
      value: 1,
    };
  }

  componentWillUnmount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia('(max-width:992px)').removeListener(handler);
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia('(max-width:992px)').addListener(handler);
  }

  handleChange(event, newVal) {
    this.setState({ value: newVal });
  }

  render() {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100%', overflowY: 'scroll' }}>
        <Grid id="content-grid" container item xs={12} sm={11} md={9} lg={8} alignItems="center">
          <Grid id="title-grid" container style={{ paddingBottom: this.state.matches ? 8 : 16 }}>
            <Grid item xs={10} />
            <Grid container item xs={2} justifyContent="flex-end">
              <IconButton onClick={this.props.onClose} style={{ backgroundColor: this.props.theme.palette.secondary.main, color: '#fff' }}>
                <CloseIcon style={this.state.matches ? { height: 24, width: 24, fontWeight: 'bold' } : { height: 32, width: 32 }} />
              </IconButton>
            </Grid>
          </Grid>

          <Grid
            id="tab-grid"
            container
            justifyContent="center"
            alignItems="center"
            style={{ backgroundColor: '#fff', padding: '8px 0px 16px', borderTopRightRadius: 5, borderTopLeftRadius: 5 }}
          >
            <Grid item xs={12} sm={11} md={10} lg={10} container justifyContent="center">
              <Button
                onClick={() => this.setState({ value: 1 })}
                variant="contained"
                color="primary"
                size={this.state.matches ? 'medium' : 'large'}
                style={{
                  borderRadius: 80,
                  marginRight: this.state.matches ? 8 : 24,
                  textTransform: 'none',
                  opacity: this.state.value === 1 ? 1 : 0.25,
                }}
              >
                <Typography style={{ fontSize: this.state.matches ? 'small' : 'large' }}>Day 1</Typography>
              </Button>
              <Button
                onClick={() => this.setState({ value: 2 })}
                variant="contained"
                color="secondary"
                size={this.state.matches ? 'medium' : 'large'}
                style={{ borderRadius: 80, textTransform: 'none', opacity: this.state.value === 2 ? 1 : 0.25 }}
              >
                <Typography style={{ fontSize: this.state.matches ? 'small' : 'large', color: '#fff' }}>Day 2</Typography>
              </Button>
            </Grid>
          </Grid>

          <Grid
            id="agenda-grid"
            container
            justifyContent="center"
            style={{ backgroundColor: '#fff', borderBottomRightRadius: 5, borderBottomLeftRadius: 5, padding: '24px 0px' }}
          >
            {this.state.value === 1 && (
              <Grid item xs={12} sm={11} md={10} lg={10}>
                <img src={`${EVENT_IMG}/agenda_d1.png`} alt={'img'} style={{ width: '100%' }} />
              </Grid>
            )}

            {this.state.value === 2 && (
              <Grid item xs={12} sm={11} md={10} lg={10}>
                <img src={`${EVENT_IMG}/agenda_d2.png`} alt={'img'} style={{ width: '100%' }} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AgendaDialog);

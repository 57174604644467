import { createContext } from 'react';

const MainContext = createContext({
  state: {
    currentSession: {},
    currentTime: 0,
    arrDate: [],
    strDate: '',
    dayIndex: 0,
  },
  actions: {
    setCurrentSession: () => {},
    setCurrentTime: () => {},
    setArrDate: () => {},
    setStrDate: () => {},
    setDayIndex: () => {},
  },
});

// Provider를 rendering하면서 상태값과 메소드들을 전달합니다.
// const MainProvider = ({ children }) => {
//   const [currentSession, setCurrentSession] = useState({});
//   const [currentTime, setCurrentTime] = useState(0);
//   const [arrDate, setArrDate] = useState([]);
//   const [strDate, setStrDate] = useState('');
//   const [dayIndex, setDayIndex] = useState(0);
//
//   const value = {
//     state: { currentSession, currentTime, arrDate, strDate, dayIndex },
//     actions: { setCurrentSession, setCurrentTime, setArrDate, setStrDate, setDayIndex  },
//   };
//
//   return <MainContext.Provider value={[value.state, value.actions]}>{children}</MainContext.Provider>;
// };

//const MainConsumer = MainContext.Consumer;
//export { MainConsumer };
export default MainContext;

import React from 'react';
import ReactJWPlayer from 'react-jw-player';
import { withStyles } from '@material-ui/core';
import { JWPLAYER_ID } from '../constants';

const styles = (theme) => ({
  player: {
    width: '100%',
    height: '100%',
    '& .jw-logo-button': {
      display: 'none !important',
    },
    '& .jw-related-btn': {
      display: 'none !important',
    },
  },
  playerWelcome: {
    width: '100%',
    height: '100%',
    marginTop: '-2%',
    marginBottom: '-2%',
    '& .jw-logo-button': {
      display: 'none !important',
    },
    '& .jw-related-btn': {
      display: 'none !important',
    },
  },
});
class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type ? this.props.type : 'jw',
      src: this.props.src ? this.props.src : 'https://innon.s3.ap-northeast-2.amazonaws.com/test06.mp4',
    };
  }

  componentDidMount() {
    const divEl = document.getElementById('div-frame');
    if (this.state.type === 'vimeo') {
      divEl.innerHTML = `<iframe width="100%" height="100%" src="https://player.vimeo.com/video/521808126" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="" />`;
    } else if (this.state.type === 'youtube') {
      divEl.innerHTML = `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/Gg9mos61800" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    }
  }

  render() {
    const playlist = [
      {
        file: this.state.src,
      },
    ];
    const { classes } = this.props;
    return (
      <div style={this.props.pos === 'live' ? { width: '100%', height: '100%' } : { width: '100%' }}>
        {this.state.type === 'jw' ? (
          <ReactJWPlayer
            className={classes.player}
            playerId={JWPLAYER_ID}
            playerScript={`https://cdn.jwplayer.com/libraries/${JWPLAYER_ID}.js`}
            playlist={playlist}
            isAutoPlay={false}
            style={{ height: '100%' }}
          />
        ) : this.state.type === 'vimeo' || this.state.type === 'youtube' ? (
          <div id={'div-frame'} style={{ width: '100%', height: '100%' }} />
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(VideoPlayer);

import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, withStyles, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  dialogRoot: {
    // height: '80vh',
    overflow: 'auto',
  },

  dialogTitleContent: {
    textAlign: 'center',
    fontSize: '19.5px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    margin: '0 15px',
    paddingTop: '1em',
    paddingBottom: '1.5em',
    borderBottom: '2px solid #377ac6',
  },
  closeBtn: {
    right: '12px',
    top: '4px',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      right: 0,
      top: 0,
    },
    '& svg': {
      fontSize: '1.5em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2em',
      },
    },
  },
  dialogContent: {
    marginBottom: '10px',
    // overflow: 'hidden',
    // [theme.breakpoints.down('sm')]: {
    //   overflow: 'auto',
    // },
    '& .jw-controlbar': {
      bottom: '5%',
    },
    '& .jw-icon-fullscreen': {
      display: 'none',
    },
  },
  dialogActions: {
    display: 'none',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  listTitle: {
    fontSize: '19.5px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '3.03',
    letterSpacing: '-0.68px',
    textAlign: 'center',
    color: '#2b4a65',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      lineHeight: '2',
    },
  },
  contents: {
    padding: theme.spacing(1),
  },
  msgImg: {
    width: '100%',
    height: 'auto',
  },
  vodIframe: {
    height: '40vw',
    overflow: 'auto',
    [theme.breakpoints.down('lg')]: {
      height: '33vw', //19
    },
    [theme.breakpoints.down('md')]: {
      height: '50vw', //26
    },
    [theme.breakpoints.down('sm')]: {
      // marginTop: '-4%',
      // marginBottom: '-5%',
    },
  },
});

class ReplayVod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia('(max-width:960px)').matches,
    };
  }

  render() {
    const { classes, open, onClose, disableBackdrop } = this.props;
    // const playlist = [
    //   {
    //     file: this.props.vod,
    //   },
    // ];
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        onClose={onClose}
        open={open}
        disableEscapeKeyDown={disableBackdrop}
        className={classes.dialogRoot}
      >
        <DialogTitle style={{ padding: 0 }}>
          <Grid container justifyContent="center">
            {this.state.matches ? (
              <Grid container>
                <Grid container item xs={10} style={{ padding: 8 }}>
                  <Typography style={{ color: '#555555', fontWeight: 'bold', fontSize: '0.7em' }}>{this.props.lecture}</Typography>
                </Grid>

                <Grid container justifyContent="flex-end" item xs={2}>
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ) : (
              <Grid container style={{ padding: '8px 16px' }}>
                <Grid container justifyContent="flex-start" alignItems="center" item xs={10}>
                  <Typography style={{ color: '#555555', fontWeight: 'bold', fontSize: '0.9em' }}>{this.props.lecture}</Typography>
                </Grid>
                <Grid container justifyContent="flex-end" item xs={2}>
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} style={{ padding: 0, margin: 0, backgroundColor: '#000000' }}>
          <Grid container justifyContent="center" style={{ padding: '16px 0' }}>
            <Grid container justifyContent="center" item xs={12} sm={12} md={11} lg={10}>
              <iframe
                id="player"
                type="text/html"
                title="replayVod"
                width="100%"
                height="100%"
                src={`https://player.vimeo.com/video/${this.props.vod.trim()}`}
                frameBorder="0"
                allowFullScreen
                allow="accelerometer; autoplay; encrypted-media;"
                className={classes.vodIframe}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ReplayVod);

import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { EVENT_IMG } from '../constants';

const styles = (theme) => ({
  bg: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: '#ffffff',
    position: 'relative',
  },
});

class ComingSoon extends React.Component {
  constructor(props) {
    super(props);
    this.state = { matches: window.matchMedia('(max-width:992px)').matches }; // md: 992px
  }

  componentWillUnmount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia('(max-width:992px)').removeListener(handler);
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia('(max-width:992px)').addListener(handler);
  }

  customSpacing(size) {
    return (
      <React.Fragment>
        {size === 's' && <Grid item xs={12} style={{ padding: this.state.matches ? '8px 0' : '16px 0' }}></Grid>}
        {size === 'm' && <Grid item xs={12} style={{ padding: this.state.matches ? '16px 0' : '32px 0' }}></Grid>}
        {size === 'l' && <Grid item xs={12} style={{ padding: this.state.matches ? '24px 0' : '48px 0' }}></Grid>}
      </React.Fragment>
    );
  }

  render() {
    // const { classes } = this.props;
    return (
      <div
        style={{
          height: '100%',
          position: 'relative',
          overflowY: 'auto',
        }}
      >
        <Grid container justifyContent="center" style={{ height: '100%', position: 'relative', backgroundColor: '#eaeaea' }}>
          {this.state.matches ? (
            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
              <img src={`${EVENT_IMG}/bg_mob.jpg`} alt="" style={{ width: '100%', height: '100%' }} />

              <div
                style={{
                  width: '99.7%',
                  height: '10.2%',
                  left: '0.2%',
                  top: '45.1%',
                  position: 'absolute',
                  cursor: 'pointer',
                  display: 'block',
                  zIndex: 5,
                  overflow: 'hidden',
                  textAlign: 'center',
                }}
              >
                <Typography
                  style={{
                    fontSize: '2.5rem',
                    fontWeight: 800,
                    color: `#00479d`,
                    textShadow: '2px 4px 2px #fff',
                  }}
                >
                  Coming Soon
                </Typography>
              </div>
              {/* <Grid container justifyContent="center" style={{ position: 'absolute', bottom: '20px' }}>
                <Grid item xs={5}>
                  <img src={`${EVENT_IMG}/logo_bms.png`} alt="" style={{ width: '100%' }} />
                </Grid>
              </Grid> */}
            </div>
          ) : (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'right', position: 'relative' }}>
              <img src={`${EVENT_IMG}/bg_wide_web.png`} alt="" style={{ width: '100%', height: 'auto' }} />
              {/* <div
                style={{
                  maxWidth: '50%',
                  width: 'auto',
                  height: '8%',
                  left: '55%',
                  top: '75.4%',
                  position: 'absolute',
                  display: 'block',
                  zIndex: 999,
                  overflow: 'hidden',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: 'fit-content',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    backgroundColor: '#e94e32',
                    borderRadius: '10px',
                  }}
                >
                  <Typography
                    style={{
                      color: '#fff',
                      fontSize: '2.3vw',
                      fontWeight: 'bold',
                    }}
                  >
                    &nbsp;&nbsp;Coming Soon&nbsp;&nbsp;
                  </Typography>
                </div>
              </div> */}
            </div>
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ComingSoon);

import { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { EVENT_ID, getUserID, LOGIN_TB, SIGNOUT_URL, WEBSOCKET_URL } from '../constants';
import MainContext from '../MainContext';
import swal from 'sweetalert';
import IoTClient from '../lib/iot-client';

Amplify.configure(awsconfig);

const styles = (theme) => ({});

class SocketManager extends Component {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    this.state = {};
    this.socketManager = this.socketManager.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.socketManager();
  }

  componentWillUnmount() {
    if (this.state.mainSocket) {
      this.state.mainSocket.close();
      delete this.state.mainSocket;
      this.setState({ mainSocket: null });
    }
  }

  socketManager() {
    console.log('>>> socketManger <<<');
    let socket = new WebSocket(WEBSOCKET_URL);
    this.setState({ mainSocket: socket });

    if (!sessionStorage.getItem('_in_user_')) {
      this.props.history.push('/');
    }
    socket.onopen = (event) => {
      console.log('socket open');
      if (sessionStorage.getItem('_in_user_')) {
        const _in_ = sessionStorage.getItem('_in_user_');
        console.log('socket >> ' + _in_);
        socket.send(JSON.stringify({ action: 'sendmessage', type: 'join', room: _in_, evt: EVENT_ID }));
        console.log('socket send >> ' + sessionStorage.getItem('_in_event_id'));
        socket.send(
          JSON.stringify({ action: 'sendmessage', type: 'msg', room: _in_, evt: EVENT_ID, data: sessionStorage.getItem('_in_event_id') }),
        );
        socket.send(
          JSON.stringify({
            action: 'sendmessage',
            type: 'msg',
            room: _in_ + '_Live',
            evt: EVENT_ID,
            data: sessionStorage.getItem('_in_event_id'),
          }),
        );
      }
    };
    socket.onmessage = (event) => {
      console.log('onmessage ');
      if (sessionStorage.getItem('_in_event_id') && event.data !== sessionStorage.getItem('_in_event_id')) {
        console.log('중복로그인 >>');
        swal('', '중복로그인이 되어 로그아웃됩니다.', 'warning');
        this.logout();
      }
    };
    socket.onclose = (event) => {
      console.log('onclose', sessionStorage.getItem('_in_user_'));
      if (sessionStorage.getItem('_in_user_')) {
        // eslint-disable-next-line
        const _in_ = sessionStorage.getItem('_in_user_');
        this.socketManager();
      }
    };
    socket.onerror = (event) => {
      console.log('onerror', event);
    };
  }

  logout() {
    if (sessionStorage.getItem('_in_name')) {
      fetch(
        `${SIGNOUT_URL}?username=${encodeURIComponent(
          sessionStorage.getItem('_in_name'),
        )}&userid=${getUserID()}&evt=${EVENT_ID}&tbName=${LOGIN_TB}&agent=${encodeURIComponent(navigator.userAgent)}`,
        {
          method: 'GET',
        },
      )
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    }

    try {
      const mqClient = new IoTClient();
      mqClient.disconnect();
    } catch (e) {}

    localStorage.clear();
    sessionStorage.clear();
    if (this.props.winClose) {
      Auth.signOut().then(() => {
        window.opener = 'Self';
        window.close(); // 일반적인 현재 창 닫기
      });
    } else {
      Auth.signOut().then(() => {
        this.props.history.push('/');
      });
    }
  }

  render() {
    return null;
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(SocketManager));

import React, { Component } from 'react';
import Stomp from 'stompjs';
import { Grid, FormControl, LinearProgress, Radio, RadioGroup, Typography, withStyles } from '@material-ui/core';
import {
  API_URL,
  // CHANNEL,
  EVENT_ID,
  MQ_URL,
  TOPIC_QNA_APPROVE,
  TOPIC_QNA_REG,
  TOPIC_QNA_SELECT,
  TOPIC_SESSION_CLOSE,
  TOPIC_SESSION_START,
  TOPIC_VOTING_START,
} from '../constants';
import axios from 'axios';
// import swal from 'sweetalert';

import './qnaMonitor.css';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 33,
    // borderRadius: 5,
    margin: '10px 0 15px',
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    // borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

const styles = (theme) => ({
  root: {
    height: 20,
    // borderRadius: 5,
    margin: '10px 0 15px',
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    // borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
  voteBtn: {
    width: '60px',
    height: '60px',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  selectBtn: {
    width: '60px',
    height: '60px',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '3px',
    display: 'flex',
    color: 'white',
  },
  radioBtn: {
    opacity: 0,
    position: 'absolute',
    width: '60px',
    height: '60px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  radioLabel: {
    width: '60px',
    height: '60px',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  voteCnt: {
    position: 'absolute',
    right: 0,
    color: '#828282',
    fontSize: '18px',
    marginRight: '60px',
  },
});

class VotingPage extends Component {
  constructor(props) {
    super(props);

    //let param = queryString.parse(this.props.location.search.replace('?', ''));
    this.state = {
      channelId: 'ChannelA', // *보팅:: 해당 채널만 보팅 사용
      choices: 5, //보팅:: 항목 표시
      isCount: false,
      tick: null,
      resultTick: null,
      resultTimerHour: 5,
      timerHour: 15,
      voting: '',
      totalCnt: 0,
      res1: 0,
      res2: 0,
      res3: 0,
      res4: 0,
      res5: 0,
      // res6: 0,
    };
  }

  componentWillUnmount() {}

  componentDidMount() {
    const clientId = 'voting-page-' + Math.floor(Math.random() * 100000 + 1);

    const headers = {
      login: 'atozsoft',
      passcode: 'atozsoft0901',
      'client-id': clientId,
    };
    const CH = this.state.channelId;
    const client = Stomp.client(MQ_URL);
    client.connect(
      headers,
      () => {
        console.log('<< connect', new Date());

        client.subscribe(
          '/topic/' + CH,
          (data) => {
            console.log(data);
            if (data.command === 'MESSAGE') {
              let cData = JSON.parse(data.body);

              switch (cData.status) {
                case TOPIC_QNA_REG:
                  //this.getQnAList("1");
                  /*
                  if (this.state.session && this.state.session.id) {
                    this.getQnAList(this.state.session.id);
                  }
                  */
                  break;
                case TOPIC_QNA_APPROVE:
                  break;
                case TOPIC_QNA_SELECT:
                  break;
                case TOPIC_SESSION_START:
                  break;
                case TOPIC_SESSION_CLOSE:
                  break;
                case TOPIC_VOTING_START:
                  console.log('VOTING_START');
                  console.log(cData.count);
                  this.setState({
                    choices: cData.count,
                    isCount: true,
                    voting: cData.data,
                    totalCnt: 0,
                    res1: 0,
                    res2: 0,
                    res3: 0,
                    res4: 0,
                    res5: 0,
                    // res6: 0,
                  });
                  this.starTimer();
                  break;
                default:
                  break;
              }
            }
          },
          headers,
        );
      },
      function () {
        console.log('error', new Date());
      },
    );
  }

  calc100(value) {
    return ((value - 0) * 100) / (this.state.totalCnt - 0);
  }

  callResultData = () => {
    if (this.state.voting) {
      axios
        .get(`${API_URL}/voting/result?evt=${EVENT_ID}&voting=${this.state.voting}`, { headers: { Pragma: 'no-cache' } })
        .then((response) => {
          console.log(response);
          this.setState({
            totalCnt: response.data.total,
            res1: response.data.val1,
            res2: response.data.val2,
            res3: response.data.val3,
            res4: response.data.val4,
            res5: response.data.val5,
            // res6: response.data.val6,
          });

          this.starResultTimer();
        });
    }
  };

  callResult = () => {
    // this.unSelected();
    this.setState({ tick: null, timerHour: 15 });
    //this.callResult();
    this.setState({ isCount: false });
    this.callResultData();
    //axios.get(`${API_URL}/voting/result?evt=${EVENT_ID}&voting=${this.state.voting}`, { headers: { Pragma: "no-cache" } })

    //console.log(`http://192.168.1.21:3000/voting/result?evt=${EVENT_ID}&voting=${this.state.voting}`);
  };

  starResultTimer() {
    if (this.state.resultTick !== null) {
      clearInterval(this.state.resultTick);
      this.setState({ resultTick: null, resultTimerHour: 5 });
    }
    const func = () => {
      let timer = this.state.resultTimerHour;
      timer--;
      this.setState({ resultTimerHour: timer });
      axios
        .get(`${API_URL}/voting/result?evt=${EVENT_ID}&voting=${this.state.voting}`, { headers: { Pragma: 'no-cache' } })
        .then((response) => {
          console.log(response);
          this.setState({
            totalCnt: response.data.total,
            res1: response.data.val1,
            res2: response.data.val2,
            res3: response.data.val3,
            res4: response.data.val4,
            res5: response.data.val5,
            // res6: response.data.val6,
          });
        });
      if (timer === 0) {
        clearInterval(this.state.resultTick);
        this.setState({ resultTick: null, resultTimerHour: 5 });
      }
    };

    let buff = setInterval(func, 1000);
    this.setState({ resultTick: buff });
  }

  starTimer() {
    if (this.state.tick !== null) {
      clearInterval(this.state.tick);
      // this.setState({ tick: null, timerHour: 15, totalCnt: 0, res1: 0, res2: 0, res3: 0, res4: 0, res5: 0, res6: 6 });
      this.setState({ tick: null, timerHour: 15, totalCnt: 0, res1: 0, res2: 0, res3: 0, res4: 0, res5: 5 });
    }
    const func = () => {
      let timer = this.state.timerHour;
      timer--;
      this.setState({ timerHour: timer });
      if (timer === 0) {
        clearInterval(this.state.tick);
        //this.setState({ tick: null, timerHour: 25 });
        //this.callResult();
      }
    };

    let buff = setInterval(func, 1000);
    this.setState({ tick: buff });
  }

  unSelected = (datas) => {
    //this.setState({ selectedId: -1 });

    axios
      .get(`${API_URL}/voting/start?channel=${this.state.channelId}&pollId=${EVENT_ID}_voting00&evt=${EVENT_ID}&choices=5`)
      .then((response) => {
        console.log('error start');
        console.log(response);
        if (response.status === 200) {
          //this.setState({ selectedId: -1 });
        }
      });
  };

  render() {
    const { classes } = this.props;
    const normalise = (value, total) => {
      return ((value - 0) * 100) / (total - 0);
    };

    return (
      <div
        className="voting"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', overflowY: 'true' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1', backgroundColor: '#dddddd', height: '100%' }}>
          {!this.state.isCount ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                backgroundColor: '#dddddd',
                height: '100%',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  flex: '1',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '55px',
                  flexDirection: 'column',
                }}
              >
                <div>{/* <span style={{ color: '#7f1084', fontStyle: 'bold' }}>결과</span> */}</div>
                <div></div>
              </div>
              <div
                style={{
                  // alignItems: 'flex-end',
                  marginBottom: '27px',
                  objectFit: 'contain',
                  borderRadius: '20px',
                  backgroundColor: '#222222',
                  padding: '20px',
                  margin: '60px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src="/images/giftbox@2x.png"
                  onClick={this.callResult}
                  style={{ width: '229px', height: '221px', cursor: 'pointer' }}
                  alt={'img'}
                />
                <span
                  style={{
                    fontSize: '100px',
                    color: '#FFFFFF',
                    fontStyle: 'bold',
                  }}
                >
                  voting
                </span>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                backgroundColor: '#dddddd',
                height: '100%',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  flex: '1',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '55px',
                  flexDirection: 'column',
                }}
              >
                <div>{/* <span style={{ color: '#7f1084', fontStyle: 'bold' }}>투표중입니다. </span> */}</div>
                {/* <div>가능합니다.</div> */}
              </div>
              <div
                style={{
                  // alignItems: 'flex-end',
                  marginBottom: '27px',
                  objectFit: 'contain',
                  borderRadius: '20px',
                  backgroundColor: '#f6f6f6',
                  padding: '20px',
                  margin: '60px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img src="/images/gifttime@2x.png" onClick={this.callResult} style={{ width: '133px', height: '178px' }} alt={'img'} />
                <span
                  style={{
                    fontSize: '64.5px',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    textAlign: 'center',
                    color: '#b121c1',
                  }}
                >
                  {/* {this.state.timerHour == 0 ? <span>CLICK</span> : <span style={{ color: '#f6f6f6' }}>CLICK</span>} */}
                  {/* {this.state.timerHour > 9 ? '' + this.state.timerHour : '0' + this.state.timerHour} */}
                  <span style={{ color: '#f6f6f6' }}>CLICK</span>
                </span>
              </div>
            </div>
          )}
        </div>
        <div style={{ flex: '1', height: '100%' }}>
          <div style={{ height: '100%', padding: '20px', display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h5">{this.state.title}</Typography>

            {/* <form onSubmit={this.handleSubmit}> */}
            <FormControl style={{ height: '80%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <RadioGroup value={this.state.selected} onChange={this.handleRadioChange} style={{ height: '100%', width: '100%' }}>
                <Grid container spacing={2}>
                  <Grid item container xs={12}>
                    {/* <Button variant="contained" color="primary" className={classes.voteBtn} onClick={() => this.handleBtn('1')}>
                      1
                    </Button>{' '}
                    <Typography display={'inline'}>1번</Typography> */}
                    <Grid item>
                      <div className={classes.selectBtn}>
                        {/* <input id="1" type="radio" value="1" className={classes.radioBtn} onChange={() => this.handleBtn("1")} /> */}
                        {/* <FormControlLabel value="1" control={<Radio />} label="1" className={classes.radioLabel} /> */}
                        <Radio value="1" id="1" className={classes.radioBtn} />
                        <label htmlFor="1" className={classes.radioLabel}>
                          1
                        </label>
                      </div>
                    </Grid>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography display={'inline'} style={{ marginLeft: '20px', fontSize: '18px' }}>
                        1번
                      </Typography>
                      {!this.state.isCount ? (
                        <Typography display={'inline'} className={classes.voteCnt}>
                          {/* {this.state.res1}/{this.state.totalCnt} */}
                          {this.state.res1 && this.state.totalCnt ? normalise(this.state.res1, this.state.totalCnt).toFixed(2) : ''}
                          <span>%</span>
                        </Typography>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <Grid item xs={12}>
                      {/* <div className={classes.voteBarDiv}> */}

                      {!this.state.isCount ? (
                        <BorderLinearProgress variant="determinate" value={this.calc100(this.state.res1)} />
                      ) : (
                        <div style={{ height: '33px', margin: '10px 0 20px', backgroundColor: '#a8a8a8' }}></div>
                      )}
                      {/* </div> */}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    {/* <Button variant="contained" color="primary" className={classes.voteBtn} onClick={() => this.handleBtn("2")}>
                2
              </Button>{" "} */}
                    <Grid item>
                      <div className={classes.selectBtn}>
                        {/* <input id="2" type="radio" value="2" className={classes.radioBtn} /> */}
                        {/* <FormControlLabel value="2" control={<Radio />} label="2" className={classes.radioLabel} /> */}
                        <Radio value="2" id="2" className={classes.radioBtn} />
                        <label htmlFor="2" className={classes.radioLabel}>
                          2
                        </label>
                      </div>
                    </Grid>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography display={'inline'} style={{ marginLeft: '20px', fontSize: '18px' }}>
                        2번
                      </Typography>
                      {!this.state.isCount ? (
                        <Typography display={'inline'} className={classes.voteCnt}>
                          {/* {this.state.res2}/{this.state.totalCnt} */}
                          {this.state.res2 && this.state.totalCnt ? normalise(this.state.res2, this.state.totalCnt).toFixed(2) : ''}
                          <span>%</span>
                        </Typography>
                      ) : (
                        ''
                      )}
                    </div>
                    <Grid item xs={12}>
                      {/* <div className={classes.voteBarDiv}> */}
                      {!this.state.isCount ? (
                        <BorderLinearProgress variant="determinate" value={this.calc100(this.state.res2)} />
                      ) : (
                        <div style={{ height: '33px', margin: '10px 0 20px', backgroundColor: '#a8a8a8' }}></div>
                      )}
                      {/* </div> */}
                    </Grid>
                  </Grid>
                  {this.state.choices > 2 ? (
                    <Grid item container xs={12}>
                      {/* <Button variant="contained" color="primary" className={classes.voteBtn} onClick={() => this.handleBtn("3")}>
                3
              </Button>{" "}
              <Typography display={"inline"}>3번</Typography> */}
                      <Grid item>
                        <div className={classes.selectBtn}>
                          {/* <input id="3" type="radio" value="3" className={classes.radioBtn} /> */}
                          {/* <FormControlLabel value="3" control={<Radio />} label="3" className={classes.radioLabel} /> */}
                          <Radio value="3" id="3" className={classes.radioBtn} />
                          <label htmlFor="3" className={classes.radioLabel}>
                            3
                          </label>
                        </div>
                      </Grid>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography display={'inline'} style={{ marginLeft: '20px', fontSize: '18px' }}>
                          3번
                        </Typography>
                        {!this.state.isCount ? (
                          <Typography display={'inline'} className={classes.voteCnt}>
                            {/* {this.state.res3}/{this.state.totalCnt} */}
                            {this.state.res3 && this.state.totalCnt ? normalise(this.state.res3, this.state.totalCnt).toFixed(2) : ''}
                            <span>%</span>
                          </Typography>
                        ) : (
                          ''
                        )}
                      </div>
                      <Grid item xs={12}>
                        {/* <div className={classes.voteBarDiv}> */}
                        {!this.state.isCount ? (
                          <BorderLinearProgress variant="determinate" value={this.calc100(this.state.res3)} />
                        ) : (
                          <div style={{ height: '33px', margin: '10px 0 20px', backgroundColor: '#a8a8a8' }}></div>
                        )}
                        {/* </div> */}
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {this.state.choices > 3 ? (
                    <Grid item container xs={12}>
                      {/* <Button variant="contained" color="primary" className={classes.voteBtn} onClick={() => this.handleBtn("4")}>
                4
              </Button>{" "}
              <Typography display={"inline"}>4번</Typography> */}
                      <Grid item>
                        <div className={classes.selectBtn}>
                          {/* <input id="4" type="radio" value="4" className={classes.radioBtn} /> */}
                          {/* <FormControlLabel value="4" control={<Radio />} label="4" className={classes.radioLabel} /> */}
                          <Radio value="4" id="4" className={classes.radioBtn} />
                          <label htmlFor="4" className={classes.radioLabel}>
                            4
                          </label>
                        </div>
                      </Grid>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography display={'inline'} style={{ marginLeft: '20px', fontSize: '18px' }}>
                          4번
                        </Typography>
                        {!this.state.isCount ? (
                          <Typography display={'inline'} className={classes.voteCnt}>
                            {/* {this.state.res4}/{this.state.totalCnt} */}
                            {this.state.res4 && this.state.totalCnt ? normalise(this.state.res4, this.state.totalCnt).toFixed(2) : ''}
                            <span>%</span>
                          </Typography>
                        ) : (
                          ''
                        )}
                      </div>
                      <Grid item xs={12}>
                        {/* <div className={classes.voteBarDiv}> */}
                        {!this.state.isCount ? (
                          <BorderLinearProgress variant="determinate" value={this.calc100(this.state.res4)} />
                        ) : (
                          <div style={{ height: '33px', margin: '10px 0 20px', backgroundColor: '#a8a8a8' }}></div>
                        )}
                        {/* </div> */}
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {this.state.choices > 4 ? (
                    <Grid item container xs={12}>
                      <Grid item>
                        <div className={classes.selectBtn}>
                          <Radio value="5" id="5" className={classes.radioBtn} />
                          <label htmlFor="5" className={classes.radioLabel}>
                            5
                          </label>
                        </div>
                      </Grid>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography display={'inline'} style={{ marginLeft: '20px', fontSize: '18px' }}>
                          5번
                        </Typography>
                        {!this.state.isCount ? (
                          <Typography display={'inline'} className={classes.voteCnt}>
                            {/* {this.state.res5}/{this.state.totalCnt} */}
                            {this.state.res5 && this.state.totalCnt ? normalise(this.state.res5, this.state.totalCnt).toFixed(2) : ''}
                            <span>%</span>
                          </Typography>
                        ) : (
                          ''
                        )}
                      </div>
                      <Grid item xs={12}>
                        {!this.state.isCount ? (
                          <BorderLinearProgress variant="determinate" value={this.calc100(this.state.res5)} />
                        ) : (
                          <div style={{ height: '33px', margin: '10px 0 20px', backgroundColor: '#a8a8a8' }}></div>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}

                  {/* {this.state.choices > 5 ? (
                    <Grid item container xs={12}>
                      <Grid item>
                        <div className={classes.selectBtn}>
                          <Radio value="6" id="6" className={classes.radioBtn} />
                          <label htmlFor="6" className={classes.radioLabel}>
                            6
                          </label>
                        </div>
                      </Grid>
                      <Grid item alignItems={'center'} style={{ display: 'flex' }}>
                        <Typography display={'inline'} style={{ marginLeft: '20px', fontSize: '18px' }}>
                          6번
                        </Typography>
                        {!this.state.isCount ? (
                          <Typography display={'inline'} className={classes.voteCnt}>
                
                            {this.state.res6 && this.state.totalCnt ? normalise(this.state.res6, this.state.totalCnt).toFixed(2) : ''}
                            <span>%</span>
                          </Typography>
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {!this.state.isCount ? (
                          <BorderLinearProgress variant="determinate" value={this.calc100(this.state.res6)} />
                        ) : (
                          <div style={{ height: '33px', margin: '10px 0 20px', backgroundColor: '#a8a8a8' }}></div>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )} */}
                </Grid>
                {/*
                <Button variant="outlined" color="primary" type="submit">
                  제출하기
                </Button>
                */}
              </RadioGroup>
            </FormControl>
            {/* </form> */}

            <Typography variant="h3">{this.state.desc}</Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(VotingPage);
